.research {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 683px;

  &__title {
    width: 196px;
    height: 48px;
    margin-top: 80px;
    margin-bottom: 32px; // margin to dropdown-list (needed for reuse)
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
  }
}

@media (max-width: 479px) {
  .research {
    min-height: 401px;

    &__title {
      width: 145px;
      height: 32px;
      margin-top: 0;
      margin-bottom: 24px; // margin to dropdown-list (needed for reuse)
      font-size: 24px;
      line-height: 32px;
    }
  }
}
