.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  color: #141414;
  font-family: Rubik;
}

.spinnerContainer {
  width: 100%;
  max-width: 1440px;
  height: 536px;
  max-height: 536px;
}

@media (max-width: 479px) {
  .spinnerContainer {
    width: 100%;
    max-width: 1440px;
    height: 238px;
    max-height: 536px;
  }
}
