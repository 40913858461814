.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgba(20, 20, 20, 0.1);

  &__head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 52px;
    background: #e6e8f1;
  }

  &__headText {
    margin: 0 0 0 32px;
    color: #8a8a8a;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  &__paymentBlock {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);
  }

  &__paymentMethodText {
    margin: 0 0 0 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  &__paymentData {
    margin: 0 0 32px 0;
  }
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 696px;
  height: 60px;
  margin: 24px 0 0 0;
  padding-left: 16px;
  font-size: 22px;
  background: #f4f6fb;
  border-radius: 10px;
  cursor: pointer;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &_selected {
    background: #ecfaf3;
  }

  &:first-of-type {
    margin: 32px 0 0 0;
  }
}

.bankBlock {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 696px;

  &__bank {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 216px;
    height: 60px;
    margin: 20px 0 0 0;
    background: #fff;
    border: 1px solid rgba(20, 20, 20, 0.1);
    border-radius: 10px;
    cursor: pointer;

    &_active {
      border: 3px solid #45cb85;
    }
  }

  &__image {
    max-width: 216px;
    max-height: 60px;
  }

  &__text {
    width: 107px;
    height: 36px;
    margin: 0 0 0 12px;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 3px;
  }
}

.cardForm {
  &__title {
    height: 20px;
    margin: 24px 0 0 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  &__numberBlock {
    margin: 24px 0 0 0;
  }

  &__blockTitle {
    width: 115px;
    height: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  &__numberHolder {
    position: relative;
    width: 696px;
    height: 40px;
    margin: 8px 0 0 0;
    padding: 11px 16px;
    border: 1px solid rgba(20, 20, 20, 0.1);
    border-radius: 10px;
  }

  &__cardImage {
    position: absolute;
    top: 10.5px;
    right: 16px;
  }

  &__expiryAndCvc {
    display: flex;
    margin: 24px 0 0 0;
  }

  &__expiryBlock {
    margin: 0;
  }

  &__expiryHolder {
    width: 324px;
    height: 40px;
    margin: 8px 0 0 0;
    padding: 11px 16px;
    background: #fff;
    border: 1px solid rgba(20, 20, 20, 0.1);
    border-radius: 10px;
  }

  &__cvcBlock {
    margin: 0 0 0 48px;
  }

  &__cvcHolder {
    width: 324px;
    height: 40px;
    margin: 8px 0 0 0;
    padding: 11px 16px;
    background: #fff;
    border: 1px solid rgba(20, 20, 20, 0.1);
    border-radius: 10px;
  }

  &__cardError {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: -moz-max-content;
    width: 343px;
    max-width: 343px;
    padding: 16px;
    color: #f20035;
    font-weight: normal;
    font-size: 14px;
    font-family: Rubik;
    font-style: normal;
    line-height: 20px;
    text-align: center;
    background-color: #fff2f5;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(88, 0, 19, 0.1);
    align-self: center;

    &:after {
      position: absolute;
      top: -20px;
      left: 47%;
      margin-left: -5px;
      border-color: transparent #fff2f5 transparent transparent;
      border-style: solid;
      border-width: 10px;
      transform: rotate(0.25turn);
      content: "";
    }
  }

  &__cardErrorText {
    display: flex;
    align-items: center;
    width: fit-content;
    width: -moz-fit-content;
    height: 4rem;
  }
}

.insuranceBlock {
  margin: 24px 0 0 0;

  &__companies {
    display: flex;
    justify-content: space-between;
    width: max-content;
    min-width: 456px;
    max-width: 696px;
  }

  &__company {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 216px;
    height: 60px;
    background: #fff;
    border: 1px solid rgba(20, 20, 20, 0.1);
    border-radius: 10px;
    cursor: pointer;

    &_active {
      border: 3px solid #45cb85;
    }
  }

  &__companyLogo {
    max-width: 216px;
    max-height: 48px;
  }

  &__data {
    margin: 24px 0 0 0;
  }

  &__title {
    width: 516px;
    height: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    &_red {
      color: #ff0302;
    }
  }

  &__input {
    width: 696px;
    height: 40px;
    margin: 8px 0 0 0;
    padding: 0 0 0 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    background: #fff;
    border: 1px solid rgba(20, 20, 20, 0.1);
    border-radius: 10px;

    &:focus {
      outline: none;
    }
  }
}

.error {
  position: relative;
  display: flex;
  align-items: center;
  width: max-content;
  max-width: 343px;
  height: 44px;
  margin: 12px 0 0 0;
  padding: 0 16px;
  color: #f20035;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background: #fff2f5;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(88, 0, 19, 0.1);

  &::after {
    position: absolute;
    top: -20px;
    left: 47%;
    margin-left: -5px;
    border-color: transparent #fff2f5 transparent transparent;
    border-style: solid;
    border-width: 10px;
    transform: rotate(0.25turn);
    content: "";
  }
}

@media (max-width: 479px) {
  .block {
    &__head {
      max-width: 100vw;
      height: 41px;
    }

    &__headText {
      margin: 0 0 0 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      opacity: 0.5;
    }

    &__paymentBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 16px;
      border-bottom: 1px solid rgba(20, 20, 20, 0.1);
    }

    &__paymentMethodText {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    &__paymentData {
      margin: 0 0 24px 0;
    }
  }

  .inputWrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 343px;
    height: 60px;
    margin: 24px 0 0 0;
    padding-left: 16px;
    font-size: 22px;
    background: #f4f6fb;
    border-radius: 10px;
    cursor: pointer;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &_selected {
      background: #ecfaf3;
    }

    &:first-of-type {
      margin: 32px 0 0 0;
    }
  }

  .bankBlock {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 343px;

    &__bank {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 162px;
      height: 60px;
      margin: 16px 0 0 0;
      background: #fff;
      border: 1px solid rgba(20, 20, 20, 0.1);
      border-radius: 10px;
      cursor: pointer;

      &_active {
        border: 3px solid #45cb85;
      }
    }

    &__image {
      max-width: 162px;
      max-height: 60px;
    }

    &__text {
      width: 107px;
      height: 36px;
      margin: 0 0 0 6px;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 3px;
    }
  }

  .cardForm {
    &__title {
      height: 20px;
      margin: 24px 0 0 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    &__numberBlock {
      margin: 24px 0 0 0;
    }

    &__blockTitle {
      width: 115px;
      height: 20px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    &__numberHolder {
      position: relative;
      width: 343px;
      height: 40px;
      margin: 8px 0 0 0;
      padding: 11px 16px;
      border: 1px solid rgba(20, 20, 20, 0.1);
      border-radius: 10px;
    }

    &__cardImage {
      position: absolute;
      top: 10.5px;
      right: 16px;
    }

    &__expiryAndCvc {
      display: flex;
      margin: 16px 0 0 0;
    }

    &__expiryBlock {
      margin: 0;
    }

    &__expiryHolder {
      width: 162px;
      height: 40px;
      margin: 8px 0 0 0;
      padding: 11px 16px;
      background: #fff;
      border: 1px solid rgba(20, 20, 20, 0.1);
      border-radius: 10px;
    }

    &__cvcBlock {
      margin: 0 0 0 19px;
    }

    &__cvcHolder {
      width: 162px;
      height: 40px;
      margin: 8px 0 0 0;
      padding: 11px 16px;
      background: #fff;
      border: 1px solid rgba(20, 20, 20, 0.1);
      border-radius: 10px;
    }

    &__cardError {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: -moz-max-content;
      width: 343px;
      max-width: 343px;
      padding: 16px;
      color: #f20035;
      font-weight: normal;
      font-size: 14px;
      font-family: Rubik;
      font-style: normal;
      line-height: 20px;
      text-align: center;
      background-color: #fff2f5;
      border-radius: 10px;
      box-shadow: 0px 4px 10px rgba(88, 0, 19, 0.1);

      &:after {
        position: absolute;
        top: -20px;
        left: 47%;
        margin-left: -5px;
        border-color: transparent #fff2f5 transparent transparent;
        border-style: solid;
        border-width: 10px;
        transform: rotate(0.25turn);
        content: "";
      }
    }

    &__cardErrorText {
      display: flex;
      align-items: center;
      width: fit-content;
      width: -moz-fit-content;
      height: 4rem;
    }
  }

  .insuranceBlock {
    margin: 0;

    &__companies {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      min-width: 343px;
      max-width: 343px;
    }

    &__company {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 162px;
      height: 60px;
      margin: 16px 0 0 0;
      background: #fff;
      border: 1px solid rgba(20, 20, 20, 0.1);
      border-radius: 10px;
      cursor: pointer;

      &_active {
        border: 3px solid #45cb85;
      }
    }

    &__companyLogo {
      max-width: 162px;
      max-height: 48px;
    }

    &__data {
      margin: 24px 0 0 0;
    }

    &__title {
      width: 343px;
      height: max-content;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      &_red {
        color: #ff0302;
      }
    }

    &__input {
      width: 343px;
      height: 40px;
      margin: 8px 0 0 0;
      padding: 0 0 0 16px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      background: #fff;
      border: 1px solid rgba(20, 20, 20, 0.1);
      border-radius: 10px;

      &:focus {
        outline: none;
      }
    }
  }
}
