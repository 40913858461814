.survey {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  min-height: 535px;
  font-family: Rubik;
  color: #141414;

  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 64px 0 0 0;
  }

  &__title {
    max-width: 518px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    color: #000;
  }

  &__description {
    margin: 16px 0 6px 0;
    max-width: 580px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #141414;
  }

  &__link {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #2b89e2;
  }

  &__subDescription {
    margin: 0 0 24px 0;
    max-width: 580px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #141414;
  }
}

.form {
  width: 724px;
  height: max-content;
  margin: 0 0 110px 0;
  background: #fff;
  box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
  border-radius: 20px;

  &__headBlock {
    width: 100%;
    height: 140px;
    padding: 32px 0 40px 72px;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);
  }

  &__headInput {
    width: 580px;
    height: 40px;
    margin: 8px 0 0 0;
    padding: 0 0 0 16px;
    background: #fff;
    border: 1px solid rgba(20, 20, 20, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #141414;
    outline: none;
  }

  &__uploadBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__questionsBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid rgba(20, 20, 20, 0.1);
  }

  &__questionWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 162px;
    padding: 32px 0 10px 72px;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);
  }

  &__bottomBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 72px;
  }

  &__consents {
    display: flex;
    flex-direction: column;

    & > div {
      margin: 16px 0 0 0;

      &:first-child {
        margin: 0;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
    height: 56px;
    margin: 40px 0 0 0;
    background: #f95b3d;
    border: none;
    border-radius: 8px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    cursor: pointer;

    &_disabled {
      background: #c0c0c0;
      cursor: default;
    }
  }
}

@media (max-width: 479px) {
  .survey {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1440px;
    min-height: 400px;

    &__top {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 32px 0 0 0;
      background: #f9faff;
    }

    &__title {
      max-width: 343px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #000;
    }

    &__description,
    &__subDescription {
      margin: 16px 0 0 0;
      max-width: 343px;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      color: #141414;
    }
  }

  .background {
    display: none;
  }

  .form {
    width: 100vw;
    height: max-content;
    margin: 24px 0 64px 0;
    background: #fff;
    box-shadow: none;
    border-radius: none;

    &__headBlock {
      width: 100%;
      height: 132px;
      padding: 0 16px 16px 16px;
      border-bottom: 1px solid rgba(20, 20, 20, 0.1);
    }

    &__uploadBlock {
      display: flex;
      flex-direction: column;
      padding: 32px 16px;
    }

    &__uploadBlockLarge {
      height: 435px;
      justify-content: space-between;
    }

    &__questionsBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-top: 1px solid rgba(20, 20, 20, 0.1);
    }

    &__questionWrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: 182px;
      padding: 32px 16px 0 16px;
      border-bottom: 1px solid rgba(20, 20, 20, 0.1);
    }

    &__bottomBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px 16px 40px 16px;
    }

    &__consents {
      display: flex;
      flex-direction: column;

      & > div {
        margin: 16px 0 0 0;

        &:first-child {
          margin: 0;
        }
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 343px;
      height: 56px;
      margin: 24px 0 0 0;
      background: #f95b3d;
      border: none;
      border-radius: 8px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #fff;
      cursor: pointer;

      &_disabled {
        background: #c0c0c0;
        cursor: default;
      }
    }
  }
}
