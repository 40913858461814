@import "../../assets/scss/abstracts.scss";

.formWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 144rem;
  height: 72.7rem;
  background: #f9faff;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #f9faff;
  @include size(600px, 100%);
}

.header {
  margin-top: 5.6rem;
  margin-bottom: 0.8rem;
  color: #141414;
  font-weight: 500;
  font-size: 24px;
  font-family: Rubik;
  font-style: normal;
  line-height: 32px;
  letter-spacing: 0.01em;
  text-align: center;
}

.headerText {
  width: 55.5rem;
  margin-bottom: 3.2rem;
  color: #141414;
  font-weight: normal;
  font-size: 16px;
  font-family: Rubik;
  font-style: normal;
  line-height: 24px;
  text-align: center;
}

.button {
  width: 24rem;
  height: 4rem;
  margin-top: 3.2rem;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  font-family: Rubik;
  font-style: normal;
  line-height: 24px;
  text-align: center;
  background: #45cb85;
  border: 0;
  border-radius: 8px;
  outline: none;

  &:hover {
    border: 1px solid #fff;
  }
}

@media (max-width: 479px) {
  .wrapper {
    max-width: 100%;
    height: auto;
    min-height: 60rem;
    background: #e5e5e5;
  }

  .container {
    min-height: 60rem;
  }

  .header {
    margin-top: 0.8rem;
    font-size: 20px;
  }

  .headerText {
    width: 34.3rem;
  }

  .button {
    width: 34.3rem;
    margin-bottom: 17rem;
  }
}
