.faq {
  &__item {
    width: 804px;
    border-bottom: 1px solid #e8e8e8;

    &:last-of-type {
      border-bottom: none;
    }
  }

  &__question {
    display: flex;
    align-items: center;
    width: 100%;
    height: 74px;
    color: #141414;
    font-weight: 500;
    font-size: 20px;
    font-family: Rubik;
    font-style: normal;
    line-height: 26px;
    cursor: pointer;
  }

  &__arrow {
    margin: 0 0 0 auto;
  }

  &__answer {
    display: none;
    width: 712px;

    margin: -8px 0 24px 0;
    margin-bottom: 24px;
    font-weight: normal;
    font-size: 16px;
    font-family: Rubik;
    font-style: normal;
    line-height: 24px;

    &_opened {
      display: block;
    }

    a {
      color: #141414;
    }
  }
}

@media (max-width: 479px) {
  .faq {
    &__item {
      width: 91%;
      border-bottom: 1px solid #e8e8e8;

      &:last-of-type {
        border-bottom: none;
      }
    }

    &__question {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 32px 0 32px 0;
      font-size: 16px;
      line-height: 24px;

      &__text {
        max-width: 295px;
      }
    }

    &__arrow {
      align-self: flex-start;
      width: 14px;
      height: 18px;
      margin: 6px 0 0 auto;
    }

    &__answer {
      width: 100%;
      margin: 0 0 32px 0;

      margin: -8px 0 24px 0;
      font-weight: normal;
      font-size: 16px;
      font-family: Rubik;
      font-style: normal;
      line-height: 24px;

      &_opened {
        display: block;
      }

      a {
        color: #141414;
      }
    }
  }
}
