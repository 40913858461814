*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

#root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  color: rgb(63, 61, 61);
  font-size: 1.6rem;
}
