@import "../../assets/scss/abstracts.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 144rem;
  height: 30.8rem;
  color: #fff;
  background-color: #270949;
}

.logoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 10.6rem;
  height: 6.3rem;
  margin-top: 3.2rem;
}

.footerLogoImage {
  width: 4.2rem;
  height: 3.9rem;
}

.text {
  margin-top: 1.6rem;
  color: rgba(255, 255, 255, 0.4);
  font-weight: 400;
  font-size: 1.2rem;
  font-family: Rubik;
  font-style: normal;
  line-height: 1.4rem;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  height: 6.5rem;
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
  padding: 0 29.6rem 0 29.6rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.menuItem {
  color: #fff;
  font-weight: 500;
  font-size: 1.4rem;
  font-family: Rubik;
  font-style: normal;
  line-height: 1.7rem;
  letter-spacing: 0.01em;
  text-decoration: none;
}

.iconsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 12.4rem;
  height: 2rem;
  margin-bottom: 3.2rem;
}

@media (max-width: 479px) {
  .wrapper {
    height: 47.9rem;
  }

  .logoWrapper {
    margin-top: 4rem;
  }

  .menu {
    flex-direction: column;
    width: 34rem;
    height: 23rem;
    padding: 3.2rem 0 3.2rem 0;
  }
}
