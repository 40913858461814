.benefits {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 852px;
  overflow: hidden;
  margin: 92px 0 0 0;

  &__background {
    position: absolute;
    bottom: 0;
    left: 100px;
    width: 2434px;
    height: 2434px;
    background: linear-gradient(
      206.16deg,
      #ffffff 61.71%,
      rgba(255, 218, 226, 0.3) 83.53%
    );
    transform: matrix(-1, 0, 0, 1, 0, 0);
    filter: blur(100px);
  }

  &__content {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 659px;
  }

  &__title {
    width: 589px;
    height: 96px;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
  }

  &__subtitle {
    width: 600px;
    height: 52px;
    margin-top: 24px;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
  }

  &__subContent {
    display: flex;
    margin-top: 43px;
  }

  &__image {
    width: 589px;
    height: 516px;
    margin-left: 57px;
  }

  .description {
    margin-top: 110px;

    &__title {
      width: 507px;
      height: 64px;
      margin-top: 56px;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    &__subtitle {
      width: 507px;
      min-height: 64px;
      margin-top: 16px;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
    }
  }
}

@media (max-width: 479px) {
  .benefits {
    height: 882px;
    margin: 64px 0 0 0;

    &__background {
      position: absolute;
      bottom: 0;
      left: 100px;
      width: 2434px;
      height: 2434px;
      background: linear-gradient(
        206.16deg,
        #ffffff 61.71%,
        rgba(255, 218, 226, 0.3) 83.53%
      );
      transform: matrix(-1, 0, 0, 1, 0, 0);
      filter: blur(100px);
    }

    &__content {
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 659px;
    }

    &__title {
      width: 343px;
      font-size: 24px;
      line-height: 32px;
    }

    &__subtitle {
      width: 343px;
      height: 72px;
      margin-top: 16px;
      font-size: 14px;
      line-height: 24px;
    }

    &__subContent {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      margin-top: 25px;
    }

    &__image {
      width: 365px;
      height: 320px;
      margin-left: 0;
    }

    .description {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 25px;

      &__title {
        width: 343px;
        height: 52px;
        margin-top: 32px;
        font-size: 18px;
        line-height: 26px;

        &:first-of-type {
          margin-top: 0;
        }
      }

      &__subtitle {
        width: 343px;
        min-height: 48px;
        margin-top: 8px;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
