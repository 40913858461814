.docs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  min-height: 536px;
  padding: 0 0 80px 0;

  &__title {
    margin: 64px 0 0 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    letter-spacing: 0.01em;
    color: #141414;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 760px;
    min-height: 76px;
    margin: 24px 0 0 0;
    overflow: hidden;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
  }

  &__document {
    display: flex;
    align-items: center;
    width: 696px;
    height: 76px;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);

    &:last-of-type {
      height: 76px;
      border-bottom: none;
    }
  }

  &__documentName {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #141414;
  }

  &__documentLink {
    margin: 0 0 0 auto;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #2b89e2;
  }
}

@media (max-width: 479px) {
  .docs {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1440px;
    min-height: 240px;
    margin: 64px 0;
    padding: 0 0 40px 0;

    &__title {
      margin: 0 0 0 16px;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% - 16px);
      min-height: 105px;
      margin: 16px 0 0 0;
      overflow: hidden;
      background: #fff;
      border-radius: 0;
      box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
    }

    &__document {
      display: flex;
      flex-direction: column;
      align-items: initial;
      width: 100%;
      height: 105px;
      padding: 0 16px;
      border-bottom: 1px solid rgba(20, 20, 20, 0.1);

      &:last-of-type {
        height: 104px;
        border-bottom: none;
      }
    }

    &__documentName {
      width: 300px;
      margin: 24px 0 0 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    &__documentLink {
      margin: 8px 0 0 0;
      color: #2b89e2;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
