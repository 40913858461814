@import "../../assets/scss/abstracts.scss";

.blockHidden {
  display: none;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signupPageWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 144rem;
  height: auto;
  min-height: 536px;
  background: #f9faff;
}

.signupPageHeader {
  margin-top: 5.6rem;
  color: #141414;
  font-weight: 500;
  font-size: 24px;
  font-family: Rubik;
  font-style: normal;
  line-height: 32px;
  letter-spacing: 0.01em;
  text-align: center;
}

.signupPageNamesWrapper {
  display: flex;
  justify-content: space-between;
  width: 46rem;
  margin-top: 3.2rem;
}

.signupPageInputText {
  color: #141414;
  font-weight: 500;
  font-size: 14px;
  font-family: Rubik;
  font-style: normal;
  line-height: 20px;
}

.signupPageInputError {
  border: 1px solid #f20035 !important;
}

.signupPageNameError {
  position: absolute;
  top: 2.5rem;
  left: 480px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: max-content;
  max-width: 343px;
  height: 4.2rem;
  color: #f20035;
  font-weight: normal;
  font-size: 14px;
  font-family: Rubik;
  font-style: normal;
  line-height: 20px;
  text-align: start;
  background-color: #fff2f5;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(88, 0, 19, 0.1);

  &__sex {
    top: 1.8rem;
    left: 46%;
  }
}

.signupPageNameError::after {
  position: absolute;
  top: 13px;
  left: -15px;
  margin-left: -5px;
  border-color: transparent #fff2f5 transparent transparent;
  border-style: solid;
  border-width: 10px;
  content: "";
}

.signupPageErrorText {
  display: inline-block;
  width: fit-content;
  margin: 16px;
}

.signupPageInputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 46rem;
  margin-top: 2.4rem;
}

.birthDate {
  display: flex;
  align-items: center;
  width: 460px;
  height: 40px;
  margin: 8px 0 0 0;
  background: #fff;
  border: 1px solid rgba(20, 20, 20, 0.1);
  border-radius: 10px;
  overflow: hidden;

  &_hasError {
    border: 1px solid #f20035;
  }

  &__holder {
    position: relative;
  }

  &__input {
    text-align: center;
    width: 33.333%;
    height: 38px;
    border: none;
    border-right: 1px solid rgba(20, 20, 20, 0.1);
    outline: none;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #141414;

    &:last-of-type {
      border: none;
    }

    &::placeholder {
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__image {
    width: 18px;
    height: 16px;
    margin: 0 24px 0 auto;
    cursor: pointer;
  }
}

.signupPageSexWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 46rem;
  height: 5.2rem;
  margin-top: 2.4rem;
}

.signupPageSexRadioWrapper {
  display: flex;
  justify-content: flex-start;
}

.signupPageSexRadio {
  display: none;
}

.signupPageSexLabel {
  position: relative;
  margin-right: 4rem;
  margin-left: 3rem;
  color: #141414;
  font-weight: normal;
  font-size: 16px;
  font-family: Rubik;
  font-style: normal;
  line-height: 20px;
  cursor: pointer;
}

.signupPageSexLabel::before {
  position: absolute;
  top: 50%;
  left: -3rem;
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  border: 2px solid #e8e8e8;
  border-radius: 50%;
  transform: translateY(-50%);
  transition: border-color 400ms ease;
  content: "";
}

.signupPageSexLabel::after {
  position: absolute;
  top: 50%;
  left: -3rem;
  width: 2rem;
  height: 2rem;
  background-color: #f9faff;
  border: 2px solid #45cb85;
  border-radius: 50%;
  transform: translateY(-50%) scale(0);
  transition: transform 400ms ease;
  content: "";
}

.signupPageSexRadio:checked + .signupPageSexLabel::before {
  background-color: #45cb85;
  border-color: #45cb85;
}

.signupPageSexRadio:checked + .signupPageSexLabel::after {
  transform: translateY(-50%) scale(0.55);
}

.signupPageButton {
  width: 24rem;
  height: 4rem;
  margin-top: 3.2rem;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  font-family: Rubik;
  font-style: normal;
  line-height: 24px;
  text-align: center;
  border: 0;
  border-radius: 8px;
  outline: none;

  &__enabled {
    background: #45cb85;
  }

  &__disabled {
    background: #c0c0c0;
  }
}

.signupPageCheckboxWrapper {
  display: flex;
  align-items: flex-start;
  width: 46rem;

  &__private {
    margin-top: 2.4rem;
  }

  &__data {
    margin-top: 1.6rem;
  }
}

.signupPageCheckbox {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border: 1px solid #e8e8e8;
  border-radius: 3.5px;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
}

.signupPageCheckbox::after {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 13px;
  content: url(../../assets/image/icons/check-solid.svg);
}

.signupPageCheckbox:checked {
  background-color: #45cb85;
  border-color: #45cb85;
}

.signupPageCheckboxText {
  color: #222222;
  font-weight: normal;
  font-size: 12px;
  font-family: Rubik;
  font-style: normal;
  line-height: 18px;
}

.signupPageCheckboxLink {
  color: #2b89e2;
  font-weight: 500;
  font-size: 12px;
  font-family: Rubik;
  font-style: normal;
  line-height: 18px;
  text-align: center;
}

.signupPageFooterWrapper {
  margin-top: 3.2rem;
  margin-bottom: 8rem;
}

.signupPageFooterText {
  color: #868686;
  font-weight: normal;
  font-size: 16px;
  font-family: Rubik;
  font-style: normal;
  line-height: 24px;
  text-align: center;
}

.signupPageFooterLink {
  color: #2b89e2;
  font-weight: 500;
  font-size: 16px;
  font-family: Rubik;
  font-style: normal;
  line-height: 24px;
  text-align: center;
}

@media (max-width: 479px) {
  .signupPageWrapper {
    max-width: 100%;
    min-height: 60rem;
  }

  .signupPageHeader {
    margin-top: 5.6rem;
    font-size: 20px;
  }

  .signupPageNamesWrapper {
    flex-direction: column;
    width: 34.3rem;
    height: auto;
    margin-top: 0;
  }

  .signupPageInputWrapper {
    width: 34.3rem;
  }

  .birthDate {
    display: flex;
    align-items: center;
    width: 343px;
    height: 40px;
    background: #fff;
    border: 1px solid rgba(20, 20, 20, 0.1);
    border-radius: 10px;
    overflow: hidden;

    &_hasError {
      border: 1px solid #f20035;
    }

    &__holder {
      position: relative;
    }

    &__input {
      text-align: center;
      width: 33.333%;
      height: 38px;
      border: none;
      border-right: 1px solid rgba(20, 20, 20, 0.1);
      outline: none;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #141414;

      &:last-of-type {
        border: none;
      }

      &::placeholder {
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
      }
    }

    &__image {
      width: 18px;
      height: 16px;
      margin: 0 24px 0 auto;
      cursor: pointer;
    }
  }

  .signupPageInput {
    width: 34.3rem;
  }

  .signupPageNameError {
    position: relative;
    top: 0%;
    left: 0%;
    width: 34.3rem;
    height: 3.7rem;
    margin-top: 16px;
  }

  .signupPageNameError::after {
    top: -16px;
    left: 47%;
    margin-left: -5px;
    border-color: transparent transparent #fff2f5 transparent;
    border-style: solid;
    border-width: 8px;
    content: "";
  }

  .signupPageSexWrapper {
    width: 34.3rem;
    height: 100%;
  }

  .signupPageButton {
    width: 34.3rem;
    margin-top: 2.4rem;
  }

  .signupPageCheckboxWrapper {
    width: 34.3rem;
  }

  .signupPageFooterWrapper {
    margin-top: 2.4rem;
    margin-bottom: 8rem;
  }
}
