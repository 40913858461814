.result {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: linear-gradient(to right bottom, #f9faff 75%, #2d73f628);

  &__check {
    display: flex;
    background-color: #45cb85;
    width: 64px;
    height: 64px;
    border-radius: 70px;
    justify-content: center;
    align-items: center;

    &__img {
      width: 28px;
    }
  }

  &__title {
    font-size: 40px;
    font-family: Rubik;
    font-weight: 500;
    line-height: 47px;
    margin-bottom: 0;
  }

  &__text {
    display: flex;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    max-width: 731px;
    text-align: center;
    margin-bottom: 56px;
  }

  &__failed {
    display: flex;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    max-width: 731px;
    text-align: center;
    margin-bottom: 56px;
    color: #ff0000;
  }

  &__btn {
    font-family: Rubik;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #f95b3d;
    width: 296px;
    height: 56px;
    border: none;
    border-radius: 8px;
    margin-bottom: 200px;

    &:active {
      background-color: #f95c3dda;
    }
  }
}

@media (max-width: 479px) {
  .result {
    align-items: flex-start;
    margin-bottom: 80px;
    background-image: none;
    margin-left: 20px;
    &__check {
      &__img {
        width: 28px;
      }
    }

    &__title {
      font-size: 20px;
      line-height: 24px;
    }

    &__text {
      text-align: left;
      max-width: 343px;
      margin-bottom: 40px;
    }

    &__failed {
      text-align: left;
      max-width: 343px;
      margin-bottom: 40px;
    }

    &__btn {
      margin-bottom: 80px;
    }
  }
}
