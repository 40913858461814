.head {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1440px;
  min-height: 850px;
  padding: 0 0 64px 0;

  &__imageBlock {
    width: 578px;
  }

  &__mainImageHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 578px;
    height: 480px;
    margin: 6px 0 0 0;
    background: #fff;
    border: 2px solid #fafafa;
  }

  &__mainImage {
    max-width: 553px;
    max-height: 415px;
  }

  &__images {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 578px;
    height: 75px;
    margin: 33px 0 0 0;
  }

  &__prevImageButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    margin: 0 13.5px 0 0;
    background: #f4f6fb;
    border-radius: 50%;
    cursor: pointer;

    &__arrow {
      width: 0;
      height: 0;
      margin: 0 0 0 -3px;
      border-top: 8px solid transparent;
      border-right: 12px solid #4654a3;
      border-bottom: 8px solid transparent;

      &_disabled {
        border-right: 12px solid #bcbec5;
      }
    }
  }

  &__smallImageHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75px;
    height: 75px;
    margin: 0 0 0 18.5px;
    background: #fafafa;
    cursor: pointer;

    &:last-of-type {
      margin: 0;
    }

    &_active {
      background: #4654a3;
    }

    &_hidden {
      display: none;
    }
  }

  &__smallImage {
    min-width: 73px;
    max-width: 73px;
    min-height: 73px;
    max-height: 73px;
  }

  &__nextImageButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    margin: 0 0 0 32px;
    background: #f4f6fb;
    border-radius: 50%;
    cursor: pointer;

    &__arrow {
      width: 0;
      height: 0;
      margin: 0 -3px 0 0;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 12px solid #4654a3;

      &_disabled {
        border-left: 12px solid #bcbec5;
      }
    }
  }

  &__benefits {
    display: flex;
    flex-direction: column;
    margin: 53px 0 0 0;
  }

  &__benefit {
    display: flex;
    align-items: center;
    max-width: 491px;
    height: 100%;
    min-height: 22px;
    margin: 24px 0 0 0;

    &:first-of-type {
      margin: 0;
    }
  }

  &__iconHolder {
    min-width: 30px;
  }

  &__benefitText {
    margin: 0 0 0 16px;
    color: #222222;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  &__textBlock {
    max-width: 531px;
    margin: 6px 0 0 51px;
  }

  &__title {
    width: 421px;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
  }

  &__description {
    width: 490px;
    margin: 8px 0 0 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  &__prices {
    display: flex;
    height: 71px;
    margin: 20px 0 0 0;
    border-bottom: 1px solid #ebebeb;
  }

  &__price {
    height: 47px;
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;

    &__euro {
      font-weight: 400;
    }
  }

  &__priceBeforeDiscount {
    display: flex;
    align-items: center;
    height: 47px;
    margin: 0 0 0 16px;
    color: #eb3f26;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: line-through;

    &__euro {
      font-weight: 400;
    }
  }

  &__plans {
    margin-top: 24px;

    &__title {
      width: 87px;
      height: 19px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      opacity: 0.6;
    }

    &__inputHolder {
      display: flex;
      align-items: center;
      width: 531px;
      height: 60px;
      margin: 8px 0 0 0;
      background: #f4f6fb;
      border-radius: 10px;

      &:first-of-type {
        margin: 0;
      }
    }

    &__input {
      margin: 0 6px 0 16px;
    }

    // custom checbox
    .inputWrapper {
      position: relative;
      display: flex;
      align-items: center;
      width: 531px;
      height: 60px;
      margin: 8px 0 0 0;
      padding-left: 52px;
      font-weight: 500;
      font-size: 22px;
      font-size: 16px;
      line-height: 20px;
      background: #f4f6fb;
      border-radius: 10px;
      cursor: pointer;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    // Hide the browser's default radio button
    .inputWrapper input {
      position: absolute;
      width: 0;
      height: 0;
      cursor: pointer;
      opacity: 0;
    }

    // Create a custom radio button
    .checkmark {
      position: absolute;
      top: 20px;
      left: 16px;
      width: 18px;
      height: 18px;
      background: #fff;
      border: 1px solid #e8e8e8;
      border-radius: 50%;
    }

    // When the radio button is checked, add a blue background
    .inputWrapper input:checked ~ .checkmark {
      background: #45cb85;
      border: 1px solid #45cb85;
    }

    // Create the indicator (the dot/circle - hidden when not checked)
    .checkmark:after {
      position: absolute;
      display: none;
      content: "";
    }

    // Show the indicator (dot/circle) when checked
    .inputWrapper input:checked ~ .checkmark:after {
      display: block;
    }

    // Style the indicator (dot/circle)
    .inputWrapper .checkmark:after {
      top: 4px;
      left: 4px;
      width: 8px;
      height: 8px;
      background: white;
      border-radius: 50%;
    }

    &__dropdown {
      position: relative;
      margin: 0 0 0 10px;
    }

    &__active {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      width: 66px;
      height: 40px;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      background: #fff;
      border: 1px solid rgba(20, 20, 20, 0.1);
      border-radius: 10px;
      cursor: pointer;
    }

    &__activeNumber {
      margin: 0 0 0 16px;
    }

    &__arrowDown {
      width: 0;
      height: 0;
      margin: 0 16px 0 auto;
      border-top: 5px solid black;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      opacity: 0.6;
    }

    &__item {
      display: flex;
      align-items: center;
      width: 64px;
      height: 36px;
      padding-left: 16px;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      background: #fff;
      cursor: pointer;

      &:hover {
        background: #f4f6fb;
      }
    }

    &__text {
      margin: 0 0 0 10px;
    }

    &__discount {
      height: 20px;
      margin: 0 0 0 8px;
      color: #eb3f26;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }

    &__hint {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 532px;
      height: 44px;
      margin: 16px 0 0 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      background: rgba(69, 203, 133, 0.15);
      border-radius: 10px;

      &::after {
        position: absolute;
        top: -14px;
        left: 256px;
        margin-left: -5px;
        border-color: transparent transparent rgba(69, 203, 133, 0.15)
          transparent;
        border-style: solid;
        border-width: 7px;
        content: "";
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    width: 531px;
    height: 56px;
    margin: 40px 0 0 0;
    background: #4654a3;
    border: none;
    border-radius: 8px;
    cursor: pointer;

    &_disabled {
      background: grey;
      cursor: default;
    }
  }

  &__shoppingCart {
    width: 21px;
    height: 18px;
    margin: 0 0 0 187px;
  }

  &__buttonText {
    margin: 0 0 0 18px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }
}

@media (max-width: 479px) {
  .head {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    width: 100%;
    max-width: 479px;
    height: 100%;
    padding: 0 0 32px 0;

    &__imageBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      &__imageContent {
        position: absolute;
        margin-top: -843px;
      }

      &__imageContent_fromSaliva {
        position: absolute;
        top: 260px;
      }

      &__imageContent_fromBlood {
        position: absolute;
        top: 426px;
      }
    }

    &__mainImageHolder {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 343px;
      height: 286px;
      margin: 0;
      background: #fff;
      border: 2px solid #fafafa;
    }

    &__mainImage {
      max-width: 325px;
      max-height: 244px;
    }

    &__images {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 343px;
      height: 75px;
      margin: 16px 0 0 0;
    }

    &__prevImageButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      margin: 0 9px 0 0;
      background: #f4f6fb;
      border-radius: 50%;
      cursor: pointer;

      &__arrow {
        width: 0;
        height: 0;
        margin: 0 0 0 -3px;
        border-top: 6px solid transparent;
        border-right: 8px solid #4654a3;
        border-bottom: 6px solid transparent;

        &_disabled {
          border-right: 8px solid #bcbec5;
        }
      }
    }

    &__smallImageHolder {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 75px;
      min-width: 75px;
      height: 75px;
      margin: 0 0 0 10px;
      background: #fafafa;
      cursor: pointer;

      &:last-of-type {
        margin: 0;
      }

      &_active {
        background: #4654a3;
      }

      &_hidden {
        display: none;
      }
    }

    &__smallImage {
      min-width: 73px;
      max-width: 73px;
      min-height: 73px;
      max-height: 73px;
    }

    &__nextImageButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      margin: 0 0 0 19px;
      background: #f4f6fb;
      border-radius: 50%;
      cursor: pointer;

      &__arrow {
        width: 0;
        height: 0;
        margin: 0 -3px 0 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 8px solid #4654a3;

        &_disabled {
          border-left: 8px solid #bcbec5;
        }
      }
    }

    &__benefits {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 40px 0 40px 0;
    }

    &__benefit {
      display: flex;
      align-items: center;
      max-width: 343px;
      height: 100%;
      min-height: 16px;
      margin: 24px 0 0 0;

      &:first-of-type {
        margin: 0;
      }
    }

    &__benefitText {
      margin: 0 0 0 16px;
      color: #222222;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }

    &__textBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 479px;
      margin: 24px 0 0 0;
    }

    &__title {
      width: 343px;
      font-size: 20px;
      line-height: 28px;
    }

    &__description {
      width: 343px;
      margin: 8px 0 0 0;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      white-space: normal;
    }

    &__rating {
      display: flex;
      align-items: center;
      width: 343px;
      height: 26px;
      margin: 16px 0 0 0;
    }

    &__prices {
      display: flex;
      width: 343px;
      height: 71px;
      margin: 421px 0 0 0;
      border-bottom: 1px solid #ebebeb;
    }

    &__priceBeforeDiscount {
      display: flex;
      align-items: center;
      height: 38px;
      margin: 0 0 0 25px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-decoration-line: line-through;

      &__euro {
        font-weight: 400;
      }
    }

    &__plans {
      margin-top: 24px;

      &__inputHolder {
        display: flex;
        align-items: center;
        width: 343px;
        height: 40px;
      }

      &__input {
        margin: 0 6px 0 16px;
      }

      // custom checbox
      .inputWrapper {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 343px;
        height: 100%;
        min-height: 60px;
        margin: 8px 0 0 0;
        padding-left: 52px;
        background: #f4f6fb;
        border-radius: 10px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      &__dropdown {
        position: relative;
        margin: 10px 0 0 10px;
      }

      &__active {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        width: 109px;
        height: 40px;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        background: #fff;
        border: 1px solid rgba(20, 20, 20, 0.1);
        border-radius: 10px;
        cursor: pointer;
      }

      &__item {
        display: flex;
        align-items: center;
        width: 107px;
        height: 36px;
        padding-left: 16px;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        background: #fff;
        cursor: pointer;

        &:hover {
          background: #f4f6fb;
        }
      }

      &__text {
        margin: 10px 0 0 10px;

        &:first-of-type {
          margin: 10px 0 0 0;
        }
      }

      &__discount {
        height: 20px;
        margin: 8px 0 10px 0;
        color: #eb3f26;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }

      &__hint {
        width: 343px;
        height: 72px;
        margin: 10px 0 0 0;
        padding: 0 12px;
      }
    }

    &__button {
      width: 343px;
      height: 56px;
      margin: 24px 0 0 0;
    }

    &__shoppingCart {
      margin: 0 0 0 92px;
    }
  }
}
