.stressometer {
  display: flex;
  width: calc(100vw - 32px);
  max-width: 900px;
  height: max-content;
  margin: 32px 0 0 0;
  padding: 40px;
  background: #ebeeff;
  box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
  border-radius: 20px;

  &__image {
    width: 220px;
    height: 220px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 32px;
  }

  &__title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: #141414;
  }

  &__text {
    width: calc(100vw - 64px);
    max-width: 508px;
    margin: 14px 0 0 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    color: #141414;
  }

  &__textBold {
    margin: 16px 0 0 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #141414;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 277px;
    height: 40px;
    margin: 24px 0 0 0;
    background: #45cb85;
    border-radius: 8px;
    text-decoration: none;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
  }
}

@media (max-width: 479px) {
  .stressometer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100vw - 32px);
    max-width: 900px;
    height: max-content;
    margin: 32px 0 0 0;
    padding: 24px 12px;
    background: #ebeeff;
    box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
    border-radius: 20px;

    &__imageWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__image {
      margin: 16px 0 0 0;
      width: 150px;
      height: 150px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 12px 0 0 0;
    }

    &__title {
      margin: 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 0.01em;
      color: #141414;
    }

    &__text {
      width: calc(100% - 24px);
      max-width: 508px;
      margin: 16px 0 0 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #141414;
    }

    &__textBold {
      width: calc(100% - 24px);
      margin: 16px 0 0 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #141414;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 40px;
      margin: 16px 0 0 0;
      background: #45cb85;
      border-radius: 8px;
      text-decoration: none;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #fff;
    }
  }
}
