.benefits {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  height: 232px;
  background: #f9faff;

  &__fromBlood {
    & > div {
      margin-left: 64px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__fromSaliva {
    & > div {
      margin-left: 35px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__list {
    display: flex;
    margin-top: 64px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 165px;
    height: 112px;
  }

  &__imageHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
  }

  &__image {
    max-width: 65px;
    max-height: 40px;
  }

  &__description {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 164px;
    height: 48px;
    margin-top: 24px;
    color: #141414;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    white-space: pre-line;
    text-align: center;
  }
}

@media (max-width: 479px) {
  .benefits {
    max-width: 479px;
    height: 100%;
    min-height: 360px;
    max-height: 448px;

    &__fromBlood {
      & > div {
        margin: 0;

        &:first-child {
          margin: 0;
        }
      }

      .benefits {
        &__list {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 360px;
        }

        &__item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 156px;
          height: 72px;
          margin: 32px 0 0 0;

          &:first-of-type {
            margin: 40px 0 0 0;
          }
        }
      }
    }

    &__fromSaliva {
      & > div {
        margin: 0;

        &:first-child {
          margin: 40px 0 0 0;
        }
      }

      .benefits {
        &__list {
          flex-direction: column;
          flex-wrap: wrap;
          align-items: center;
          width: 100%;
          max-height: 448px;
        }

        &__item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 156px;
          height: 96px;
          margin: 0 16px 40px 16px;
        }
      }
    }

    &__imageHolder {
      height: 32px;
    }

    &__image {
      max-width: 65px;
      max-height: 32px;
    }

    &__description {
      width: 156px;
      margin-top: 16px;
    }
  }
}
