.popular {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 624px;

  scrollbar-color: rgba(34, 34, 34, 0.08) rgba(34, 34, 34, 0.12);
  scrollbar-width: 6px;

  &__title {
    width: 324px;
    height: 48px;
    margin-top: 104px;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
  }

  &__list {
    display: flex;
    max-width: 1200px;
    height: 330px;
    margin: 56px 0 0 0;
    overflow-x: scroll;
  }

  ::-webkit-scrollbar {
    height: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(34, 34, 34, 0.08);
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    width: 10px;
    background: rgba(34, 34, 34, 0.12);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(34, 34, 34, 0.2);
  }

  &__item {
    min-width: 420px;
    max-width: 420px;
    min-height: 250px;
    max-height: 250px;
    margin-left: 48px;
    border-radius: 20px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);

    &:first-child {
      margin-left: 0;
    }
  }

  &__name {
    margin: 48px 0 0 32px;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
  }

  &__description {
    margin: 16px 0 0 32px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 212px;
    height: 40px;
    margin: 24px 0 0 32px;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-decoration: none;
    background: #45cb85;
    border-radius: 8px;
  }
}

@media (max-width: 479px) {
  .popular {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 1040px;

    &__title {
      width: 343px;
      height: 32px;
      margin-top: 56px;
      font-size: 24px;
      line-height: 32px;
    }

    &__list {
      display: flex;
      flex-direction: column;
      align-self: center;
      max-width: 100%;
      height: 920px;
      margin: 32px 0 0 0;
      overflow-x: hidden;
    }

    &__item {
      min-width: 375px;
      max-width: 375px;
      min-height: 202px;
      max-height: 202px;
      margin-top: 24px;
      margin-left: 0;
      border-radius: 20px;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);

      &:first-child {
        margin-top: 0;
        margin-left: 0;
      }
    }

    &__name {
      margin: 24px 0 0 16px;
    }

    &__description {
      margin: 16px 0 0 16px;
    }

    &__link {
      margin: 24px 0 0 16px;
    }
  }
}
