.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 804px;
  min-height: 128px;
  border-bottom: 1px solid #e8e8e8;

  &:last-child {
    border: none;
  }
}

.hiddenAnchor {
  position: absolute;
  color: #fff;
  background: #fff;
  background-color: #fff;
  cursor: default;

  &:focus {
    outline: none;
  }
}

.type {
  width: 804px;
  min-height: 128px;
  cursor: pointer;

  &__content {
    display: flex;
    align-items: center;
    height: 48px;
    margin-top: 40px;
  }

  &__image {
    max-width: 48px;
    max-height: 48px;
  }

  &__name {
    margin-left: 32px;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
  }

  &__description {
    margin-left: 16px;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;

    &_bold {
      display: inline-block;
      font-weight: bold;
    }
  }

  &__popular {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85px;
    height: 29px;
    margin-left: 16px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    background: #ffa500;
    border-radius: 10px;
  }

  &__new {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 81px;
    height: 29px;
    margin-left: 16px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    background: #f95b3d;
    border-radius: 10px;
  }

  &__arrow {
    margin-left: auto;
  }

  &_opened {
    min-height: 112px;
  }
}

@media (max-width: 479px) {
  .wrapper {
    width: 100vw;
    min-height: 80px;

    &:last-child {
      border: none;
    }

    // covid type styles
    &:first-child {
      min-height: 102px;

      .type__content {
        position: relative;
        height: 78px;
        height: 102px;
        margin-top: -10px;
      }

      .type__name {
        width: 211px;
        height: 19px;
        margin-left: 16px;
        font-size: 16px;
        line-height: 19px;
      }

      .type__description {
        position: absolute;
        top: 66px;
        left: 48px;
        width: 160px;
        height: 17px;
        font-size: 14px;
        line-height: 17px;
      }

      .type__new {
        position: absolute;
        top: 64px;
        left: 209px;
        width: 66px;
        min-width: 66px;
        height: 22px;
        margin-left: 6px;
        font-size: 12px;
        line-height: 14px;
        border-radius: 6px;
      }
    }
  }

  .type {
    width: 100%;
    min-height: 80px;
    margin-left: 16px;

    &__content {
      height: 32px;
      margin-top: 24px;
    }

    &__image {
      max-width: 32px;
      max-height: 32px;
    }

    &__name {
      margin-left: 16px;
      font-size: 16px;
      line-height: 19px;
    }

    &__description {
      margin-left: 0;
      font-size: 14px;
      line-height: 17px;

      &_bold {
        display: inline-block;
        font-weight: bold;
      }
    }

    &__popular {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 85px;
      height: 29px;
      margin-left: 16px;
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      background: #ffa500;
      border-radius: 10px;
    }

    &__new {
      width: 66px;
      min-width: 66px;
      height: 22px;
      margin-left: 6px;
      font-size: 12px;
      line-height: 14px;
      border-radius: 6px;
    }

    &__arrow {
      width: 10px;
      height: 14px;
      margin: 0 18px 0 auto;
    }

    &_opened {
      min-height: 80px;
    }
  }
}

.hidden {
  display: none;
  visibility: hidden;
}
