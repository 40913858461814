.radio {
  position: relative;
  cursor: pointer;
  width: 18px;
  height: 18px;

  &_size20 {
    width: 20px;
    height: 20px;
  }

  input {
    position: absolute;
    width: 0;
    height: 0;
    cursor: pointer;
    opacity: 0;
    display: none;
    visibility: hidden;
  }

  input:checked ~ .checkMark {
    background: #45cb85;
    border: 1px solid #45cb85;
  }

  input:checked ~ .checkMark:after {
    display: block;
  }
}

.checkMark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 50%;

  &:after {
    position: absolute;
    display: none;
    content: "";
  }

  &:after {
    top: calc((100% - 8px) / 2);
    left: calc((100% - 8px) / 2);
    width: 8px;
    height: 8px;
    background: white;
    border-radius: 50%;
  }
}
