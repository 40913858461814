.wrapper {
  min-width: 760px;
  min-height: 416px;
  border-radius: 20px;
}

.order {
  display: flex;
  flex-direction: column;
  width: 760px;
  height: 100%;
  padding: 0 0 32px;
  overflow: hidden;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 40px rgb(46 46 46 / 7%);

  &__payButtonBlock {
    align-self: center;
  }

  &__payButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 296px;
    height: 56px;
    margin: 32px 0 0 0;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-decoration: none;
    background: #f95b3d;
    border-radius: 8px;

    &_disabled {
      background: #c0c0c0;
    }
  }
}

.formWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 479px) {
  .wrapper {
    min-width: 100%;
    min-height: 200px;
  }

  .order {
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    height: 100%;
    padding: 0 0 24px 0;
    border-radius: 0;

    &__payButtonBlock {
      position: absolute;
      bottom: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 104px;
      background: #fff;
    }

    &__payButton {
      width: 343px;
      height: 56px;
      margin: 0;
    }
  }
}
