@import "../../assets/scss/abstracts.scss";

.blockHidden {
  display: none;
}

.loginPageWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 144rem;
  height: 72.7rem;
  background: #f9faff;
}

.loginPageWrapperHidden {
  display: none;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginPageContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #f9faff;
  @include size(600px, 100%);
}

.loginPageHeader {
  margin-top: 5.6rem;
  margin-bottom: 0.8rem;
  color: #141414;
  font-weight: 500;
  font-size: 24px;
  font-family: Rubik;
  font-style: normal;
  line-height: 32px;
  letter-spacing: 0.01em;
  text-align: center;
}

.loginPageHeaderText {
  margin-bottom: 4rem;
  color: #141414;
  font-weight: normal;
  font-size: 16px;
  font-family: Rubik;
  font-style: normal;
  line-height: 24px;
  text-align: center;
}

.loginPageButton {
  width: 24rem;
  height: 4rem;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  font-family: Rubik;
  font-style: normal;
  line-height: 24px;
  text-align: center;
  background: #45cb85;
  border: 0;
  border-radius: 8px;
  outline: none;

  &:hover {
    border: 1px solid #fff;
  }
}

.loginPageText {
  color: #00f;
  font-size: 14px;
}

.loginPageLinkWrapper {
  width: 46rem;
  margin-top: 8px;
}

.loginPageLink {
  color: #2b89e2;
  font-weight: 500;
  font-size: 16px;
  font-family: Rubik;
  font-style: normal;
  line-height: 24px;
  text-align: center;
}

.loginPageCheckboxWrapper {
  display: flex;
  align-items: center;
  width: 46rem;
  height: 1.6rem;
  margin-top: 3.3rem;
  margin-bottom: 4rem;
}

.loginPageCheckbox {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border: 1px solid #e8e8e8;
  border-radius: 3.5px;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
}

.loginPageCheckbox::after {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 13px;
  content: url(../../assets/image/icons/check-solid.svg);
}

.loginPageCheckbox:checked {
  background-color: #45cb85;
  border-color: #45cb85;
}

.loginPageCheckboxText {
  color: #222222;
  font-weight: normal;
  font-size: 16px;
  font-family: Rubik;
  font-style: normal;
  line-height: 15px;
}

.loginPageFooterWrapper {
  margin-top: 2.4rem;
  margin-bottom: 20rem;
}

.loginPageFooterText {
  color: #868686;
  font-weight: normal;
  font-size: 16px;
  font-family: Rubik;
  font-style: normal;
  line-height: 24px;
  text-align: center;
}

@media (max-width: 479px) {
  .loginPageWrapper {
    max-width: 100%;
    height: auto;
    background: #e5e5e5;
  }

  .loginPageHeader {
    margin-top: 2px;
    font-size: 20px;
  }

  .loginPageHeaderText {
    width: 34.3rem;
    margin-bottom: 0.1rem;
  }

  .loginPageLinkWrapper {
    width: 34.3rem;
    margin-top: 2.3rem;
  }

  .loginPageCheckboxWrapper {
    width: 34.3rem;
    margin-top: 2.4rem;
    margin-bottom: 3.2rem;
  }

  .loginPageButton {
    width: 34.3rem;
  }

  .loginPageFooterWrapper {
    margin-bottom: 5.1rem;
  }
}
