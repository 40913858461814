.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;
  width: 100%;
  border-bottom: 1px solid rgba(20, 20, 20, 0.1);

  &:last-child {
    border: none;
  }

  &__uploadTitle {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    width: 580px;
    margin: 8px 0 8px 0;
  }

  &__uploadTitleBig {
    font-family: "Rubik";
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }

  &__dropBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 580px;
    height: max-content;
    padding: 0 0 26px 0;
    border: 2px dashed #e8e8e8;
    box-sizing: border-box;
    border-radius: 10.8108px;
    background-color: #ffffff;
  }

  &__image {
    width: 43.24px;
    height: 45.95px;
    margin: 27px 0 0 0;
  }

  &__title {
    margin: 16px 0 0 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #141414;
  }

  &__uploadButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 144px;
    height: 40px;
    margin: 18px 0 0 0;
    background: #45cb85;
    border-radius: 8px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    cursor: pointer;
  }

  &__input {
    display: none;
  }

  &__hintsList {
    margin: 16px 0 0 0;
  }

  &__hint {
    margin: 5px 0 0 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #8a8a8a;

    &:first-of-type {
      margin: 0;
    }
  }
}

.successContent {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    width: 88px;
    height: 88px;
    margin-top: 40px;
  }

  &__title {
    margin: 32px 0 0 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #141414;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 235px;
    margin: 48px 0 0 0;
    padding: 0 16px;
    background: #45cb85;
    border-radius: 8px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    cursor: pointer;
  }

  &__passwordBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 235px;

    &__title {
      margin: 16px 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      color: #141414;
    }

    &__inputWrapper {
      height: 50px;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      width: 235px;
      margin: 48px 0 0 0;
      padding: 0 16px;
      background: #45cb85;
      border-radius: 8px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      cursor: pointer;
    }
  }
}

.errorContent {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    width: 88px;
    height: 88px;
  }

  &__title {
    margin: 24px 0 0 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #141414;
  }

  &__subTitle {
    margin: 16px 0 0 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #141414;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    margin: 24px 0 0 0;
    padding: 0 16px;
    background: #f95b3d;
    border-radius: 8px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    cursor: pointer;
  }
}

@media (max-width: 479px) {
  .upload {
    &__uploadTitle {
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      width: 343px;
      margin: 0 0 8px 0;
    }

    &__dropBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 343px;
      height: max-content;
      padding: 0 0 18px 0;
      border: 3px dashed #e8e8e8;
      box-sizing: border-box;
      border-radius: 16px;
    }

    &__image {
      width: 48px;
      height: 51px;
      margin: 32px 0 0 0;
    }

    &__title {
      max-width: 200px;
      margin: 16px 0 0 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.01em;
      color: #141414;
      text-align: center;
    }

    &__uploadButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 210px;
      height: 48px;
      margin: 24px 0 0 0;
      background: #45cb85;
      border-radius: 8px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      cursor: pointer;
    }

    &__input {
      display: none;
    }

    &__hintsList {
      margin: 18px 0 0 0;
    }

    &__hint {
      margin: 6px 0 0 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #8a8a8a;

      &:first-of-type {
        margin: 0;
      }
    }
  }

  .successContent {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__image {
      width: 64px;
      height: 64px;
    }

    &__title {
      margin: 32px 0 0 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 34px;
      line-height: 40px;
      letter-spacing: 0.01em;
      color: #141414;
      text-align: center;
    }

    &__button {
      width: 275px;
      font-size: 18px;
      line-height: 24px;
    }

    &__passwordBlock {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 340px;

      &__inputWrapper {
        height: 90px;
      }

      &__button {
        width: 275px;
        font-size: 18px;
      }
    }
  }

  .errorContent {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__image {
      width: 64px;
      height: 64px;
    }

    &__title {
      margin: 24px 0 0 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 34px;
      line-height: 40px;
      letter-spacing: 0.01em;
      color: #141414;
      text-align: center;
    }

    &__subTitle {
      margin: 16px 0 0 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #141414;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      margin: 24px 0 0 0;
      padding: 0 16px;
      background: #f95b3d;
      border-radius: 8px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      cursor: pointer;
    }
  }
}

.noBorder {
  border-bottom: none;
}
