.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(20, 20, 20, 0.8);
}

.popup {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  height: 280px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);

  &__image {
    max-width: 64px;
    max-height: 64px;
    margin: 40px 0 0 0;
  }

  &__title {
    margin: 16px 0 0 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  &__buttons {
    display: flex;
    margin: 40px 0 0 0;
  }

  &__backButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 40px;
    color: #45cb85;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    background: rgba(69, 203, 133, 0.15);
    border-radius: 8px;
  }

  &__buyButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 40px;
    margin: 0 0 0 24px;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    background: #45cb85;
    border-radius: 8px;
  }

  &__closeIcon {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
    cursor: pointer;

    &:before,
    &:after {
      position: absolute;
      left: 15px;
      width: 2px;
      height: 33px;
      background-color: #141414;
      content: " ";
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}

@media (max-width: 479px) {
  .wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(20, 20, 20, 0.8);
  }

  .popup {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 350px;
    background: #fff;
    border-radius: 0;
    box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);

    &__image {
      max-width: 64px;
      max-height: 64px;
      margin: 40px 0 0 0;
    }

    &__title {
      margin: 16px 0 0 0;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      margin: 40px 0 0 0;
    }

    &__backButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 40px;
      color: #45cb85;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-decoration: none;
      background: rgba(69, 203, 133, 0.15);
      border-radius: 8px;
    }

    &__buyButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 40px;
      margin: 24px 0 0 0;
      color: #fff;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-decoration: none;
      background: #45cb85;
      border-radius: 8px;
    }

    &__closeIcon {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 32px;
      height: 32px;
      cursor: pointer;

      &:before,
      &:after {
        position: absolute;
        left: 15px;
        width: 2px;
        height: 33px;
        background-color: #141414;
        content: " ";
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}
