.howItWorks {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 578px;
  background: #f95b3d;

  &__title {
    width: 536px;
    height: 48px;
    margin-top: 110px;
    color: #fff;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
  }

  &__list {
    display: flex;
    margin-top: 129px;
  }

  .step {
    position: relative;

    &__number {
      position: absolute;
      height: 48px;
      color: #fff;
      font-weight: normal;
      font-size: 100px;
      font-family: Krona One;
      line-height: 48px;
      text-align: center;
      opacity: 0.3;
    }

    &__title {
      width: 350px;
      color: #fff;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    }

    &__subtitle {
      width: 332px;
      margin-top: 32px;
      color: #fff;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
    }
  }

  .firstStep {
    .step__number {
      top: -19px;
      left: -33px;
      width: 59px;
    }
  }

  .secondStep {
    margin-left: 55px;

    .step__number {
      top: -19px;
      left: -41px;
      width: 84px;
    }
  }

  .thirdStep {
    margin-left: 73px;

    .step__title {
      width: 332px;
    }

    .step__number {
      top: -19px;
      left: -34px;
      width: 84px;
    }
  }
}

@media (max-width: 479px) {
  .howItWorks {
    height: 705px;

    &__title {
      width: 325px;
      height: 28px;
      margin-top: 56px;
      font-size: 24px;
      line-height: 28px;
    }

    &__list {
      flex-direction: column;
      align-items: center;
      margin-top: 75px;
    }

    .step {
      position: relative;
      width: 100%;
      max-width: 295px;

      &__number {
        font-size: 80px;
      }

      &__title {
        width: 295px;
        font-size: 18px;
        line-height: 32px;
      }

      &__subtitle {
        width: 295px;
        margin-top: 16px;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .firstStep {
      .step__number {
        top: -19px;
        left: -40px;
        width: 47px;
      }
    }

    .secondStep {
      margin: 75px 0 0 0;

      .step__number {
        top: -19px;
        left: -40px;
        width: 67px;
      }
    }

    .thirdStep {
      margin: 75px 0 0 0;

      .step__title {
        width: 295px;
      }

      .step__number {
        top: -19px;
        left: -40px;
        width: 67px;
      }
    }
  }
}
