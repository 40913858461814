.burnout {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  padding: 80px 0 120px 0;

  &__title {
    display: flex;
    align-items: center;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    letter-spacing: 0.01em;
    color: #2b89e2;
  }

  &__titleImage {
    margin: 0 12px 0 0;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    height: 48px;
    margin: 24px 0 0 0;
    padding: 0 32px;
    background: #45cb85;
    border-radius: 8px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    align-self: flex-start;
  }

  &__contentTitle {
    margin: 48px 0 0 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #2b89e2;
  }

  &__columns {
    display: flex;
    margin: 20px 0 0 0;
  }

  &__column {
    width: calc((100% - 40px) / 2);
  }

  &__text {
    max-width: 340px;
    margin: 0 0 24px 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #141414;

    &_blue {
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #2b89e2;
    }
  }

  &__image {
    width: 400px;
  }

  &__listTitle {
    margin: 0 0 0 20px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #2b89e2;
  }

  &__list {
    display: inline-block;
  }

  &__item {
    padding-left: 8px;
    list-style-type: decimal;
    max-width: 300px;
    margin: 0 0 24px 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #141414;
  }

  &__link {
    max-width: 300px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #2b89e2;
  }
}

@media (max-width: 479px) {
  .burnout {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1440px;
    padding: 80px 0 120px 0;

    &__title {
      display: flex;
      align-items: center;
      max-width: 343px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 0.01em;
      color: #2b89e2;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: max-content;
      height: 40px;
      margin: 16px 0 0 0;
      padding: 0 32px;
      background: #45cb85;
      border-radius: 8px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      text-decoration: none;
      cursor: pointer;
      align-self: center;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__titleImage {
      margin: 0 12px 0 0;
    }

    &__contentTitle {
      max-width: 343px;
      margin: 32px 0 0 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.01em;
      color: #2b89e2;
    }

    &__columns {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 20px 0 0 0;
    }

    &__column {
      width: 343px;
    }

    &__text {
      width: 343px;
      margin: 0 0 24px 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #141414;

      &_blue {
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #2b89e2;
      }
    }

    &__image {
      width: 343px;
    }

    &__listTitle {
      margin: 0 0 0 20px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #2b89e2;
    }

    &__list {
      display: inline-block;
      width: 343px;
    }

    &__item {
      padding-left: 8px;
      list-style-type: decimal;
      max-width: 300px;
      margin: 0 0 24px 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #141414;
    }

    &__link {
      max-width: 300px;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #2b89e2;
    }
  }
}
