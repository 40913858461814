.contacts {
  display: flex;
  flex-direction: column;
  width: 760px;
  min-height: 260px;
  margin: 24px 0 0 140px;
  padding: 0 0 32px 32px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);

  &_onProfilePage {
    margin: 32px 0 0 0;
  }

  &__name {
    margin: 32px 0 0 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }

  &__email {
    margin: 16px 0 0 0;
    color: #2b89e2;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
  }

  &__link {
    display: block;
    margin: 16px 0 0 0;
    color: #2b89e2;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
  }

  &__mobileNumber {
    margin: 16px 0 0 0;
    color: #2b89e2;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
  }

  &__address {
    width: 164px;
    margin: 16px 0 0 0;
    color: #8a8a8a;
    font-weight: 400;
    font-size: 16px;
    line-height: 36px;
  }
}

@media (max-width: 479px) {
  .contacts {
    width: 100%;
    min-height: 251px;
    margin: 16px 0 0 0;
    padding: 0 0 16px 16px;
    background: #fff;
    border-radius: 0;
    box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);

    &__name {
      margin: 32px 0 0 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }

    &__email {
      margin: 16px 0 0 0;
      color: #2b89e2;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    &__link {
      display: block;
      margin: 16px 0 0 0;
      color: #2b89e2;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-decoration-line: underline;
    }

    &__mobileNumber {
      margin: 16px 0 0 0;
      color: #2b89e2;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-decoration-line: underline;
    }

    &__address {
      width: 164px;
      margin: 16px 0 0 0;
      color: #8a8a8a;
      font-weight: 400;
      font-size: 16px;
      line-height: 36px;
    }
  }
}
