.wrapper {
  display: flex;
  justify-content: center;
  max-width: calc(100% - 64px);
}

@media (max-width: 479px) {
  .wrapper {
    max-width: 100%;
  }

}