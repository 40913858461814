.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 116rem;
    height: 59.2rem;
    margin-top: 2.4rem;
    margin-bottom: 10rem;
    font-weight: normal;
    font-size: 16px;
    font-family: Rubik;
    font-style: normal;
    line-height: 20px;
    background: #FFF;
    border-radius: 20px;
    box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
     
  }

.headerWrapper {
  display: flex;
  justify-content: flex-start;
  width: 109.8rem;
  height: 6.8rem;
  margin-top: 3.2rem;
  margin-left: 3.2rem;
}

.chartWrapper {
  display: flex;
  justify-content: center;
  width: 116rem;
  height: 40rem;
  margin-bottom: 3.2rem;
  margin-left: 3.2;
}

@media (max-width: 479px) {

  .wrapper {
    width: 100%;
    max-width: 37.5rem;
    height: 50.2rem;
    margin-top: 2.4rem;
    margin-bottom: 0;    
    border-radius: 0;
    box-shadow: none;
     
  }

  .headerWrapper {
      flex-direction: column;
      width: 34.3rem;
      height: 16rem;
      margin-top: 0;
      margin-left: 0;
  }

  .chartWrapper {
    width: 37.5rem;
    height: 26rem;
  }

}

  