.successOrder {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  height: 727px;
  background: #f9faff;

  &__image {
    width: 64px;
    height: 64px;
    margin: 132px 0 0 0;
  }

  &__title {
    margin: 32px 0 0 0;
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
  }

  &__subtitle {
    margin: 24px 0 0 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 296px;
    height: 56px;
    margin: 40px 0 0 0;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-decoration: none;
    background: #f95b3d;
    border-radius: 8px;
  }
}

.requiresConfirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 48px 0 0 0;

  &__title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.01em;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 296px;
    height: 56px;
    margin: 24px 0 0 0;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-decoration: none;
    background: #f95b3d;
    border-radius: 8px;
  }
}

@media (max-width: 479px) {
  .successOrder {
    height: max-content;
    padding: 0 0 60px 0;

    &__imageHolder {
      width: 343px;
    }

    &__image {
      width: 64px;
      height: 64px;
      margin: 100px 0 0 0;
    }

    &__title {
      width: 343px;
      margin: 24px 0 0 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }

    &__subtitle {
      max-width: 343px;
      margin: 16px 0 0 0;
    }

    &__link {
      width: 343px;
      margin: 32px 0 0 0;
    }
  }

  .requiresConfirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 48px 0 0 0;

    &__title {
      max-width: 343px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.01em;
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 343px;
      height: 56px;
      margin: 24px 0 0 0;
      color: #fff;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      text-decoration: none;
      background: #f95b3d;
      border-radius: 8px;
    }
  }
}
