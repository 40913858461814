.block {
  width: 100%;

  &__head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 52px;
    background: #e6e8f1;
  }

  &__headText {
    margin: 0 0 0 32px;
    color: #8a8a8a;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  &__deliveryContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 32px 32px 32px;
    width: 100%;
  }
}

.error {
  position: relative;
  display: flex;
  align-items: center;
  width: max-content;
  max-width: 343px;
  height: 44px;
  margin: 12px 0 0 0;
  padding: 0 16px;
  color: #f20035;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background: #fff2f5;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(88, 0, 19, 0.1);

  &::after {
    position: absolute;
    top: -20px;
    left: 47%;
    margin-left: -5px;
    border-color: transparent #fff2f5 transparent transparent;
    border-style: solid;
    border-width: 10px;
    transform: rotate(0.25turn);
    content: "";
  }
}

.usDeliveryContent {
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px 24px;

  &__smallInputs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: 479px) {
  .block {
    &__head {
      max-width: 100vw;
      height: 41px;
    }

    &__headText {
      margin: 0 0 0 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      opacity: 0.5;
    }

    &__deliveryContent {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      max-width: 100vw;
      padding: 0 16px 24px 16px;
    }
  }

  .usDeliveryContent {
    padding: 0 16px 24px 16px;
  }
}
