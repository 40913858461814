.num {
  display: flex;
  justify-content: center;
  width: 580px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid rgba(20, 20, 20, 0.1);
  font-family: "Rubik";
  font-size: 16px;
  padding-left: 16px;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: 479px) {
    width: 100%;
    margin-bottom: 15px;
  }
}

.dimension {
  position: absolute;
  color: #737373;
  right: 5%;
  top: 45%;
  font-family: "Rubik";

  @media (max-width: 479px) {
    top: 40%;
  }
}

.error {
  position: absolute;
  top: 3.3rem;
  left: 600px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: max-content;
  max-width: 32.5rem;
  max-height: 7.2rem;
  padding: 0.2rem 1.6rem;
  color: #f20035;
  font-weight: normal;
  font-size: 14px;
  font-family: Rubik;
  font-style: normal;
  line-height: 20px;
  text-align: start;
  background-color: #fff2f5;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgb(88 0 19 / 10%);

  &::after {
    position: absolute;
    top: 13px;
    left: -15px;
    margin-left: -5px;
    border-color: transparent #fff2f5 transparent transparent;
    border-style: solid;
    border-width: 10px;
    content: "";
  }

  &__text {
    height: 4rem;
    display: flex;
    align-items: center;
    width: fit-content;
  }
}
