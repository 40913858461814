.blockHidden {
  display: none;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 46rem;
}

.name {
  width: 21.5rem;
}

.email {
  margin-top: 2.4rem;
}

.password {
  margin-top: 2.4rem;
}

.mobileNumber {
  margin-top: 2.4rem;
}

.mediumInput {
  display: block;
  width: 324px;
  margin: 24px 0 0 0;
}

.bigInput {
  display: block;
  width: 580px;
  margin: 24px 0 0 0;
}

.fullWidthInput {
  width: 100%;
  margin: 16px 0 0 0;
}

.bigPassword {
  width: 696px;
  margin: 24px 0 0 0;
}

.text {
  color: #141414;
  font-weight: 500;
  font-size: 14px;
  font-family: Rubik;
  font-style: normal;
  line-height: 20px;
}

.subText {
  margin: 0 0 0 8px;
  color: #141414;
  font-weight: 400;
  font-size: 12px;
  font-family: Rubik;
  font-style: italic;
  line-height: 20px;
  opacity: 0.6;
}

.input {
  width: 100%;
  height: 4rem;
  margin: 0.8rem 0 auto 0;
  padding-left: 16px;
  color: #141414;
  font-weight: normal;
  font-size: 16px;
  font-family: Rubik;
  font-style: normal;
  line-height: 20px;
  background: #ffffff;
  border: 1px solid rgba(20, 20, 20, 0.1);
  border-radius: 10px;
  outline: none;
}

.inputError {
  border: 1px solid #f20035 !important;
}

.error {
  position: absolute;
  top: 2.3rem;
  left: 480px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: -moz-max-content;
  width: max-content;
  max-width: 32.5rem;
  max-height: 7.2rem;
  padding: 0.2rem 1.6rem;
  color: #f20035;
  font-weight: normal;
  font-size: 14px;
  font-family: Rubik;
  font-style: normal;
  line-height: 20px;
  text-align: start;
  background-color: #fff2f5;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(88, 0, 19, 0.1);

  &__name {
    left: 235px;
    max-width: 18rem;
  }
}

.verticalError {
  position: relative;
  top: 1.2rem;
  left: 0;
}

.error::after {
  position: absolute;
  top: 13px;
  left: -15px;
  margin-left: -5px;
  border-color: transparent #fff2f5 transparent transparent;
  border-style: solid;
  border-width: 10px;
  content: "";
}

.verticalError::after {
  position: absolute;
  top: -20px;
  left: 47%;
  margin-left: -5px;
  border-color: transparent #fff2f5 transparent transparent;
  border-style: solid;
  border-width: 10px;
  transform: rotate(0.25turn);
  content: "";
}

.errorText {
  display: flex;
  align-items: center;
  width: fit-content;
  width: -moz-fit-content;
  height: 4rem;
}

@media (max-width: 479px) {
  .wrapper {
    width: 34.3rem;
    margin-top: 2.4rem;
  }

  .error {
    position: relative;
    top: 0%;
    left: 0%;
    width: 34.3rem;
    max-width: 34.3rem;
    height: max-content;
    height: -moz-max-content;
    margin-top: 16px;
    padding: 0.8rem 1.6rem 0.8rem 1.6rem;

    &__name {
      max-width: 34.3rem;
    }
  }

  .verticalError {
    position: relative;
    top: 0%;
    left: 0%;
  }

  .error::after {
    top: -16px;
    left: 47%;
    margin-left: -5px;
    border-color: transparent transparent #fff2f5 transparent;
    border-style: solid;
    border-width: 8px;
    content: "";
  }

  .verticalError::after {
    position: absolute;
    top: -20px;
    left: 166px;
    margin-left: -5px;
    border-color: transparent #fff2f5 transparent transparent;
    border-style: solid;
    border-width: 10px;
    transform: rotate(0.25turn);
    content: "";
  }

  .errorText {
    display: flex;
    align-items: center;
    width: auto;
    height: -moz-fit-content;
    height: fit-content;
  }
}
