.faq {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  min-height: 860px;
  overflow: hidden;

  &__background {
    position: absolute;
    bottom: 0px;
    z-index: -1;
    width: 100%;
    height: 500px;
    background-color: #fff0ed;
    border-top-left-radius: 1000px;
    border-top-right-radius: 1000px;
    opacity: 0.85;
    filter: blur(30px);
  }

  &__title {
    width: 444px;
    height: 48px;
    margin: 80px 0 0 0;
    color: #141414;
    font-weight: 500;
    font-size: 32px;
    font-family: Rubik;
    line-height: 48px;
    text-align: center;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 900px;
    margin: 51px 0 164px 0;
    padding: 24px 0 24px 0;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
  }
}

@media (max-width: 479px) {
  .faq {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1440px;
    min-height: 860px;
    overflow: hidden;

    &__background {
      position: absolute;
      bottom: 0px;
      z-index: -1;
      width: 100%;
      height: 500px;
      background-color: #fff0ed;
      border-top-left-radius: 1000px;
      border-top-right-radius: 1000px;
      opacity: 0.85;
      filter: blur(30px);
    }

    &__title {
      width: 278px;
      height: 28px;
      margin: 40px 0 0 0;
      font-size: 20px;
      line-height: 28px;
    }

    &__list {
      width: 100%;
      margin: 24px 0 56px 0;
      padding: 0;
      border-radius: 0;
      box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
    }
  }
}
