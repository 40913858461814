.block {
  &__head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 52px;
    background: #e6e8f1;
  }

  &__headText {
    margin: 0 0 0 32px;
    color: #8a8a8a;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  &__detailsContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 32px 32px 32px;
  }

  &__inputWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 324px;
    margin-top: 2.4rem;
  }

  &__inputText {
    color: #141414;
    font-weight: 500;
    font-size: 14px;
    font-family: Rubik;
    font-style: normal;
    line-height: 20px;
  }

  &__birthDateError {
    position: relative;
    top: 10px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: max-content;
    max-width: 343px;
    height: 4.2rem;
    padding: 10px;
    color: #f20035;
    font-weight: normal;
    font-size: 14px;
    font-family: Rubik;
    font-style: normal;
    line-height: 20px;
    text-align: start;
    background-color: #fff2f5;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(88, 0, 19, 0.1);

    &::after {
      position: absolute;
      top: -20px;
      left: 47%;
      margin-left: -5px;
      border-color: transparent #fff2f5 transparent transparent;
      border-style: solid;
      border-width: 10px;
      transform: rotate(0.25turn);
      content: "";
    }
  }

  &__sexWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 46rem;
    height: 5.2rem;
    margin-top: 2.4rem;
  }

  &__sexRadioWrapper {
    display: flex;
    justify-content: flex-start;
    margin: 12px 0 0 0;
  }

  &__sexRadio {
    display: none;

    &:checked + .block__sexLabel::before {
      background-color: #45cb85;
      border-color: #45cb85;
    }

    &:checked + .block__sexLabel::after {
      transform: translateY(-50%) scale(0.55);
    }
  }

  &__sexLabel {
    position: relative;
    margin-right: 4rem;
    margin-left: 3rem;
    color: #141414;
    font-weight: normal;
    font-size: 16px;
    font-family: Rubik;
    font-style: normal;
    line-height: 20px;
    cursor: pointer;

    &::before {
      position: absolute;
      top: 50%;
      left: -3rem;
      width: 2rem;
      height: 2rem;
      background-color: transparent;
      border: 2px solid #e8e8e8;
      border-radius: 50%;
      transform: translateY(-50%);
      transition: border-color 400ms ease;
      content: "";
    }

    &::after {
      position: absolute;
      top: 50%;
      left: -3rem;
      width: 2rem;
      height: 2rem;
      background-color: #f9faff;
      border: 2px solid #45cb85;
      border-radius: 50%;
      transform: translateY(-50%) scale(0);
      transition: transform 400ms ease;
      content: "";
    }
  }

  &__error {
    position: absolute;
    top: 2.5rem;
    left: 480px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: max-content;
    max-width: 343px;
    height: 4.2rem;
    padding: 10px;
    color: #f20035;
    font-weight: normal;
    font-size: 14px;
    font-family: Rubik;
    font-style: normal;
    line-height: 20px;
    text-align: start;
    background-color: #fff2f5;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(88, 0, 19, 0.1);

    &__sex {
      top: 1.8rem;
      left: 46%;
    }

    &::after {
      position: absolute;
      top: 13px;
      left: -15px;
      margin-left: -5px;
      border-color: transparent #fff2f5 transparent transparent;
      border-style: solid;
      border-width: 10px;
      content: "";
    }
  }
}

.flexWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.birthDate {
  display: flex;
  align-items: center;
  width: 324px;
  height: 40px;
  margin: 8px 0 0 0;
  background: #fff;
  border: 1px solid rgba(20, 20, 20, 0.1);
  border-radius: 10px;
  overflow: hidden;

  &_hasError {
    border: 1px solid #f20035;
  }

  &__holder {
    position: relative;
  }

  &__input {
    text-align: center;
    width: 33.333%;
    height: 38px;
    border: none;
    border-right: 1px solid rgba(20, 20, 20, 0.1);
    outline: none;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #141414;

    &:last-of-type {
      border: none;
    }

    &::placeholder {
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__image {
    width: 18px;
    height: 16px;
    margin: 0 24px 0 auto;
    cursor: pointer;
  }
}

.hidden {
  display: none;
}

@media (max-width: 479px) {
  .block {
    &__head {
      max-width: 100vw;
      height: 41px;
    }

    &__headText {
      margin: 0 0 0 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      opacity: 0.5;
    }

    &__detailsContent {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      max-width: 100vw;
      padding: 0 16px 24px 16px;
    }

    &__inputWrapper {
      width: 343px;
    }

    &__birthDateError {
      position: relative;
      top: 10px;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: max-content;
      width: 343px;
      height: 4.2rem;
      padding: 10px;
      color: #f20035;
      font-weight: normal;
      font-size: 14px;
      font-family: Rubik;
      font-style: normal;
      line-height: 20px;
      text-align: start;
      background-color: #fff2f5;
      border-radius: 10px;
      box-shadow: 0px 4px 10px rgba(88, 0, 19, 0.1);

      &::after {
        position: absolute;
        top: -20px;
        left: 47%;
        margin-left: -5px;
        border-color: transparent #fff2f5 transparent transparent;
        border-style: solid;
        border-width: 10px;
        transform: rotate(0.25turn);
        content: "";
      }
    }

    &__sexWrapper {
      width: 34.3rem;
      height: 100%;
    }

    &__error {
      position: relative;
      display: flex;
      align-items: flex-start;
      top: 0%;
      left: 0%;
      width: 34.3rem;
      height: 3.7rem;
      margin-top: 16px;

      &::after {
        top: -16px;
        left: 47%;
        margin-left: -5px;
        border-color: transparent transparent #fff2f5 transparent;
        border-style: solid;
        border-width: 8px;
        content: "";
      }
    }
  }

  .flexWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .birthDate {
    display: flex;
    align-items: center;
    width: 324px;
    height: 40px;
    background: #fff;
    border: 1px solid rgba(20, 20, 20, 0.1);
    border-radius: 10px;
    overflow: hidden;

    &_hasError {
      border: 1px solid #f20035;
    }

    &__holder {
      position: relative;
    }

    &__input {
      text-align: center;
      width: 33.333%;
      height: 38px;
      border: none;
      border-right: 1px solid rgba(20, 20, 20, 0.1);
      outline: none;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #141414;

      &:last-of-type {
        border: none;
      }

      &::placeholder {
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
      }
    }

    &__image {
      width: 18px;
      height: 16px;
      margin: 0 24px 0 auto;
      cursor: pointer;
    }
  }
}
