.wrapper {
  width: 100%;
  max-width: 1440px;
  height: 727px;
}

@media (max-width: 479px) {
  .wrapper {
    width: 100vw;
    max-width: 100vw;
    height: 545px;
  }
}
