.uploadPopup {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  font-family: Rubik;
  font-style: normal;
  color: #141414;

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(20, 20, 20, 0.8);
  }

  &__block {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 820px;
    height: 515px;
    background: #fff;
    box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
    border-radius: 20px;
    z-index: 1;
  }

  &__head {
    display: flex;
    width: 100%;
    height: 95px;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin: 24px 0 0 0;

    &_main {
      margin: 30px 0 0 32px;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__closeIcon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23px;
    height: 23px;
    margin: 24px 24px 0 auto;
    cursor: pointer;

    &:before,
    &:after {
      position: absolute;
      top: 0;
      width: 3px;
      height: 23px;
      background-color: #868686;
      content: "";
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  &__loadingBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 72px 0 0 0;
  }

  &__passwordBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 390px;

    &__title {
      margin: 32px 16px;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      color: #141414;
    }

    &__inputContainer {
      height: 50px;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      width: 235px;
      margin: 48px 0 0 0;
      padding: 0 16px;
      background: #45cb85;
      border-radius: 8px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      cursor: pointer;
    }
  }

  .RCP {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__loadingProgress {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    top: 54px;
    left: 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  &__loadingText {
    margin: 24px 0 0 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  &__successBlock,
  &__errorBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 90px 0 0 0;
  }

  &__successImage,
  &__errorImage {
    width: 64px;
    height: 64px;
  }

  &__successTitle,
  &__errorTitle {
    margin: 42px 0 0 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  &__successText {
    margin: 24px 0 0 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  &__errorText {
    width: 480px;
    margin: 8px 0 0 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  &__dropBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 740px;
    height: 340px;
    margin: 40px 0 0 0;
    box-sizing: border-box;
    border-radius: 16px;
    background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: %23e8e8e8; stroke-width: 5; stroke-dasharray: 7 7'/></svg>");
  }

  &__image {
    width: 64px;
    height: 68px;
    margin: 40px 0 0 0;
  }

  &__subTitle {
    margin: 8px 0 0 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 169px;
    height: 40px;
    margin: 32px 0 0 0;
    background: #45cb85;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    cursor: pointer;
  }

  &__hint {
    margin: 16px 0 0 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #8a8a8a;
  }

  &__input {
    display: none;
    overflow: hidden;
    visibility: hidden;
  }
}

@media (max-width: 479px) {
  .uploadPopup {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    &__background {
      display: none;
    }

    &__block {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 0;
      width: 100vw;
      height: 100vh;
      background: #fff;
      box-shadow: none;
      border-radius: 0;
      z-index: 1;
    }

    &__head {
      display: flex;
      width: 100%;
      height: 65px;
      border-bottom: none;
    }

    &__title {
      width: 200px;
      margin: 40px 0 0 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      text-align: center;

      &_main {
        margin: 32.5px 0 0 16px;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
      }
    }

    &__closeIcon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 23px;
      height: 23px;
      margin: 27.5px 16px 0 auto;
      cursor: pointer;

      &:before,
      &:after {
        position: absolute;
        top: 0;
        width: 3px;
        height: 23px;
        background-color: #868686;
        content: "";
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }

    &__loadingBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 82px 0 0 0;
    }

    &__passwordBlock {
      height: 390px;

      &__title {
        margin: 32px 16px 16px 16px;
      }

      &__inputContainer {
        height: 90px;
      }

      &__button {
        width: 275px;
        font-size: 18px;
      }
    }

    .RCP {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__loadingProgress {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 140px;
      top: 54px;
      left: 0;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    }

    &__loadingText {
      margin: 24px 0 0 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
    }

    &__successBlock,
    &__errorBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 100px 0 0 0;
    }

    &__successImage,
    &__errorImage {
      width: 64px;
      height: 64px;
    }

    &__successTitle,
    &__errorTitle {
      margin: 42px 0 0 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
    }

    &__successText {
      max-width: 343px;
      margin: 24px 0 0 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
    }

    &__errorText {
      width: 343px;
      margin: 16px 0 0 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }

    &__dropBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 343px;
      height: 430px;
      margin: 24px 0 0 0;
      border-radius: 16px;
      background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: %23e8e8e8; stroke-width: 5; stroke-dasharray: 7 7'/></svg>");
    }

    &__image {
      width: 48px;
      height: 51px;
      margin: 40px 0 0 0;
    }

    &__subTitle {
      width: 280px;
      margin: 8px 0 0 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 169px;
      height: 40px;
      margin: 32px 0 0 0;
      background: #45cb85;
      border-radius: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      cursor: pointer;
    }

    &__hint {
      margin: 16px 0 0 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #8a8a8a;
    }
  }
}
