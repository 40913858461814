.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 144rem;
}

.cookieConsentContainer {
    align-items: center !important;
    justify-content: center !important;
    font-weight: 500; 
    font-size: 16px;
    font-family: Rubik;
    background: #270949 !important; 
}

.cookieConsentButton{
  width: 256px;
  height: 36px;
  color: #FFF !important;
  font-weight: 500;
  font-size: 18px;
  background: #F95B3D !important;
  border-radius: 8px !important; 
}

@media (max-width: 479px) {

  .cookieConsentContainer {
  font-size: 12px;
  line-height: 14px;
  }

  .cookieConsentButton {
    width: 216px;
    height: 36px;
    font-size: 14px;
  }
}
