.stage {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 50px;

  @media (max-width: 479px) {
    gap: 20px;
  }

  &__circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    border-radius: 50%;
  }

  &__firstCircle {
    &_firstStage {
      background-color: #4654a3;
    }

    &_secondStage {
      background-color: #4654a3;
    }

    &_thirdStage {
      background-color: #4654a3;
    }
  }

  &__secondCircle {
    &_firstStage {
      background-color: #e6e8f1;
    }

    &_secondStage {
      background-color: #4654a3;
    }

    &_thirdStage {
      background-color: #4654a3;
    }
  }

  &__thirdCircle {
    &_firstStage {
      background-color: #e6e8f1;
    }

    &_secondStage {
      background-color: #e6e8f1;
    }

    &_thirdStage {
      background-color: #4654a3;
    }
  }

  &__number {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
  }

  &__firstNumber {
    &_firstStage {
      color: #ffffff;
    }

    &_secondStage {
      color: #ffffff;
    }

    &_thirdStage {
      color: #ffffff;
    }
  }

  &__secondNumber {
    &_firstStage {
      color: rgba(20, 20, 20, 0.5);
    }

    &_secondStage {
      color: #ffffff;
    }

    &_thirdStage {
      color: #ffffff;
    }
  }

  &__thirdNumber {
    &_firstStage {
      color: rgba(20, 20, 20, 0.5);
    }

    &_secondStage {
      color: rgba(20, 20, 20, 0.5);
    }

    &_thirdStage {
      color: #ffffff;
    }
  }

  &__dash {
    width: 100px;
    border-radius: 40px;
    height: 6px;
    position: relative;

    @media (max-width: 479px) {
      width: 75px;
    }
  }

  &__firstDash {
    &_firstStage {
      background-color: #e6e8f1;
      position: relative;

      &:before {
        width: 50%;
        height: 100%;
        background-color: #4654a3;
        border-radius: 40px;
        position: absolute;
        content: "";
        left: 0;
      }
    }

    &_secondStage {
      background-color: #4654a3;
    }

    &_thirdStage {
      background-color: #4654a3;
    }
  }

  &__secondDash {
    &_firstStage {
      background-color: #e6e8f1;
    }

    &_secondStage {
      background-color: #e6e8f1;
      position: relative;

      &_thirdStage {
        background-color: #4654a3;
      }

      &:before {
        width: 50%;
        height: 100%;
        background-color: #4654a3;
        border-radius: 40px;
        position: absolute;
        content: "";
        left: 0;
      }
    }

    &_thirdStage {
      background-color: #4654a3;
    }
  }
}
