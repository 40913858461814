.surveys {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  min-height: 536px;

  &__title {
    margin: 64px 0 0 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    letter-spacing: 0.01em;
    color: #141414;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 760px;
    min-height: 76px;
    margin: 24px 0 0 0;
    overflow: hidden;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
  }
}

@media (max-width: 479px) {
  .surveys {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1440px;
    min-height: 240px;
    margin: 64px 0;

    &__title {
      margin: 0 0 0 16px;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% - 16px);
      min-height: 105px;
      margin: 16px 0 0 0;
      overflow: hidden;
      background: #fff;
      border-radius: 0;
      box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
    }
  }
}
