.cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  min-height: 727px;
  color: #141414;
  font-family: Rubik;
  background: #f9faff;

  &__title {
    align-self: flex-start;
    margin: 56px 0 0 140px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  &__content {
    display: flex;
    width: 1160px;
    margin: 32px 0 80px 0;
  }

  &__list {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 760px;
    height: 100%;
    min-height: 149px;
    overflow: hidden;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
  }

  &__emptyText {
    margin: 40px 0 20px 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  &__testsLink {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 296px;
    height: 56px;
    margin: 0 0 40px 0;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-decoration: none;
    background: #f95b3d;
    border-radius: 8px;
  }

  &__details {
    width: 360px;
    height: max-content;
    max-height: 460px;
    margin: 0 0 0 40px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
  }

  &__priceBlock {
    display: flex;
    align-items: center;
    width: 100%;
    height: 89px;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);
  }

  &__priceTitle {
    margin: 0 0 0 32px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    opacity: 0.5;
  }

  &__prices {
    display: flex;
    align-items: center;
    margin: 0 32px 0 auto;
  }

  &__priceBeforeDiscount {
    color: #eb3f26;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: line-through;

    &_euro {
      font-weight: 400;
    }
  }

  &__price {
    margin: 0 0 0 8px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;

    &_euro {
      font-weight: 400;
    }
  }

  &__deliveryBlock {
    display: flex;
    align-items: center;
    width: 100%;
    height: 68px;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);
  }

  &__deliveryTitle {
    margin: 0 0 0 32px;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    opacity: 0.5;
  }

  &__deliveryPrice {
    margin: 0 32px 0 auto;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  &__promocodeBlock {
    width: 100%;
    padding: 24px 32px;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);
  }

  &__promocodeTitle {
    width: 152px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  &__promocodeHolder {
    display: flex;
    width: 296px;
    height: 40px;
    margin: 8px 0 0 0;
    background: #fff;
    border-radius: 10px;
  }

  &__promocodeInput {
    width: 181px;
    height: 40px;
    padding-left: 16px;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    border: 1px solid rgba(20, 20, 20, 0.1);
    border-right: none;
    border-radius: 10px 0 0 10px;

    &:focus {
      outline: none;
    }
  }

  &__promocodeButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 115px;
    height: 40px;
    color: #45cb85;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    background: #e3f7ed;
    border-radius: 0 8px 8px 0;
    cursor: pointer;

    &_disabled {
      color: #141414;
      background: #eaeaea;
      opacity: 0.3;
    }
  }

  &__promocodeError {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 296px;
    margin: 10px 0 0 0;
    padding: 8px;
    text-align: center;
    color: #f20035;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    background: #fff2f5;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(88, 0, 19, 0.1);

    &::after {
      position: absolute;
      top: -16px;
      left: 134px;
      margin-left: 5px;
      border-color: transparent transparent #fff2f5 transparent;
      border-style: solid;
      border-width: 8px;
      content: "";
    }
  }

  &__promocodeApplied {
    display: flex;
    width: 296px;
    height: 80px;
    background: rgba(69, 203, 133, 0.1);
    border-radius: 10px;

    &__image {
      width: 24px;
      height: 24px;
      margin: 18px 0 0 16px;
    }

    &__text {
      margin: 16px 0 0 16px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    &__boldText {
      margin: 0 0 0 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    &__orangeText {
      color: #eb3f26;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__buyBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
  }

  &__continueButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 296px;
    height: 56px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-decoration: none;
    background: #f95b3d;
    border-radius: 8px;
    cursor: pointer;

    &_disabled {
      background: #c0c0c0;
    }
  }
}

@media (max-width: 479px) {
  .cart {
    padding-bottom: 80px;

    &__title {
      align-self: flex-start;
      margin: 32px 0 0 16px;
      font-size: 20px;
      line-height: 24px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 24px 0 0 0;
    }

    &__list {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 375px;
      height: 100%;
      min-height: 213px;
      background: #fff;
      border-radius: 20px;
      box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
    }

    &__details {
      width: 375px;
      margin: 16px 0 0 0;
      background: #ffffff;
      border-radius: 20px;
      box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
    }

    &__priceBlock {
      height: 73px;
    }

    &__priceTitle {
      margin: 0 0 0 16px;
    }

    &__prices {
      margin: 0 16px 0 auto;
    }

    &__deliveryBlock {
      height: 52px;
    }

    &__deliveryTitle {
      margin: 0 0 0 16px;
    }

    &__deliveryPrice {
      margin: 0 16px 0 auto;
    }

    &__promocodeBlock {
      padding: 24px 16px;
    }

    &__promocodeHolder {
      display: flex;
      width: 343px;
      height: 40px;
      margin: 8px 0 0 0;
      background: #fff;
      border-radius: 10px;
    }

    &__promocodeInput {
      width: 228px;
      height: 40px;
      padding-left: 16px;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      border: 1px solid rgba(20, 20, 20, 0.1);
      border-right: none;
      border-radius: 10px 0 0 10px;

      &:focus {
        outline: none;
      }
    }

    &__promocodeError {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 343px;
      margin: 10px 0 0 0;
      padding: 8px;
      color: #f20035;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      background: #fff2f5;
      border-radius: 10px;
      box-shadow: 0px 4px 10px rgba(88, 0, 19, 0.1);

      &::after {
        position: absolute;
        top: -16px;
        left: 158px;
        margin-left: 5px;
        border-color: transparent transparent #fff2f5 transparent;
        border-style: solid;
        border-width: 8px;
        content: "";
      }
    }

    &__promocodeApplied {
      display: flex;
      width: 343px;
      height: 80px;
      background: rgba(69, 203, 133, 0.1);
      border-radius: 10px;
    }

    &__buyBlock {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 105px;
    }

    &__continueButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 343px;
      height: 56px;
      color: #fff;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      text-decoration: none;
      background: #f95b3d;
      border-radius: 8px;
      cursor: pointer;

      &_disabled {
        background: #c0c0c0;
      }
    }
  }
}
