.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  padding: 80px 0 120px 0;
}

.title {
  display: flex;
  align-items: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  letter-spacing: 0.01em;
  color: #2b89e2;
}

.titleImage {
  margin: 0 12px 0 0;
  height: 100px;
  width: 100px;
}

.blockContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  margin: 20px 0 0 0;
}

.column {
  width: calc((100% - 40px) / 2);
}

.text {
  max-width: 406px;
  margin: 0 0 24px 0;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #141414;

  &_blue {
    color: #2b89e2;
  }
}

.image {
  width: 400px;
}

.blockImage {
  width: 554px;
  height: 336px;
}

@media (max-width: 479px) {
  
  .title {
    max-width: 343px;
    font-size: 24px;
    line-height: 28px;
  }

  .blockContainer {
    flex-direction: column;
    align-items: center;    
  }

  .column {
    width: 343px;
  }

  .image {
    width: 343px;
  }

  .blockImage {
    width: 343px;
    height: 208px;
  }
    
}