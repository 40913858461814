.contacts {
  margin: 64px 0 40px 0;

  &__title {
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
  }

  &__content {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 479px) {
  .contacts {
    margin: 32px 0 40px 0;

    &__title {
      margin: 0 0 0 16px;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
    }
  }
}
