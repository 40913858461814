.wrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.fakeHeader {
  display: none;
}

.background {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  background: rgba(20, 20, 20, 0.8);
}

.popup {
  width: 640px;
  height: max-content;
  height: 727px;
  margin-top: calc((100vh - 727px) / 2);
  background: #fff;
  box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
  border-radius: 20px;
  z-index: 2;

  &__head {
    display: flex;
    width: 100%;
    height: 95px;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);
  }

  &__title {
    margin: 30px 0 0 32px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #141414;
  }
}

.closeIcon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 23px;
  margin: 24px 24px 0 auto;
  cursor: pointer;

  &:before,
  &:after {
    position: absolute;
    top: 0;
    width: 3px;
    height: 23px;
    background-color: #868686;
    content: "";
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: max-content;
  padding: 32px 0 40px 0;

  &__question {
    margin: 24px 0 0 0;

    &:first-of-type {
      margin: 0;
    }
  }

  &__questionHead {
    display: flex;
  }

  &__title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #141414;
  }

  &__error {
    margin: 0 0 0 18px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #f20035;
  }

  &__input {
    width: 460px;
    height: 40px;
    margin: 8px 0 0 0;
    padding: 10px 16px;
    background: #ffffff;
    border: 1px solid rgba(20, 20, 20, 0.1);
    border-radius: 10px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #141414;

    &:focus {
      outline: none;
    }

    &_hasError {
      border: 1px solid #f20035;
    }
  }

  &__textarea {
    width: 460px;
    min-height: 80px;
    margin: 8px 0 0 0;
    padding: 10px 16px;
    background: #ffffff;
    border: 1px solid rgba(20, 20, 20, 0.1);
    border-radius: 10px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #141414;
    resize: none;

    &:focus {
      outline: none;
    }

    &_hasError {
      border: 1px solid #f20035;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 169px;
    height: 56px;
    margin: 24px 0 0 0;
    background: #f95b3d;
    border: none;
    border-radius: 8px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
  }
}

@media (max-width: 479px) {
  .wrapper {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    top: 0;
    left: 0;
  }

  .fakeHeader {
    display: flex;
    width: 100vw;
    height: 75px;
    min-height: 75px;
    background: #fff;
    z-index: 2;

    &__image {
      margin: 16.5px 0 0 16px;
      width: 72px;
      height: 42px;
    }
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
  }

  .popup {
    width: 100vw;
    height: 100vh;
    margin-top: 0;
    background: #fff;
    box-shadow: none;
    border-radius: 0;
    z-index: 2;

    &__head {
      display: flex;
      justify-content: center;
      width: 100%;
      height: max-content;
      border-bottom: none;
    }

    &__title {
      margin: 9px 0 0 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 0.01em;
      color: #141414;
    }
  }

  .closeIcon {
    display: none;

    &_inHeader {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 23px;
      margin: 28px 16px 0 auto;
      cursor: pointer;

      &:before,
      &:after {
        position: absolute;
        top: 0;
        width: 3px;
        height: 23px;
        background-color: #868686;
        content: "";
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;
    padding: 24px 0 32px 0;

    &__question {
      margin: 18px 0 0 0;

      &:first-of-type {
        margin: 0;
      }
    }

    &__title {
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #141414;
    }

    &__input {
      width: 343px;
      height: 40px;
      margin: 8px 0 0 0;
      padding: 10px 16px;
      background: #fff;
      border: 1px solid rgba(20, 20, 20, 0.1);
      border-radius: 10px;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #141414;

      &:focus {
        outline: none;
      }
    }

    &__textarea {
      width: 343px;
      min-height: 99px;
      margin: 8px 0 0 0;
      padding: 10px 16px;
      background: #ffffff;
      border: 1px solid rgba(20, 20, 20, 0.1);
      border-radius: 10px;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #141414;
      resize: none;

      &:focus {
        outline: none;
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 343px;
      height: 56px;
      margin: 32px 0 0 0;
      background: #f95b3d;
      border: none;
      border-radius: 8px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #fff;
    }
  }
}
