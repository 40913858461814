.choice {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 535px;
  width: 100%;
  max-width: 1440px;
  color: #141414;
  font-family: Rubik;

  &__title {
    max-width: 800px;
    margin: 100px 0 0 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.01em;
    text-align: center;
  }

  &__buttons {
    display: flex;
    margin: 48px 0 0 0;
  }

  &__registration {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 144px;
    height: 40px;
    background: rgba(69, 203, 133, 0.15);
    border-radius: 8px;
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #45cb85;
  }

  &__login {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 144px;
    height: 40px;
    margin: 0 0 0 40px;
    background: #45cb85;
    border-radius: 8px;
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
  }
}

@media (max-width: 479px) {
}
