.numeric {
  display: flex;
  flex-direction: row;

  &__minValue {
    width: 90px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #141414;
    align-self: flex-end;
  }

  &__options {
    display: flex;
    justify-content: space-between;
    width: 398px;
  }

  &__label {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20px;
  }

  &__number {
    margin: 0 0 6px 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #141414;
  }

  &__maxValue {
    margin: 0 0 0 27px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #141414;
    align-self: flex-end;
  }

  &__valuesMobile {
    display: none;
  }
}

@media (max-width: 479px) {
  .numeric {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__minValue {
      display: none;
    }

    &__options {
      display: flex;
      justify-content: space-between;
      width: 344px;
    }

    &__label {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 20px;
    }

    &__number {
      margin: 0 0 6px 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #141414;
    }

    &__maxValue {
      display: none;
    }

    &__valuesMobile {
      display: flex;
      width: 100%;
      margin: 16px 0 0 0;
    }

    &__minValueMobile {
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #141414;
    }

    &__maxValueMobile {
      margin: 0 0 0 auto;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #141414;
    }
  }
}
