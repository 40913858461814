.cartItem {
  display: flex;
  width: 696px;
  height: 168px;
  border-bottom: 1px solid rgba(20, 20, 20, 0.1);

  &:last-of-type {
    border-bottom: 1px solid #fff;
  }

  &__content {
    display: flex;
  }

  &__imageHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    margin: 32px 0 0 32px;
    background: #fafafa;
  }

  &__image {
    width: 78px;
    height: 78px;
  }

  &__textContent {
    height: 84px;
    margin: 32px 0 0 16px;
  }

  &__name {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  &__unavailable {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #f20035;
  }

  &__prices {
    display: flex;
    align-items: center;
    margin: 8px 0 0 0;
  }

  &__price {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  &__priceBeforeDiscount {
    margin: 0 0 0 8px;
    color: #eb3f26;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: line-through;
  }

  .euro {
    font-weight: 400;
  }

  &__subscription {
    display: block;
    margin: 16px 0 0 0;
    color: #2b89e2;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  &__selectedSubscription {
    margin: 16px 0 0 0;
    color: #45cb85;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    &_bold {
      font-weight: 500;
    }
  }

  &__countBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 135px;
    height: 40px;
    margin: 52px 0 0 auto;
    padding: 0 8px;
    background: #fff;
    border: 1px solid rgba(20, 20, 20, 0.1);
    border-radius: 10px;

    &_small {
      display: flex;
      align-items: center;
      width: 42px;
      height: 40px;
      margin: 52px 0 0 auto;
      padding: 0 8px;
      border: 1px solid rgba(20, 20, 20, 0.1);
      border-radius: 10px;
    }
  }

  &__count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: #270949;
    border-radius: 50%;
    cursor: pointer;

    &_disabled {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      background: grey;
      border-radius: 50%;
      cursor: default;
    }
  }

  &__trashBin {
    width: 10px;
    height: 12px;
  }

  &__minus {
    width: 6px;
    height: 3px;
    background: #fff;
  }

  &__plus {
    position: relative;
    width: 32px;
    height: 32px;

    &:before,
    &:after {
      position: absolute;
      top: 11px;
      left: 11px;
      width: 2px;
      height: 9px;
      background: #fff;
      border-radius: 1px;
      content: "";
    }

    &:after {
      transform: rotate(-90deg);
    }
  }
}

@media (max-width: 479px) {
  .cartItem {
    display: flex;
    flex-direction: column;
    width: 375px;
    height: 100%;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);

    &:last-of-type {
      border-bottom: 1px solid #fff;
    }

    &__imageHolder {
      margin: 32px 0 0 16px;
    }

    &__textContent {
      height: 100%;
      min-height: 84px;
      margin: 32px 0 0 16px;
    }

    &__name {
      width: 247px;
    }

    &__selectedSubscription {
      width: 247px;
    }

    &__countBlock {
      align-self: center;
      width: 343px;
      margin: 24px 0 32px 0;
      padding: 0 16px;
      background: #fff;
      border: 1px solid rgba(20, 20, 20, 0.1);
      border-radius: 10px;

      &_small {
        align-self: center;
        width: 58px;
        margin: 24px 0 32px 0;
        padding: 0 16px;
        background: #fff;
        border: 1px solid rgba(20, 20, 20, 0.1);
        border-radius: 10px;
      }
    }
  }
}
