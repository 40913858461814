.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  // to display components correct
  min-width: 1440px;
  margin-top: 84px;
  color: #141414;
  font-family: Rubik;
}

/* Large desktops and laptops */
// @media (min-width: 1200px) {
// }

// /* Portrait tablets and medium desktops */
// @media (min-width: 992px) and (max-width: 1199px) {
// }

// /* Portrait tablets and small desktops */
// @media (min-width: 768px) and (max-width: 991px) {
// }

// /* Landscape phones and portrait tablets */
// @media (min-width: 480px) and (max-width: 767px) {
// }

@media (max-width: 479px) {
  .content {
    min-width: 0;
    max-width: 100%;
    margin-top: 25px;
    overflow: hidden;
  }
}
