.results {
  display: flex;
  height: 719px;

  &__result {
    position: relative;
    width: 332px;
    height: 547px;
    margin: 26px 0 0 82px;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);

    &:first-child {
      margin-left: 0;
    }

    &_big {
      height: 593px;
    }
  }

  &__title {
    display: flex;
    justify-content: center;
    width: 332px;
    height: 82px;
    padding-top: 12px;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    background: #ca133b;
    border-radius: 20px;
  }

  &__image {
    position: absolute;
    top: 43px;
  }
}

@media (max-width: 479px) {
  .results {
    display: flex;
    height: 577px;

    &__result {
      position: relative;
      width: 264px;
      height: 435px;
      margin: 26px 0 0 82px;
      overflow: hidden;
      border-radius: 20px;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);

      &:first-child {
        margin-left: 0;
      }

      &_big {
        height: 473px;
      }
    }

    &__title {
      display: flex;
      justify-content: center;
      width: 264px;
      height: 65px;
      padding-top: 9.5px;
      font-size: 13px;
      line-height: 15px;
      border-radius: 16px;
    }

    &__image {
      position: absolute;
      top: 34px;
      width: inherit;
      height: inherit;
    }
  }
}
