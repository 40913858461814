.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 144rem;
  padding-bottom: 10rem;
  background: #f9faff;
}

.header {
  margin-top: 6rem;
  margin-bottom: 4rem;
  color: #141414;
  font-weight: 700;
  font-size: 24px;
  font-family: Rubik;
  font-style: normal;
  line-height: 32px;
  letter-spacing: 0.01em;
  text-align: center;
}

.text {
  display: inline-block;
  width: 100%;
  max-width: 126rem;
  color: #141414;
  font-weight: 400;
  font-size: 16px;
  font-family: Rubik;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}

.orderedList {
  width: 100%;
  max-width: 126rem;

  & li {
    list-style: none;
  }
}

.link {
  color: #2b89e2;
  font-weight: 500;
  font-size: 16px;
  font-family: Rubik;
  font-style: normal;
  line-height: 24px;
  text-align: center;

  &_orange {
    color: #ff8264;
  }
}

@media (max-width: 479px) {
  .wrapper {
    max-width: 100%;
    height: auto;
  }

  .header {
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-size: 20px;
  }
  .text {
    max-width: 34.3rem;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
}
