.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 144rem;
  min-height: 53.6rem;
  font-weight: 500;
  font-family: Rubik;
  font-style: normal;
  background: #f9faff;
}

.commentPopupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(20, 20, 20, 0.8);
}

.commentPopup {
  position: relative;
  width: 82rem;
  height: 44.8rem;
  margin-top: 16rem;
  color: #141414;
  font-family: Rubik;
  font-style: normal;
  letter-spacing: 0.01em;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);

  &__button {
    position: absolute;
    top: 2.4rem;
    right: 2.4rem;
    z-index: 5;
    width: 1.8rem;
    height: 1.8rem;
    cursor: pointer;
  }

  &__header {
    margin-top: 3.2rem;
    margin-left: 3.2rem;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    opacity: 0.6;
  }

  &__name {
    width: 100%;
    margin-top: 1.6rem;
    padding-bottom: 2.4rem;
    padding-left: 3.2rem;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);
  }

  &__text {
    margin-top: 3.2rem;
    margin-left: 3.2rem;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 116rem;
  margin-top: 6.4rem;
}

.header {
  color: #141414;
  font-size: 40px;
  line-height: 47px;
  letter-spacing: 0.01em;
}

.textContainer {
  display: flex;
  align-items: flex-end;
  margin-top: 3.2rem;
  margin-bottom: 2.4rem;
}

.text {
  color: #141414;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.01em;

  &__m56 {
    margin-top: 5.6rem;
  }
}

.date {
  margin-left: 0.8rem;
  color: #141414;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  opacity: 0.5;
}

.testContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 3.2rem;
}

.commentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 36rem;
  height: 24.6rem;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);

  &__header {
    margin-top: 4rem;
    margin-left: 3.2rem;
    color: #141414;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    opacity: 0.6;
  }

  &__text {
    display: -webkit-box;
    max-width: 29.6rem;
    max-height: 9.6rem;
    margin-top: 1.6rem;
    margin-left: 3.2rem;
    overflow: hidden;
    color: #141414;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  &__link {
    margin-top: 1.6rem;
    margin-left: 3.2rem;
    color: #2b89e2;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    cursor: pointer;
  }
}

@media (max-width: 479px) {
  .container {
    max-width: 37.5rem;
    margin-top: 3.2rem;
  }

  .header {
    margin-left: 1.6rem;
    font-size: 24px;
    line-height: 32px;
  }

  .textContainer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 1.6rem 1.6rem 2rem 0;
  }

  .text {
    margin-left: 1.6rem;
    font-size: 20px;
    line-height: 28px;

    &__m56 {
      margin-top: 4.8rem;
    }
  }

  .testContainer {
    flex-direction: column;
    margin-bottom: 4rem;
  }

  .commentWrapper {
    width: 37.5rem;
    height: 18.4rem;
    border-radius: 0px;
    box-shadow: none;

    &__header {
      margin-top: 2.4rem;
      margin-left: 1.6rem;
      line-height: 24px;
    }

    &__text {
      max-width: 34.3rem;
      max-height: 7.2rem;
      margin-top: 0.8rem;
      margin-left: 1.6rem;
      -webkit-line-clamp: 3;
    }

    &__link {
      margin-top: 0.8rem;
      margin-left: 1.6rem;
      line-height: 24px;
    }
  }

  .commentPopupWrapper {
    position: relative;
    width: 100%;
    max-width: 48rem;
    height: 51rem;
    background-color: rgba(20, 20, 20, 0.8);
  }

  .commentPopup {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    margin-top: 0;
    border-radius: 0;
    box-shadow: none;

    &__header {
      margin-top: 3.2rem;
      margin-left: 1.6rem;
    }

    &__name {
      width: 100%;
      margin-top: 0.8rem;
      padding-left: 1.6rem;
    }

    &__text {
      margin-top: 0.8rem;
      margin-left: 1.6rem;
    }
  }
}
