.wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 200;
}

.background {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(20, 20, 20, 0.8);
  z-index: 2;
}

.popup {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 260px;
  background: #fff;
  box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
  border-radius: 20px;
  z-index: 3;

  &__closeIcon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23px;
    height: 23px;
    top: 12px;
    right: 12px;
    cursor: pointer;

    &:before,
    &:after {
      position: absolute;
      top: 0;
      width: 3px;
      height: 23px;
      background-color: #868686;
      content: "";
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  &__title {
    max-width: calc(100% - 32px);
    margin: 48px 0 0 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #141414;
    text-align: center;
  }

  &__buttons {
    display: flex;
    width: max-content;
    margin: 32px 0 0 0;
  }

  &__continueButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    padding: 0 32px;
    text-decoration: none;
    background: #45cb85;
    border-radius: 8px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
  }

  &__closeButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    margin: 0 0 0 32px;
    padding: 0 32px;
    background: rgba(69, 203, 133, 0.15);
    border-radius: 8px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #45cb85;
    cursor: pointer;
  }
}

@media (max-width: 479px) {
  .wrapper {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 200;
  }

  .background {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(20, 20, 20, 0.8);
    z-index: 2;
  }

  .popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 16px);
    max-width: 400px;
    height: 220px;
    background: #fff;
    box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
    border-radius: 20px;
    z-index: 3;

    &__closeIcon {
      position: absolute;
      width: 23px;
      height: 23px;
      top: 12px;
      right: 12px;
      cursor: pointer;

      &:before,
      &:after {
        position: absolute;
        top: 0;
        left: 10px;
        width: 3px;
        height: 23px;
        background-color: #868686;
        content: "";
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }

    &__title {
      max-width: calc(100% - 32px);
      margin: 48px 0 0 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #141414;
      text-align: center;
    }

    &__buttons {
      display: flex;
      width: max-content;
      margin: 28px 0 0 0;
    }

    &__continueButton {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      padding: 0 24px;
      text-decoration: none;
      background: #45cb85;
      border-radius: 8px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #fff;
    }

    &__closeButton {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      margin: 0 0 0 16px;
      padding: 0 24px;
      background: rgba(69, 203, 133, 0.15);
      border-radius: 8px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #45cb85;
      cursor: pointer;
    }
  }
}
