.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 109.6rem;
  height: 6.7rem;
  border-bottom: 1px solid rgba(20, 20, 20, 0.1);

  &:last-child {
    border: none;
  }
}

.name {
  width: 40%;
}

.laboratoryWrapper {
  display: flex;
  align-items: center;
  width: 25%;
}

.laboratory {
  max-width: 90px;
  max-height: 39px;
}

.date {
  display: flex;
  width: 15%;
  color: #141414;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  opacity: 0.5;
}

.link {
  display: flex;
  justify-content: flex-end;
  width: 20%;
  color: #2b89e2;
  font-size: 16px;
  line-height: 19px;
}

@media (max-width: 479px) {
  .wrapper {
    flex-direction: column;
    align-items: flex-start;
    width: 34.3rem;
    height: 219px;
  }

  .name {
    width: 100%;
    margin-top: 2.4rem;
  }

  .laboratory {
    width: 100%;
    max-width: 90px;
    max-height: 39px;
    margin: 16px 0 0 0;
  }

  .date {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 0.8rem;
    line-height: 24px;
  }

  .link {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2.4rem;
    line-height: 24px;
  }
}
