.text {
  &__description {
    width: 490px;
    margin: 8px 0 0 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
}

.button {
  height: 4rem;
  color: #45cb85;
  font-weight: 900;
  background: transparent;
  border: none;
  cursor: pointer;
}

.report {
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 531px;
    height: 56px;
    margin: 20px 0 0 0;
    background: rgba(69, 203, 133, 0.15);;
    border: none;
    border-radius: 8px;
    cursor: pointer;

    &_disabled {
      background: grey;
      cursor: default;
    }
  }

  &__buttonText {
    margin: 0 0 0 18px;
    color: #45cb85;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0 0 0;
}

  @media (max-width: 479px) {
    .text {
      &__description {
        width: 343px;
        margin: 8px 0 0 0;
        font-weight: normal;
        font-size: 16px;
        white-space: normal;
      }
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      width: 343px;
      margin: 16px 0 0 0;
    }
  }
