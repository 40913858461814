.tagWrapper {
  position: relative;
}

.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  margin: 0 8px 8px 0;
  padding: 8px 16px;
  color: #2b89e2;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background: rgba(43, 137, 226, 0.1);
  border-radius: 100px;
  cursor: pointer;

  &:hover {
    background: rgba(43, 137, 226, 0.2);
  }
}

.hint {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 364px;
  padding: 16px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.2);

  &::after {
    position: absolute;
    top: -15px;
    left: 20px;
    margin-left: -5px;
    border-color: transparent transparent #fff transparent;
    border-style: solid;
    border-width: 10px;
    content: "";
  }
}

@media (max-width: 479px) {
  .tagWrapper {
    position: inherit;
  }

  .hint {
    position: absolute;
    left: calc((100% - 343px) / 2);
    width: 343px;

    &::after {
      display: none;
    }
  }
}
