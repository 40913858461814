.howItWorksUs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  height: 630px;
  background: #f95b3d;
  padding: 0 145px;

  &__title {
    margin: 110px 0 0 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0.01em;
    color: #fff;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 56px 0 0 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__image {
    width: 156px;
    height: 156px;
  }

  &__itemTitle {
    margin: 24px 0 0 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #fff;
  }

  &__itemDescription {
    width: 332px;
    margin: 16px 0 0 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #fff;
  }
}

@media (max-width: 479px) {
  .howItWorksUs {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1440px;
    height: max-content;
    background: #f95b3d;
    padding: 56px 0;

    &__title {
      max-width: calc(100% - 48px);
      margin: 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 0.01em;
      color: #fff;
      text-align: center;
    }

    &__list {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      margin: 0;
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 48px 0 0 0;
    }

    &__image {
      width: 150px;
      height: 150px;
    }

    &__itemTitle {
      margin: 16px 0 0 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      color: #fff;
    }

    &__itemDescription {
      width: 332px;
      margin: 8px 0 0 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #fff;
    }
  }
}
