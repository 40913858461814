.block {
  width: 100%;

  &__head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 52px;
    background: #e6e8f1;
  }

  &__headText {
    margin: 0 0 0 32px;
    color: #8a8a8a;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  &__detailsContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 32px 32px 32px;
  }

  &__childCheckboxBlock {
    display: flex;
    align-items: center;
    height: 65px;
    width: calc(100% - 64px);
    border-top: 1px solid rgba(20, 20, 20, 0.1);
  }

  &__childCheckboxLabel {
    margin: 0 0 0 8px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #222;
    cursor: pointer;
  }

  &__checkboxWrapper {
    display: flex;
    align-items: flex-start;
    margin: 16px 0 0 0;

    &:first-of-type {
      margin: 0;
    }
  }
}

.flexWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 479px) {
  .block {
    &__head {
      max-width: 100vw;
      height: 41px;
    }

    &__headText {
      margin: 0 0 0 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      opacity: 0.5;
    }

    &__detailsContent {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      max-width: 100vw;
      padding: 0 16px 24px 16px;
    }

    &__childCheckboxBlock {
      display: flex;
      align-items: center;
      height: 56px;
      width: 343px;
      border-top: 1px solid rgba(20, 20, 20, 0.1);
    }

    &__childCheckboxLabel {
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      color: #222;
    }
  }
}
