.sideMenu {
  width: 360px;
  height: 456px;
  margin: 135px 0 0 40px;
  padding: 0 32px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);

  &__titleBlock {
    height: 81px;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);
  }

  &__titleText {
    display: flex;
    align-items: center;
    max-width: 296px;
    height: 56px;
    margin: 32px 0 0 0;
    overflow: hidden;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }

  &__tabBlock {
    display: flex;
    align-items: center;
    height: 86px;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);

    &:last-of-type {
      border: none;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    color: #5b5b5b;
    text-decoration: none;

    &:visited {
      color: #5b5b5b;
    }
  }

  &__activeLink {
    color: #2b89e2;

    &:visited {
      color: #2b89e2;
    }

    .profileImage {
      content: url("../../../assets/image/icons/profile-folder_active.svg");
    }

    .profileSettings {
      content: url("../../../assets/image/icons/profile-settings_active.svg");
    }

    .profileContacts {
      content: url("../../../assets/image/icons/profile-contacts_active.svg");
    }

    .profileExit {
      content: url("../../../assets/image/icons/profile-exit_active.svg");
    }
  }

  &__linkImage {
    width: 24px;
    height: 24px;
  }

  &__linkText {
    margin: 0 0 0 17px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }
}

@media (max-width: 479px) {
  .sideMenu {
    width: 100%;
    height: 97px;
    margin: 40px 0 0 0;
    padding: 0;
    overflow-y: hidden;
    background: none;
    border-radius: none;
    box-shadow: none;

    &__titleBlock {
      height: 28px;
      margin: 0 0 0 16px;
      border-bottom: none;
    }

    &__titleText {
      display: block;
      width: 343px;
      height: 28px;
      margin: 0;
      overflow: hidden;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
    }

    &__tabs {
      display: flex;
      max-width: 100%;
      margin: 16px 0 0 0;
      overflow-y: scroll;
    }

    &__tabBlock {
      display: flex;
      align-items: center;
      height: 53px;
      margin: 0 0 0 16px;
      padding: 0 16px;
      white-space: nowrap;
      background: #fff;
      border-bottom: none;
      border-radius: 16px;
      box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
    }

    &__link {
      display: flex;
      align-items: center;
      color: #5b5b5b;
      text-decoration: none;

      &:visited {
        color: #5b5b5b;
      }
    }

    &__activeLink {
      color: #2b89e2;

      &:visited {
        color: #2b89e2;
      }
    }

    &__linkImage {
      width: 20px;
      height: 20px;
    }

    &__linkText {
      width: 100%;
      height: 19px;
      margin: 0 0 0 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
  }
}
