.response {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1440px;
}

.fromBlood {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 716px;

  .response {
    &__title {
      width: 735px;
      height: 96px;
      margin-top: 80px;
      font-weight: 500;
      font-size: 32px;
      line-height: 48px;
      text-align: center;
    }

    &__content {
      display: flex;
      margin-top: 40px;
    }

    &__textContent {
      max-width: 500px;
      margin-top: 73px;
    }

    &__text {
      margin-top: 15px;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    &__image {
      width: 462px;
      height: 420px;
      margin: 0 0 0 173px;
    }
  }
}

.fromSaliva {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 878px;

  .response {
    &__title {
      width: 869px;
      height: 48px;
      margin-top: 80px;
      font-weight: 500;
      font-size: 32px;
      line-height: 48px;
      text-align: center;
    }

    &__subtitle {
      width: 847px;
      height: 32px;
      margin-top: 16px;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
    }

    &__content {
      display: flex;
      margin-top: 88px;
    }

    &__textContent {
      max-width: 500px;
      margin-top: 44px;
    }

    &__text {
      width: 500px;
      margin-top: 24px;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    &__listTitle {
      width: 500px;
      margin: 48px 0 16px 0;
      color: #f95b3d;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
    }

    &__listItem {
      font-weight: normal;
      font-size: 18px;
      line-height: 32px;
      white-space: pre-line;
    }

    &__imageContent {
      position: relative;
      margin: 0 0 0 173px;
    }

    &__image {
      width: 460px;
      height: 420px;
    }
  }
}

.message {
  position: absolute;
  top: 392px;
  left: -9px;
  width: 310px;
  height: 125px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 3px 31px rgba(0, 0, 0, 0.2);

  &::after {
    position: absolute;
    top: -15px;
    left: 244px;
    margin-left: -5px;
    border-color: transparent transparent #fff transparent;
    border-style: solid;
    border-width: 10px;
    content: "";
  }

  &__input {
    display: flex;
    align-items: center;
    width: 274px;
    height: 31px;
    margin: 19px 0 0 18px;
    background: #ffffff;
    border: 1px solid rgba(20, 20, 20, 0.1);
    border-radius: 8px;
  }

  &__presonIcon {
    width: 12px;
    height: 12px;
    margin-left: 12px;
  }

  &__email {
    width: 200px;
    margin-left: 12px;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
  }

  &__result {
    display: flex;
    margin: 12px 0 0 19px;
  }

  &__crossIcon {
    width: 12px;
    height: 12px;
    margin-top: 5px;
  }

  &__text {
    margin-left: 6px;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;

    &_bold {
      font-weight: 500;
    }
  }
}

@media (max-width: 479px) {
  .response {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 479px;
  }

  .fromBlood {
    height: 838px;

    .response {
      &__title {
        width: 343px;
        height: 84px;
        margin-top: 40px;
        font-size: 20px;
        line-height: 28px;
        white-space: pre-wrap;
      }

      &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        margin-top: 0;
      }

      &__textContent {
        max-width: 479px;
        margin-top: 24px;
      }

      &__text {
        width: 343px;
        margin: 8px 0 0 0;
        font-size: 16px;
        line-height: 24px;

        &:first-of-type {
          margin-top: 0;
        }
      }

      &__image {
        width: 365px;
        height: 321px;
        margin: 33px 0 0 0;
      }
    }
  }

  .fromSaliva {
    height: 1050px;

    .response {
      &__title {
        width: 343px;
        height: 56px;
        margin-top: 40px;
        font-size: 20px;
        line-height: 28px;
      }

      &__subtitle {
        width: 343px;
        height: 48px;
        margin-top: 8px;
        font-size: 16px;
        line-height: 24px;
      }

      &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 32px;
      }

      &__textContent {
        max-width: 343px;
        margin-top: 0;
      }

      &__text {
        width: 343px;
        margin-top: 16px;
        font-size: 16px;
        line-height: 24px;

        &:first-of-type {
          margin-top: 0;
        }
      }

      &__listTitle {
        width: 234px;
        margin: 32px 0 18px 0;
        font-size: 16px;
        line-height: 24px;
      }

      &__listItem {
        font-size: 16px;
        line-height: 24px;
      }

      &__imageContent {
        position: relative;
        margin: 33px 0 0 0;
      }

      &__image {
        width: 365px;
        height: 321px;
      }
    }
  }

  .message {
    position: absolute;
    top: 289px;
    left: 11px;
    width: 310px;
    height: 125px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 3px 31px rgba(0, 0, 0, 0.2);

    &::after {
      position: absolute;
      top: -15px;
      left: 244px;
      margin-left: -5px;
      border-color: transparent transparent #fff transparent;
      border-style: solid;
      border-width: 10px;
      content: "";
    }
  }
}
