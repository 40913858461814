.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 900px;
  min-height: 523px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
}

@media (max-width: 479px) {
  .main {
    width: 100%;
    min-height: 345px;
    background: #fff;
    border-radius: 0;
  }
}
