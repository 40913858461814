.question {
  position: relative;
  display: flex;
  flex-direction: column;

  &__title {
    margin: 0 0 24px 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #141414;

    &_hasError {
      color: #f20035;
    }
  }

  &__error {
    position: relative;
    align-self: center;
    margin: 8px 72px 0 0;
    padding: 8px 16px;
    background: #fff2f5;
    box-shadow: 0px 4px 10px rgba(88, 0, 19, 0.1);
    border-radius: 10px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #f20035;

    &::after {
      position: absolute;
      top: -20px;
      left: 47%;
      margin-left: -5px;
      border-color: transparent #fff2f5 transparent transparent;
      border-style: solid;
      border-width: 10px;
      transform: rotate(0.25turn);
      content: "";
    }
  }
}

@media (max-width: 479px) {
  .question {
    display: flex;
    flex-direction: column;

    &__title {
      margin: 0 0 24px 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #141414;

      &_hasError {
        color: #f20035;
      }
    }
  }
}
