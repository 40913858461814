.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 144rem;
  min-height: 53.6rem;
  font-weight: 500;
  font-family: Rubik;
  font-style: normal;
  background: #f9faff;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 116rem;
  margin-top: 6.4rem;
  margin-bottom: 15.3rem;
}

.childContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 116rem;
  margin: 50px 0 15.3rem 0;

  &:first-of-type {
    margin: 0 0 15.3rem 0;
  }
}

.header {
  color: #141414;
  font-size: 40px;
  line-height: 47px;
  letter-spacing: 0.01em;
}

.titleHolder {
  display: flex;
  align-items: center;
  width: 1160px;
  height: 76px;
}

.uploadTest {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 238px;
  height: 40px;
  margin: 0 0 0 auto;
  background: #45cb85;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  cursor: pointer;
}

.text {
  color: #141414;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.01em;
}

.packageListWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 1.6rem;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
  overflow: hidden;
}

.wrapperHead {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 52px;
  padding: 0 32px;
  background: #e6e8f1;

  &__item {
    display: flex;
    align-items: center;
    width: 40%;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #8a8a8a;

    &:last-of-type {
      width: 20%;
    }
  }

  &__laboratory {
    width: 25%;
  }

  &__data {
    width: 15%;
  }
}

.emptyResultsContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 116rem;
  height: 72.2rem;
  margin-top: 6.4rem;
  margin-bottom: 15.3rem;
  color: #141414;

  &__image {
    width: 6.4rem;
    height: 5.2rem;
    margin-top: 14.4rem;
  }

  &__header {
    width: 38.4rem;
    margin-top: 3.2rem;
    font-size: 40px;
    line-height: 47px;
    letter-spacing: 0.01em;
    text-align: center;
  }

  &__text {
    margin-top: 2.4rem;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  &__link {
    width: 29.6rem;
    height: 5.6rem;
    margin-top: 4rem;
    color: #ffffff;
    font-weight: 500;
    font-size: 18px;
    line-height: 5.6rem;
    text-align: center;
    text-decoration: none;
    background: #f95b3d;
    border-radius: 8px;
  }

  &__uploadButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 296px;
    height: 56px;
    margin: 40px 0 0 0;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-decoration: none;
    background: #45cb85;
    border-radius: 8px;
    cursor: pointer;
  }
}

@media (max-width: 479px) {
  .container {
    width: 100%;
    max-width: 37.5rem;
    margin-top: 3.2rem;
    margin-bottom: 4rem;
  }

  .header {
    margin-left: 1.6rem;
    font-size: 24px;
    line-height: 28px;
  }

  .titleHolder {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: max-content;
    margin: 16px 0 24px 0;
  }

  .uploadTest {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 343px;
    height: 56px;
    margin: 24px 0 0 0;
    background: #45cb85;
    border-radius: 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    cursor: pointer;
  }

  .text {
    width: 343px;
    font-size: 20px;
    line-height: 24px;
  }

  .packageListWrapper {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0px;
    box-shadow: none;
  }

  .wrapperHead {
    display: none;
  }

  .emptyResultsContainer {
    align-items: flex-start;
    width: 100%;
    max-width: 37.5rem;
    height: 62.1rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
    padding-left: 1.6rem;

    &__image {
      width: 4rem;
      height: 3.3rem;
      margin-top: 10rem;
    }

    &__header {
      width: 31.3rem;
      margin-top: 2.4rem;
      font-size: 20px;
      line-height: 24px;
      text-align: left;
    }

    &__text {
      margin-top: 1.6rem;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
    }

    &__link {
      width: 34.3rem;
      margin-top: 3.2rem;
    }

    &__uploadButton {
      width: 34.3rem;
      margin-top: 3.2rem;
    }
  }
}
