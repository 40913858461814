.contacts {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  min-height: 727px;
  color: #141414;
  font-family: Rubik;
  background: #f9faff;

  &__title {
    margin: 64px 0 0 140px;
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
  }
}

@media (max-width: 479px) {
  .contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100vw;
    min-height: 521px;

    &__title {
      width: 343px;
      margin: 32px 0 0 0;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    }
  }
}
