.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background-color: #f9faff;
}

.stage {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 50px;

  &__circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    border-radius: 50%;

    &_purple {
      background-color: #4654a3;
    }

    &_silver {
      background-color: #e6e8f1;
    }
  }

  &__number {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;

    &_white {
      color: #ffffff;
    }

    &_silver {
      color: rgba(20, 20, 20, 0.5);
    }
  }

  &__dashResult {
    width: 100px;
    background-color: #4654a3;
    border-radius: 40px;
    height: 6px;
    position: relative;

    &:nth-child(4) {
      background-color: #e6e8f1;
    }

    &:nth-child(4)::before {
      content: "";
      position: absolute;
      width: 50%;
      height: 6px;
      background-color: #4654a3;
      border-radius: 40px;
    }
  }
}

.title {
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-family: Rubik;
  font-weight: 500;
  margin: 80px 0 35px 0;
}

.survey {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  min-height: 535px;
  font-family: Rubik;
  color: #141414;

  &__container {
    width: 724px;
    height: 1025px;
    background: #ffffff;
    box-shadow: 0 4px 40px rgba(46, 46, 46, 0.07);
    border-radius: 20px;
    margin-bottom: 100px;
  }

  &__questionWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 162px;
    padding: 32px 72px 10px 72px;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);
  }

  &__btnWrap {
    display: flex;
    justify-content: center;
    height: 110px;
    align-items: center;
  }

  &__btn {
    width: 260px;
    height: 56px;
    background: #f95b3d;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    font-family: "Rubik";
    font-size: 18px;

    &_disabled {
      background: #c0c0c0;
      cursor: default;
    }
  }
}

@media (max-width: 479px) {
  .wrapper {
    overflow: hidden;
  }

  .stage {
    gap: 20px;

    &__dashResult {
      width: 75px;
    }
  }

  .title {
    margin: 40px 0 24px 0;
  }

  .survey {
    &__container {
      width: 100%;
      height: auto;
      border-radius: 0;
      margin-bottom: 64px;
    }

    &__questionWrapper {
      width: 100%;
      min-height: auto;
      padding: 32px 16px 16px 16px;
    }

    &__btn {
      width: 100%;
      margin-left: 16px;
      margin-right: 16px;
    }
  }
}
