.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 37.5rem;
  background-color: #fff;
}

.blockHidden {
  display: none;
}

.testsWrapper {
  width: 34.3rem;
  border-bottom: 1px solid rgba(20, 20, 20, 0.1);
}

.testItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 34.3rem;
  min-height: 6.6rem;
  color: #141414;
  font-weight: 500;
  font-size: 1.6rem;
  font-family: Rubik;
  font-style: normal;
  line-height: 1.9rem;
  letter-spacing: 0.01em;
  text-decoration: none;
  background: #fff;
  cursor: pointer;
}

.testsMenuWrapper {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 21.2rem;
}

.testsMenuItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 32.7rem;
  height: 5.15rem;
  color: #141414;
  font-weight: 500;
  font-size: 1.6rem;
  font-family: Rubik;
  font-style: normal;
  line-height: 1.9rem;
  letter-spacing: 0.01em;
  text-decoration: none;
  background: #fff;
  border-bottom: 1px solid rgba(20, 20, 20, 0.1);
  cursor: pointer;
}

.testsMenuItem:last-child {
  height: 5.9rem;
  border: none;
}

.testMenuImage {
  width: 1.9rem;
  height: 1.9rem;
  margin-right: 2rem;
  margin-left: 0.6rem;
}

.menuItem {
  position: relative;
  z-index: 2;
  width: 34.3rem;
  height: 6.8rem;
  color: #141414;
  font-weight: 500;
  font-size: 1.6rem;
  font-family: Rubik;
  font-style: normal;
  line-height: 6.7rem;
  letter-spacing: 0.01em;
  text-decoration: none;
  background: #fff;
  border-bottom: 1px solid rgba(20, 20, 20, 0.1);
  cursor: pointer;
}

.languageWrapper {
  width: 34.3rem;
  border-bottom: 1px solid rgba(20, 20, 20, 0.1);
}

.languageItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 34.3rem;
  height: 6.8rem;
  color: #141414;
  font-weight: 500;
  font-size: 1.6rem;
  font-family: Rubik;
  font-style: normal;
  line-height: 1.9rem;
  letter-spacing: 0.01em;
  text-decoration: none;
  background: #fff;
  cursor: pointer;
}

.flagImage {
  width: 24px;
  height: 17px;
  margin-right: 16px;
}

.arrowImage {
  position: absolute;
  right: 0;
  width: 12px;
  height: 8px;
}

.buttonWrapper {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 34.3rem;
  height: 7.2rem;
  border-top: 1px solid rgba(20, 20, 20, 0.1);
  border-bottom: 1px solid rgba(20, 20, 20, 0.1);
}

.registerItem {
  z-index: 2;
  width: 16.4rem;
  height: 4rem;
  padding: 0;
  color: #45cb85;
  font-weight: 500;
  font-size: 1.6rem;
  font-family: Rubik;
  font-style: normal;
  line-height: 4rem;
  text-align: center;
  text-decoration: none;
  background: rgba(69, 203, 133, 0.15);
  border: none;
  border-radius: 0.8rem;
  cursor: pointer;
}

.joinItem {
  z-index: 2;
  width: 16.4rem;
  height: 4rem;
  padding: 0;
  color: #fff;
  font-weight: 500;
  font-size: 1.6rem;
  font-family: Rubik;
  font-style: normal;
  line-height: 4rem;
  text-align: center;
  text-decoration: none;
  background: #45cb85;
  border: none;
  border-radius: 0.8rem;
  cursor: pointer;
}
