.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &_hidden {
    display: none;
  }

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(20, 20, 20, 0.8);
  }

  &__block {
    position: absolute;
    z-index: 1;
    width: 820px;
    overflow: hidden;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
  }

  &__topBlock {
    display: flex;
    height: 95px;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);
  }

  &__logoWrapper {
    display: none;
  }

  &__title {
    margin: 30px 0 0 32px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  &__closeIcon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23px;
    height: 23px;
    margin: 24px 24px 0 auto;
    cursor: pointer;

    &:before,
    &:after {
      position: absolute;
      top: 0;
      width: 3px;
      height: 23px;
      background-color: #868686;
      content: "";
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  &__centerBlock {
    display: flex;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);
  }

  &__testContent {
    width: 410px;
    padding: 32px 0 0 32px;
  }

  &__testInfo {
    display: flex;
    width: 336px;
    height: 97px;
    background: #f4f6fb;
    border-radius: 10px;

    &__imageHolder {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      margin: 16px 0 0 16px;
      background: #fafafa;
    }

    &__image {
      width: 38px;
      height: 38px;
    }

    &__name {
      width: 260px;
      margin: 16px 0 0 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }

    &__price {
      margin: 8px 0 0 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;

      &_euro {
        font-weight: 400;
      }
    }
  }

  &__subscriptionOptions {
    width: 336px;
    margin: 24px 0 0 0;
    padding: 0 0 32px 0;

    &__title {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }
  }

  // custom checkbox
  .inputWrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 336px;
    height: 60px;
    margin: 8px 0 0 0;
    padding-left: 52px;
    font-size: 22px;
    background: #f4f6fb;
    border-radius: 10px;
    cursor: pointer;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &_selected {
      background: #ecfaf3;
    }
  }

  // Hide the browser's default radio button
  .inputWrapper input {
    position: absolute;
    width: 0;
    height: 0;
    cursor: pointer;
    opacity: 0;
  }

  // Create a custom radio button
  .checkmark {
    position: absolute;
    top: 20px;
    left: 16px;
    width: 18px;
    height: 18px;
    background: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 50%;
  }

  // When the radio button is checked, add a green background
  .inputWrapper input:checked ~ .checkmark {
    background: #45cb85;
    border: 1px solid #45cb85;
  }

  // Create the indicator (the dot/circle - hidden when not checked)
  .checkmark:after {
    position: absolute;
    display: none;
    content: "";
  }

  // Show the indicator (dot/circle) when checked
  .inputWrapper input:checked ~ .checkmark:after {
    display: block;
  }

  // Style the indicator (dot/circle)
  .inputWrapper .checkmark:after {
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    background: white;
    border-radius: 50%;
  }

  &__inputText {
    width: 125px;
    height: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  &__inputDiscount {
    width: 129px;
    height: 20px;
    margin: 0 0 0 8px;
    color: #eb3f26;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  &__benefits {
    width: 410px;
    padding: 32px 0 0 32px;
    background: #f4f6fb;
  }

  &__benefitsTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    opacity: 0.8;
  }

  &__benefit {
    display: flex;
    margin: 24px 0 0 0;

    &:last-of-type {
      margin: 40px 0 0 0;
    }

    &__image {
      height: 100%;
    }

    &__title {
      width: 293px;
      height: 20px;
      margin: 0 0 0 17px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      opacity: 0.8;
    }

    &__description {
      margin: 8px 0 0 17px;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      opacity: 0.5;
    }
  }

  &__bottomBlock {
    display: flex;
    align-self: flex-end;
    height: 120px;
  }

  &__closeButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 169px;
    height: 56px;
    margin: 32px 24px 0 auto;
    color: #f95b3d;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    background: #fedcd5;
    border-radius: 8px;
    cursor: pointer;
  }

  &__confirmButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 169px;
    height: 56px;
    margin: 32px 32px 0 0;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    background: #f95b3d;
    border-radius: 8px;
    cursor: pointer;
  }
}

@media (max-width: 479px) {
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    &__background {
      display: none;
    }

    &__block {
      z-index: 1;
      width: 100%;
      height: max-content;
      overflow: hidden;
      background: #fff;
      border-radius: 0;
      box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
    }

    &__topBlock {
      position: relative;
      display: flex;
      height: 149px;
      min-height: 149px;
      border-bottom: 1px solid rgba(20, 20, 20, 0.1);
    }

    &__logoWrapper {
      position: absolute;
      top: 16px;
      left: 16px;
      display: block;
      width: 72px;
      height: 42px;
    }

    &__logo {
      width: 29px;
      height: 27px;
    }

    &__logoText {
      width: 72px;
      height: 9px;
    }

    &__title {
      margin: 108px 0 0 16px;
      font-size: 20px;
      line-height: 24px;
    }

    &__closeIcon {
      position: relative;
      width: 23px;
      height: 23px;
      margin: 29px 16px 0 auto;
      cursor: pointer;

      &:before,
      &:after {
        position: absolute;
        top: 0;
        left: 10px;
        width: 3px;
        height: 23px;
        background-color: #868686;
        content: "";
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }

    &__centerBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid rgba(20, 20, 20, 0.1);
    }

    &__testContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 24px 0 0 0;
    }

    &__testInfo {
      display: flex;
      width: 343px;
      height: 97px;
      background: #f4f6fb;
      border-radius: 10px;

      &__imageHolder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        margin: 16px 0 0 16px;
        background: #fafafa;
      }

      &__image {
        width: 38px;
        height: 38px;
      }

      &__name {
        width: 260px;
        margin: 16px 0 0 16px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
      }

      &__price {
        margin: 8px 0 0 16px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;

        &_euro {
          font-weight: 400;
        }
      }
    }

    &__subscriptionOptions {
      width: 343px;
      margin: 24px 0 0 0;
      padding: 0;

      &__title {
        font-size: 16px;
        line-height: 19px;
      }
    }

    // custom checbox
    .inputWrapper {
      position: relative;
      display: flex;
      align-items: center;
      width: 343px;
      height: 60px;
      margin: 8px 0 0 0;
      padding-left: 52px;
      font-size: 22px;
      background: #f4f6fb;
      border-radius: 10px;
      cursor: pointer;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &_selected {
        background: #ecfaf3;
      }

      &:first-of-type {
        margin: 10px 0 0 0;
      }
    }

    &__benefits {
      width: 100%;
      height: 311px;
      margin: 32px 0 0 0;
      padding: 24px 0 0 16px;
      background: #f4f6fb;
    }

    &__benefitsTitle {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      opacity: 0.8;
    }

    &__benefit {
      display: flex;
      margin: 24px 0 0 0;

      &:last-of-type {
        margin: 40px 0 0 0;
      }

      &__image {
        height: 100%;
      }

      &__title {
        width: 293px;
        height: 20px;
        margin: 0 0 0 17px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        opacity: 0.8;
      }

      &__description {
        margin: 8px 0 0 17px;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        opacity: 0.5;
      }
    }

    &__bottomBlock {
      display: flex;
      align-self: flex-end;
      justify-content: center;
      height: 105px;
    }

    &__closeButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 162px;
      height: 56px;
      margin: 24px 0 0 0;
    }

    &__confirmButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 162px;
      height: 56px;
      margin: 24px 0 0 19px;
    }
  }
}
