.dropdown {
  position: relative;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  &__inputWrapper {
    position: relative;
  }

  &__triangleHolder {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    top: 28px;
    right: 0;
    cursor: pointer;
  }

  &__triangle {
    width: 0;
    height: 0;
    top: 46px;
    right: 16px;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: #141414 transparent transparent transparent;
    opacity: 0.6;

    &_active {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 6px 5px;
      border-color: transparent transparent #141414 transparent;
    }
  }

  &__list {
    position: absolute;
    border-radius: 10px;
    height: max-content;
    max-height: 160px;
    overflow-y: scroll;
    width: 100%;
    margin: 2px 0 0 0;
    border: 1px solid rgba(20, 20, 20, 0.1);
    background: white;
    z-index: 3;

    &:last-child {
      border-bottom: none;
    }
  }

  &__listItem {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding-left: 16px;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);
    color: #141414;
    font-weight: normal;
    font-size: 16px;
    font-family: Rubik;
    font-style: normal;
    line-height: 20px;
    cursor: pointer;
  }
}
