.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #f9faff;
  padding-bottom: 50px;
}

.stage {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 50px;

  @media (max-width: 479px) {
    gap: 20px;
  }

  &__circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-color: #4654a3;
  }

  &__number {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #ffffff;
  }

  &__dashResult {
    width: 100px;
    background-color: #4654a3;
    border-radius: 40px;
    height: 6px;

    @media (max-width: 479px) {
      width: 75px;
    }
  }
}

.uploadMenu {
  margin-top: 70px;

  @media (max-width: 479px) {
    margin-top: 35px;
  }

  &__consentWrap {
    display: flex;
    justify-content: center;

    @media (max-width: 479px) {
      margin-top: 40px;
    }
  }

  &__consent {
    width: 40%;

    @media (max-width: 479px) {
      width: 90%;
    }
  }

  &__btnWrap {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
    height: 56px;
    background: #f95b3d;
    border: none;
    border-radius: 8px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    cursor: pointer;

    @media (max-width: 479px) {
      width: 90%;
    }

    &_disabled {
      background: #c0c0c0;
      cursor: default;
    }
  }
}

.survey {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  min-height: 535px;
  font-family: Rubik;
  color: #141414;

  @media (max-width: 479px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1440px;
    min-height: 400px;
  }
}
