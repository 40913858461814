.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #F9FAFF;
  padding-bottom: 50px;

  @media (min-width: 768px) {
    padding-bottom: 300px;
  }
}

.stage {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 50px;

  @media (max-width: 479px) {
    gap: 20px;
  }

  &__circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    border-radius: 50%;

    &_purple {
      background-color: #4654A3;
    }

    &_silver {
      background-color: #E6E8F1;
    }
  }

  &__number {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;

    &_white {
      color: #FFFFFF;
    }

    &_silver {
      color: rgba(20, 20, 20, 0.5);
    }
  }

  &__dashResult {
    width: 100px;
    background-color: #E6E8F1;
    border-radius: 40px;
    height: 6px;
    position: relative;

    @media (max-width: 479px) {
      width: 75px;
    }

    &:nth-child(2)::before {
      content: '';
      position: absolute;
      width: 50%;
      height: 6px;
      background-color: #4654A3;
      border-radius: 40px;
    }
  }
}

.menu {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;

  @media (min-width: 768px) {
    margin-top: 144px;
  }

  &__header {
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #141414;
    text-align: center;
  }

  &__description {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin: 0 0 20px 0;

    @media (max-width: 479px) {
      margin: 0;
      padding: 0 10px;
    }
  }

  &__buttonWrap {
    display: flex;
    justify-content: center;
    margin-top: 25px;

    @media (max-width: 479px) {
      margin-top: 16px;
    }
  }

  &__btn {
    width: 230px;
    height: 50px;
    background: #F95B3D;
    border-radius: 8px;
    border: none;
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;

    @media (max-width: 479px) {
      width: 343px;
    }
  }
}



