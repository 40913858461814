.checkbox {
  &__input {
    width: 16px;
    height: 16px;
    border: 1px solid #e8e8e8;
    border-radius: 3.5px;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;

    &::after {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      height: 13px;
      content: url(../../../assets/image/icons/check-solid.svg);
    }

    &:checked {
      background-color: #45cb85;
      border-color: #45cb85;
    }
  }
}
