.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 144rem;
  padding-bottom: 10rem;
  background: #f9faff;
}

.header {
  margin-top: 6rem;
  margin-bottom: 4rem;
  color: #141414;
  font-weight: 700;
  font-size: 24px;
  font-family: Rubik;
  font-style: normal;
  line-height: 32px;
  letter-spacing: 0.01em;
  text-align: center;
}

.title {
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #141414;
  font-weight: 700;
  font-size: 20px;
  font-family: Rubik;
  font-style: normal;
  line-height: 28px;
  letter-spacing: 0.01em;
  text-align: center;
}

.text {
  display: inline-block;
  width: 100%;
  max-width: 126rem;
  color: #141414;
  font-weight: 400;
  font-size: 16px;
  font-family: Rubik;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}

.link {
  color: #2b89e2;
  font-weight: 500;
  font-size: 16px;
  font-family: Rubik;
  font-style: normal;
  line-height: 24px;
  text-align: center;
}

ul {
  list-style-type: disc;
}

li {
  width: 120rem;
  margin-bottom: 0;
  color: #141414;
  font-weight: 400;
  font-size: 16px;
  font-family: Rubik;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  list-style-type: disc;
}

table {
  width: 100%;
  max-width: 126rem;
  color: #141414;
  font-weight: 400;
  font-size: 16px;
  font-family: Rubik;
  font-style: normal;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: justify;
  border: 1px #000 solid;
  border-collapse: collapse;
}

td {
  padding: 1.6rem;
  border: 1px #000 solid;
}

@media (max-width: 479px) {
  .wrapper {
    max-width: 100%;
    height: auto;
  }

  .header {
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-size: 20px;
  }
  .title {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 18px;
  }
  .text {
    max-width: 34.3rem;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
  li {
    width: 32rem;
  }
  table {
    max-width: 34.3rem;
    font-size: 12px;
    line-height: 14px;
  }

  td {
    padding: 0.4rem;
  }
}
