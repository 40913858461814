.message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1440px;
  min-height: 536px;

  &__image {
    width: 64px;
    height: 64px;
  }

  &__title {
    margin: 24px 0 0 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #141414;
    text-align: center;
  }

  &__subTitle {
    max-width: 343px;
    margin: 16px 0 0 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #141414;
    text-align: center;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    margin: 30px 0 0 0;
    padding: 16px;
    background: #45cb85;
    border-radius: 8px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #fff;
    text-decoration: none;
  }
}
