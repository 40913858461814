.settings {
  margin: 64px 0 80px 0;

  &__title {
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
  }

  &__content {
    width: 760px;
    min-height: max-content;
    margin: 24px 0 0 0;
    overflow: hidden;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
  }

  &__head {
    display: flex;
    align-items: center;
    height: 52px;
    padding: 0 0 0 32px;
    color: #8a8a8a;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    background: #e6e8f1;
  }

  &__settingsBlock {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 8px 32px 40px 32px;

    &:last-of-type {
      flex-direction: column;
    }
  }

  &__notice {
    display: flex;
    align-items: center;
    width: 100%;
    height: max-content;
    margin: 16px 0 0 0;
    padding: 16px;
    background: #fdecf0;
    border-radius: 10px;
  }

  &__noticeImage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: #f16a87;
    color: #fff;
    border-radius: 50%;
    font-weight: 500;
    font-size: 20px;
  }

  &__noticeText {
    margin: 0 0 0 16px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #141414;
  }

  &__noticeLink {
    text-decoration-line: underline;
    color: #2b89e2;
  }

  &__changeButton {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    width: 296px;
    height: 56px;
    margin: 40px 0 0 0;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-decoration: none;
    background: #f95b3d;
    border-radius: 8px;
  }
}

@media (max-width: 479px) {
  .settings {
    margin: 32px 0 40px 0;

    &__title {
      margin: 0 0 0 32px;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
    }

    &__content {
      width: 100%;
      min-height: max-content;
      margin: 16px 0 0 0;
      overflow: hidden;
      background: #fff;
      border-radius: 0;
      box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
    }

    &__head {
      display: flex;
      align-items: center;
      height: 41px;
      padding-left: 32px;
      color: #8a8a8a;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      background: #e6e8f1;
    }

    &__settingsBlock {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 0 16px 32px 16px;
    }

    &__notice {
      display: flex;
      align-items: center;
      width: 100%;
      height: max-content;
      margin: 12px 0 0 0;
      padding: 12px;
      background: #fdecf0;
      border-radius: 10px;
    }

    &__noticeImage {
      width: 20px;
      height: 20px;
    }

    &__noticeText {
      margin: 0 0 0 16px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.01em;
      color: #141414;
    }

    &__changeButton {
      display: flex;
      align-items: center;
      align-self: center;
      justify-content: center;
      width: 296px;
      height: 56px;
      margin: 32px 0 0 0;
      color: #fff;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      text-decoration: none;
      background: #f95b3d;
      border-radius: 8px;
    }
  }
}
