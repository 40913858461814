.surveyItem {
  display: flex;
  align-items: center;
  width: 696px;
  height: 76px;
  border-bottom: 1px solid rgba(20, 20, 20, 0.1);

  &:last-of-type {
    height: 76px;
    border-bottom: none;
  }

  &__name {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #141414;
  }

  &__link {
    margin: 0 0 0 auto;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #2b89e2;
  }
}

@media (max-width: 479px) {
  .surveyItem {
    display: flex;
    flex-direction: column;
    align-items: initial;
    width: 100%;
    height: 105px;
    padding: 0 16px;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);

    &:last-of-type {
      height: 104px;
      border-bottom: none;
    }

    &__name {
      width: 300px;
      margin: 24px 0 0 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    &__link {
      margin: 8px 0 0 0;
      color: #2b89e2;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
