.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 0 0 0;
}

.title {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
}

.frame {
  margin: 20px 0 0 0;
  width: 853px;
  height: 480px;
}

@media (max-width: 479px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0 0 0;
  }
  
  .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }
  
  .frame {
    margin: 20px 0 0 0;
    width: 350px;
    height: 200px;
  }
}