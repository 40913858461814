.spinnerWrapper {
  position: absolute;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  min-width: inherit;
  max-width: inherit;
  height: inherit;
  min-height: inherit;
  max-height: inherit;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: inherit;
}

@keyframes ldio-7v0ai4u9zf5 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ldio-7v0ai4u9zf5 div {
  position: absolute;
  top: 24px;
  left: 47px;
  width: 6px;
  height: 12px;
  background: #fe718d;
  border-radius: 3px / 6px;
  transform-origin: 3px 26px;
  animation: ldio-7v0ai4u9zf5 linear 1s infinite;
}
.ldio-7v0ai4u9zf5 div:nth-child(1) {
  background: #fe718d;
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
}
.ldio-7v0ai4u9zf5 div:nth-child(2) {
  background: #f47e60;
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
}
.ldio-7v0ai4u9zf5 div:nth-child(3) {
  background: #f8b26a;
  transform: rotate(60deg);
  animation-delay: -0.75s;
}
.ldio-7v0ai4u9zf5 div:nth-child(4) {
  background: #abbd81;
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
}
.ldio-7v0ai4u9zf5 div:nth-child(5) {
  background: #849b87;
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
}
.ldio-7v0ai4u9zf5 div:nth-child(6) {
  background: #6492ac;
  transform: rotate(150deg);
  animation-delay: -0.5s;
}
.ldio-7v0ai4u9zf5 div:nth-child(7) {
  background: #637cb5;
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
}
.ldio-7v0ai4u9zf5 div:nth-child(8) {
  background: #6a63b6;
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
}
.ldio-7v0ai4u9zf5 div:nth-child(9) {
  background: #fe718d;
  transform: rotate(240deg);
  animation-delay: -0.25s;
}
.ldio-7v0ai4u9zf5 div:nth-child(10) {
  background: #f47e60;
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
}
.ldio-7v0ai4u9zf5 div:nth-child(11) {
  background: #f8b26a;
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
}
.ldio-7v0ai4u9zf5 div:nth-child(12) {
  background: #abbd81;
  transform: rotate(330deg);
  animation-delay: 0s;
}
.loadingio-spinner-spinner-yu86wwsp84r {
  display: inline-block;
  width: 87px;
  height: 87px;
  overflow: hidden;
  background: none;
}
.ldio-7v0ai4u9zf5 {
  position: relative;
  width: 100%;
  height: 100%;
  transform: translateZ(0) scale(0.87);
  transform-origin: 0 0;
  backface-visibility: hidden;
}
.ldio-7v0ai4u9zf5 div {
  box-sizing: content-box;
}
