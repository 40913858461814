.wrapper {
    position: absolute;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
    height: 100%;
    background-color: rgba(0,0,0,0.1);
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 46rem;
    height: 46rem;
    background-color: #F9FAFF;
}

.header {
    color: #141414;
    font-weight: 500;
    font-size: 24px;
    font-family: Rubik;
    font-style: normal;
    line-height: 32px;
    letter-spacing: 0.01em;
    text-align: center;
}

.text {
    width: 35.5rem;
    margin-top: 2.4rem;
    margin-bottom: 3.2rem;
    color: #141414;
    font-weight: normal;
    font-size: 16px;
    font-family: Rubik;
    font-style: normal;
    line-height: 24px;
    text-align: center;
}

.button {
    width: 24rem;
    height: 4rem;
    margin-top: 3.2rem;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 16px;
    font-family: Rubik;
    font-style: normal;
    line-height: 4rem;
    text-align: center;
    text-decoration: none;
    background: #45CB85; 
    border: 0;
    border-radius: 8px;
    outline: none;
}

@media (max-width: 479px) {

    .container {
        width: 100%;
        height: 100%;
        // min-height: 50rem;
    }

    .header{
        font-size: 20px;
    }

    .text {
        width: 34.3rem;
    }

    .button {
        width: 34.3rem;
        margin-bottom: 17rem;
    }

}