.link {
  display: flex;
  align-items: center;
  width: 804px;
  min-height: 58px;
  margin: 16px 0 0 0;
  text-decoration: none;
  background: #f4f6fb;
  border-radius: 10px;

  &:first-child {
    margin: 0 0 0 0;
  }

  &:last-child {
    margin: 16px 0 40px 0;
  }

  &:last-of-type {
    margin: 16px 0 48px 0;
  }

  &__text {
    margin-left: 24px;
    color: #141414;
    font-weight: 500;
    font-size: 18px;
    font-family: Rubik;
    font-style: normal;
    line-height: 26px;
  }

  &__popular {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85px;
    height: 29px;
    margin-left: 16px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    background: #ffa500;
    border-radius: 10px;
  }

  &__new {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 81px;
    height: 29px;
    margin-left: 16px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    background: #f95b3d;
    border-radius: 10px;
  }

  &__arrow {
    margin: 0 24px 0 auto;
  }
}

@media (max-width: 479px) {
  .link {
    width: 95vw;
    min-height: 56px;

    &:first-child {
      margin: 0 0 0 0;
    }

    &:last-child {
      margin: 16px 0 24px 0;
    }

    &:last-of-type {
      margin: 16px 0 24px 0;
    }

    &__text {
      max-width: 230px;
      height: 100%;
      margin: 16px 0 16px 16px;
      font-size: 16px;
      line-height: 24px;
    }

    &__popular {
      width: 68px;
      min-width: 68px;
      height: 22px;
      margin: 0 8px 0 6px;
      font-size: 12px;
      line-height: 14px;
      border-radius: 6px;
    }

    &__new {
      width: 66px;
      height: 22px;
      margin-left: 6px;
      font-size: 12px;
      line-height: 14px;
      border-radius: 6px;
    }

    &__arrow {
      width: 18px;
      height: 10px;
      margin: 0 16px 0 auto;
    }
  }
}
