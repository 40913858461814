.checkout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  color: #141414;
  font-family: Rubik;
  background: #f9faff;

  &__title {
    width: 100%;
    max-width: 1160px;
    margin: 56px 0 0 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1160px;
    margin: 32px 0 80px 0;
  }
}

@media (max-width: 479px) {
  .checkout {
    position: relative;

    &__title {
      width: 100%;
      max-width: 343px;
      margin: 32px 0 0 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }

    &__content {
      position: static;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 1160px;
      margin: 24px 0 184px 0;
    }
  }
}
