.gender {
  &__wrapper {
    display: flex;
    gap: 20px;
    padding-bottom: 30px;

    @media (max-width: 479px) {
      margin-bottom: -15px;
    }
  }

  &__content {
    width: 282px;
    height: 132px;
    background: #f4f6fb;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;

    &:hover {
      cursor: pointer;
    }
  }

  &__imgWrap {
    width: 48px;
    height: 48px;
    background-color: #dee0ec;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__active {
    background-color: #ecfaf3;
  }

  &__activeImgWrap {
    background: #ccebdb;
  }

  &__text {
    font-family: "Rubik";
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #141414;
  }
}
