.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 144rem;
  background: #fff;
}

.fakeHeader {
  width: 100%;
  max-width: 1440px;
  height: 121px;
  background: #fff;

  &__image {
    margin: 20px 0 0 140px;
    width: 106px;
    height: 63px;
  }
}

.promo {
  display: flex;
  padding: 0 140px;

  &__textContent {
    display: flex;
    flex-direction: column;
    width: 490px;
    margin: 128px 0 0 0;
  }

  &__title {
    width: 443px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0.01em;
    color: #141414;
    white-space: pre-wrap;
  }

  &__subtitle {
    margin: 16px 0 0 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    color: #141414;
  }

  &__button {
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 252px;
    height: 56px;
    margin: 40px 0 0 0;
    background: #f95b3d;
    border-radius: 8px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    cursor: pointer;
  }

  &__image {
    margin: 0 0 0 56px;
  }
}

.howitworks {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 0 0 0;
  padding: 0 175px 0 140px;

  &__title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #141414;
  }

  &__subtitle {
    width: 600px;
    margin: 16px 0 0 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #141414;
  }

  &__content {
    display: flex;
    margin: 56px 0 0 0;
  }
}

.benefits {
  margin: 29px 0 0 64px;

  &__title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #141414;
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 40px 0 0 0;

    &:first-of-type {
      margin: 35px 0 0 0;
    }
  }

  &__image {
    align-self: flex-start;
    width: 32px;
    height: 32px;
  }

  &__text {
    margin: 0 0 0 24px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    color: #141414;
  }
}

.partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 383px;
  margin: 104px 0 0 0;
  background: #f9faff;

  &__title {
    margin: 80px 0 0 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0.01em;
    color: #141414;
  }

  &__list {
    display: flex;
    align-items: center;
    margin: 64px 0 0 0;
  }

  &__image {
    max-width: 217px;
    max-height: 82px;

    &:first-of-type {
      margin: 0 101px 0 0;
    }

    &:last-of-type {
      margin: 0 0 0 90px;
    }
  }
}

.freesample {
  position: relative;
  display: flex;
  padding: 0 141px 98px 156px;
  overflow: hidden;

  &__textContent {
    margin: 244px 0 0 0;
    z-index: 1;
  }

  &__title {
    width: 491px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0.01em;
    color: #141414;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 252px;
    height: 56px;
    margin: 32px 0 0 0;
    background: #f95b3d;
    border-radius: 8px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    cursor: pointer;
  }

  &__image {
    margin: 98px 0 0 56px;
    z-index: 1;
  }

  &__background {
    position: absolute;
    width: 2434px;
    height: 2434px;
    top: 140px;
    left: 200px;
    background: linear-gradient(206.16deg, #ffffff 61.71%, #fff0ed 83.53%);
    filter: blur(100px);
    transform: matrix(0.89, 0.46, 0.46, -0.89, 0, 0);
    border-radius: 50%;
  }
}

@media (max-width: 479px) {
  .wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 144rem;
    background: #fff;
  }

  .fakeHeader {
    width: 100vw;
    height: 91px;
    background: #fff;

    &__image {
      margin: 16.5px 0 0 16px;
      width: 72px;
      height: 42px;
    }
  }

  .hidden {
    display: none;
  }

  .promo {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;

    &__textContent {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0;
    }

    &__title {
      width: 343px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.01em;
      color: #141414;
    }

    &__subtitle {
      width: 343px;
      margin: 8px 0 0 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      color: #141414;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 343px;
      height: 56px;
      margin: 24px 0 0 0;
      background: #f95b3d;
      border-radius: 8px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #fff;
      cursor: pointer;
    }

    &__image {
      max-width: calc(100vw - 32px);
      max-height: calc(100vw - 32px);
      margin: 24px 0 0 -40px;
    }
  }

  .howitworks {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0 0 0;
    padding: 0 16px;

    &__title {
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #141414;
    }

    &__subtitle {
      width: 343px;
      margin: 8px 0 0 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      color: #141414;
    }

    &__image {
      width: calc(100vw - 46px);
      height: calc(100vw - 46px);
    }

    &__content {
      display: flex;
      flex-direction: column;
      margin: 0;
    }
  }

  .benefits {
    margin: 40px 0 0 0;

    &__title {
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #141414;
    }

    &__item {
      display: flex;
      align-items: center;
      margin: 32px 0 0 0;

      &:first-of-type {
        margin: 24px 0 0 0;
      }
    }

    &__image {
      align-self: flex-start;
      width: 32px;
      height: 32px;
    }

    &__text {
      margin: 0 0 0 16px;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      color: #141414;
    }
  }

  .partners {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 490px;
    margin: 40px 0 0 0;
    background: #f9faff;

    &__title {
      margin: 56px 0 0 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 0.01em;
    }

    &__list {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 32px 0 0 0;
    }

    &__image {
      max-width: 240px;
      max-height: 112px;
      margin: 41px 0 0 0;

      &:first-of-type {
        margin: 0;
      }

      &:last-of-type {
        margin: 48px 0 0 0;
      }
    }
  }

  .freesample {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding: 0 0 56px 0;
    overflow: hidden;

    &__textContent {
      margin: 24px 0 0 0;
    }

    &__title {
      width: 343px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.01em;
      color: #141414;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 343px;
      height: 56px;
      margin: 24px 0 0 0;
      background: #f95b3d;
      border-radius: 8px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #fff;
      cursor: pointer;
    }

    &__image {
      max-width: calc(100vw - 10px);
      max-height: calc(100vw - 10px);
      margin: 18px 0 0 0;
    }

    &__background {
      display: none;
    }
  }
}
