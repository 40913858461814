.partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 473px;
  background: #f9faff;

  &__title {
    width: 355px;
    height: 48px;
    margin-top: 110px;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
  }

  &__list {
    display: flex;
    align-items: center;
    height: 100px;
    margin-top: 65px;
  }

  &__image {
    max-height: 100px;
    margin-left: 100px;

    &:first-child {
      margin-left: 0px;
    }
  }
}

@media (max-width: 479px) {
  .partners {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 490px;
    height: 490 px;
    background: #f9faff;

    &__title {
      width: 266px;
      height: 28px;
      margin-top: 56px;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
    }

    &__list {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 240px;
      height: 100px;
      margin-top: 32px;
    }

    &__image {
      max-height: 100px;
      margin-top: 40px;
      margin-left: 0px;

      &:first-child {
        margin-top: 0;
        margin-left: 0;
      }

      &:last-child {
        margin-top: 50px;
        margin-left: 0;
      }
    }
  }
}
