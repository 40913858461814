.cardiac__risk {
  display: grid;
  flex-direction: row;
  grid-template-areas: "left right";
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  background-image: linear-gradient(to right bottom, #fff 75%, #2d73f628);

  &__title {
    margin-left: 110px;
    font-family: Rubik;
    max-width: 532px;
    color: #000;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
  }

  &__text {
    margin-left: 110px;
    font-family: Rubik;
    max-width: 532px;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    color: #141414;
    margin-right: 30px;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
    height: 56px;
    margin: 40px 0 0 0;
    background: #f95b3d;
    border: none;
    border-radius: 8px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    margin-bottom: 184px;
    margin-left: 110px;

    &:active {
      background-color: #f95c3dcc;
    }
  }

  &__shape {
    grid-area: right;
    border-radius: 600px;
    background-color: #2d73f61b;
    width: 510px;
    height: 510px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 184px;

    &__img {
      max-width: 500px;
      max-height: 336px;
    }
  }
}

@media (max-width: 768px) {
  .cardiac__risk {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    background-image: linear-gradient(to top, #fff 75%, #2d73f628);

    &__title {
      max-width: 400px;
      font-size: 22px;
      line-height: 24px;
      margin-left: auto;
      margin-right: auto;
      width: 90%;
    }

    &__text {
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
      width: 90%;
    }

    &__button {
      margin-bottom: 48px;
      margin-left: auto;
      margin-right: auto;
      max-width: 400px;
      width: 90%;
      position: sticky;
      bottom: 10px;
    }

    &__shape {
      background-color: transparent;
      width: auto;
      height: auto;
      margin-bottom: 0;

      &__img {
        max-width: 230px;
        max-height: 158px;
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 1280px) {
  .cardiac__risk {
    &__title {
      max-width: 400px;
      font-size: 22px;
      line-height: 24px;
      margin-left: auto;
      margin-right: auto;
      width: 90%;
    }

    &__text {
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
      width: 90%;
    }

    &__button {
      margin-bottom: 48px;
      margin-left: auto;
      margin-right: auto;
      max-width: 400px;
      width: 90%;
    }
  }
}
