.wrapper {
  position: absolute;
  top: 50px;
  left: -300px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 36.4rem;
  min-height: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.2);
}

.wrapper::after {
  position: absolute;
  top: -20px;
  left: 85%;
  margin-left: -5px;
  border-color: transparent transparent #fff transparent;
  border-style: solid;
  border-width: 10px;
  content: "";
}

.listWrapper {
  width: 31.6 rem;
}

.listWrapper:last-child {
  border: none;
}

.itemWrapper {
  display: flex;
  align-items: center;
  width: 31.6rem;
  height: max-content;
  padding: 0 0 16px 0;
  border-bottom: 1px solid rgba(20, 20, 20, 0.1);
}

.itemWrapper:last-of-type {
  border: none;
}

.itemImage {
  width: 4rem;
  height: 4rem;
}

.itemDescription {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  margin-left: 1.6rem;

  &__name {
    margin-top: 1.7rem;
    color: #141414;
    font-weight: 500;
    font-size: 14px;
    font-family: Rubik;
    font-style: normal;
    line-height: 17px;
    max-width: 216px;
  }

  &__quantity {
    margin-top: 0.8rem;
    color: #141414;
    font-weight: normal;
    font-size: 14px;
    font-family: Rubik;
    font-style: normal;
    line-height: 17px;
    letter-spacing: 0.01em;
  }

  &__price {
    margin-top: 0.8rem;
    color: #141414;
    font-weight: 500;
    font-size: 14px;
    font-family: Rubik;
    font-style: normal;
    line-height: 17px;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin: 0 20px 0 auto;
    background: #270949;
    border-radius: 50%;
    cursor: pointer;
  }

  &__trashBin {
    width: 10px;
    height: 12px;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 15.2rem;
  border-top: 1px solid rgba(20, 20, 20, 0.1);

  &__totalWrapper {
    display: flex;
    justify-content: space-between;
    width: 31.6rem;
    margin-top: 2.4rem;

    &__pricesHolder {
      display: flex;
    }

    &__orangePrice {
      margin: 0 0 0 8px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      text-decoration-line: line-through;
      color: #eb3f26;
    }

    &_text {
      color: #141414;
      font-weight: 500;
      font-size: 16px;
      font-family: Rubik;
      font-style: normal;
      line-height: 19px;
      letter-spacing: 0.01em;
      opacity: 0.5;
    }

    &_number {
      color: #141414;
      font-weight: 500;
      font-size: 16px;
      font-family: Rubik;
      font-style: normal;
      line-height: 19px;
      letter-spacing: 0.01em;
    }
  }

  &__deliveryWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 31.6rem;
    height: 2.8rem;
    margin-top: 0.8rem;
    padding-left: 0.8rem;
    background: rgba(69, 203, 133, 0.1);
    border-radius: 10px;

    &_image {
      width: 2.3rem;
      height: 1.2rem;
    }

    &_text {
      height: 2.8rem;
      margin-left: 0.8rem;
      color: #141414;
      font-weight: normal;
      font-size: 12px;
      font-family: Rubik;
      font-style: normal;
      line-height: 28px;
    }
  }

  &__link {
    width: 100%;
    height: 4.8rem;
    margin-top: 2.5rem;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    font-family: Rubik;
    font-style: normal;
    line-height: 4.8rem;
    text-align: center;
    text-decoration: none;
    background: #f95b3d;
    border-radius: 0px 0px 8px 8px;
  }
}

.euroSymbol {
  font-weight: 400 !important;
}

.bold {
  font-weight: 500 !important;
}

@media (max-width: 479px) {
  .wrapper {
    left: -263px;
    width: 34.3rem;
  }

  .wrapper::after {
    left: 80%;
  }
}
