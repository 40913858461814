.bloodInstruction {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  height: 1007px;

  .instruction {
    &__title {
      width: 673px;
      margin-top: 80px;
      font-weight: 500;
      font-size: 32px;
      line-height: 48px;
      text-align: center;
    }

    &__steps {
      display: flex;
      margin: 56px 0 0 0;
    }

    &__firstStep {
      display: flex;
      width: 148px;
      height: 54px;

      .instruction__number {
        width: 36px;
      }
    }

    &__secondStep {
      display: flex;
      width: 163px;
      height: 54px;
      margin: 0 0 0 127px;

      .instruction__number {
        width: 51px;
      }
    }

    &__thirdStep {
      display: flex;
      width: 163px;
      height: 54px;
      margin: 0 0 0 99px;

      .instruction__number {
        width: 51px;
      }
    }

    &__number {
      height: 48px;
      color: #45cb85;
      font-weight: normal;
      font-size: 60px;
      font-family: Krona One;
      font-style: normal;
      line-height: 48px;
    }

    &__numberText {
      margin: 22px 0 0 16px;
      color: #45cb85;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    }

    &__howToDo {
      position: relative;
      display: flex;
      margin: 66px 0 0 0;
    }

    &__step {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 370px;
      height: 296px;
      max-height: 296px;
      margin: 0 0 0 5px;

      &:first-of-type {
        height: 259px;
        margin: 36px 0 0 0;
      }

      &:last-of-type {
        height: 288px;
        margin: 8px 0 0 5px;
      }
    }

    &__image {
      width: 100%;
      height: 100%;
    }

    &__text {
      width: 370px;
      height: 52px;
      margin: auto 0 0 0;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      white-space: pre-wrap;
      text-align: center;
    }

    &__arrow1 {
      position: absolute;
      top: 111px;
      left: 338px;
    }

    &__arrow2 {
      position: absolute;
      top: 77px;
      left: 682px;
      transform: rotate(19.42deg);
    }

    &__description {
      width: 716px;
      margin: 32px 0 0 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
    }

    &__quote {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 499px;
      height: 166px;
      margin: 56px 0 0 0;
      background: #ffffff;
      border-radius: 20px;
      box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
    }

    &__quoteIcon {
      position: absolute;
      top: 16px;
      left: 40px;
    }

    &__quoteTitle {
      width: 342px;
      margin: 40px 0 0 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
    }

    &__quoteSubtitle {
      margin: 8px 0 0 0;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      white-space: pre-wrap;
      text-align: center;
    }
  }
}

.salivaInstruction {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  height: 785px;

  .instruction {
    &__background {
      position: absolute;
      width: 100%;
      max-width: 1440px;
      height: 785px;
      background: linear-gradient(179.81deg, #ffffff 71.73%, #f9faff 99.83%);
      transform: rotate(180deg);
    }

    &__content {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 785px;
    }

    &__title {
      width: 473px;
      margin: 80px 0 0 0;
      font-weight: 500;
      font-size: 32px;
      line-height: 48px;
      text-align: center;
    }

    &__subtitle {
      width: 893px;
      height: 32px;
      margin: 16px 0 0 0;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    }

    &__steps {
      display: flex;
      margin: 56px 0 0 0;
    }

    &__firstStep {
      display: flex;
      width: 148px;
      height: 54px;

      .instruction__number {
        width: 36px;
      }
    }

    &__secondStep {
      display: flex;
      width: 163px;
      height: 54px;
      margin: 0 0 0 127px;

      .instruction__number {
        width: 51px;
      }
    }

    &__thirdStep {
      display: flex;
      width: 163px;
      height: 54px;
      margin: 0 0 0 99px;

      .instruction__number {
        width: 51px;
      }
    }

    &__number {
      height: 48px;
      color: #45cb85;
      font-weight: normal;
      font-size: 60px;
      font-family: Krona One;
      font-style: normal;
      line-height: 48px;
    }

    &__numberText {
      margin: 22px 0 0 16px;
      color: #45cb85;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    }

    &__howToDo {
      position: relative;
      display: flex;
      margin: 32px 0 0 0;
    }

    &__step {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 240px;
      height: 329px;
      margin: 0 0 0 32px;

      &:first-of-type {
        margin: 0;
      }

      &:last-of-type {
        position: relative;
        margin: 0;

        .background {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 240px;
          height: 70px;
          background: linear-gradient(
            180deg,
            #ffffff 0%,
            rgba(255, 255, 255, 0) 100%
          );
        }

        .arrow {
          position: absolute;
          top: 170px;
          left: 183px;
        }
      }
    }

    &__image {
      width: 100%;
      height: 100%;
    }

    &__text {
      margin: 32px 0 0 0;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      white-space: pre-wrap;
      text-align: center;
    }

    &__arrow1 {
      position: absolute;
      top: 138px;
      left: 211px;
    }

    &__arrow2 {
      position: absolute;
      top: 123px;
      left: 463px;
      transform: rotate(19.42deg);
    }

    &__description {
      margin: 32px 0 0 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
    }
  }
}

@media (max-width: 479px) {
  .bloodInstruction {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 479px;
    height: 1734px;

    .instruction {
      &__title {
        width: 343px;
        margin-top: 40px;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
      }

      &__steps {
        position: absolute;
        top: 128px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
      }

      &__firstStep {
        display: flex;
        width: 137px;
        height: 50px;

        .instruction__number {
          width: 29px;
        }
      }

      &__secondStep {
        display: flex;
        width: 149px;
        height: 50px;
        margin: 324px 0 0 0;

        .instruction__number {
          width: 41px;
        }
      }

      &__thirdStep {
        display: flex;
        width: 163px;
        height: 54px;
        margin: 421px 0 0 0;

        .instruction__number {
          width: 41px;
        }
      }

      &__number {
        font-size: 48px;
      }

      &__numberText {
        margin: 18px 0 0 12px;
      }

      &__howToDo {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 106px 0 0 0;
      }

      &__step {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 343px;
        height: 100%;
        max-height: 302px;
        margin: 168px 0 0 0;

        &:first-of-type {
          height: 206px;
          margin: 0;

          .instruction__text {
            white-space: normal;
          }
        }

        &:last-of-type {
          height: 290px;
          margin: 168px 0 0 0;
        }
      }

      &__image {
        width: 100%;
        height: 100%;
      }

      &__text {
        width: 343px;
        margin: 24px 0 0 0;
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        white-space: pre-wrap;
        text-align: center;
      }

      &__arrow1 {
        position: absolute;
        top: 241px;
        left: 45%;
        width: 49px;
        height: 24px;
        transform: rotate(105deg);
      }

      &__arrow2 {
        position: absolute;
        top: 712px;
        left: 45%;
        width: 49px;
        height: 24px;
        transform: rotate(105deg);
      }

      &__description {
        width: 343px;
        margin: 40px 0 0 0;
        font-size: 18px;
        line-height: 26px;
      }

      &__quote {
        width: 343px;
        height: 207px;
        margin: 32px 0 0 0;
        border-radius: 20px;
        box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
      }

      &__quoteIcon {
        position: absolute;
        top: 16px;
        left: 47px;
        width: 48px;
        height: 32px;
      }

      &__quoteTitle {
        width: 184px;
        margin: 32px 0 0 0;
      }

      &__quoteSubtitle {
        width: 343px;
        margin: 13px 0 0 0;
        white-space: pre-wrap;
      }
    }
  }

  .salivaInstruction {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 479px;
    height: 1459px;

    .instruction {
      &__background {
        position: absolute;
        width: 100%;
        max-width: 479px;
        height: 1459px;
        background: linear-gradient(179.81deg, #ffffff 71.73%, #f9faff 99.83%);
        transform: rotate(180deg);
      }

      &__content {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 1459px;
      }

      &__title {
        width: 246px;
        margin: 40px 0 0 0;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
      }

      &__subtitle {
        width: 343px;
        height: 48px;
        margin: 8px 0 0 0;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
      }

      &__steps {
        position: absolute;
        top: 0;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
      }

      &__firstStep {
        display: flex;
        width: 137px;
        height: 50px;
        margin: 152px 0 0 0;

        .instruction__number {
          width: 29px;
        }
      }

      &__secondStep {
        display: flex;
        width: 149px;
        height: 50px;
        margin: 345px 0 0 0;

        .instruction__number {
          width: 41px;
        }
      }

      &__thirdStep {
        display: flex;
        width: 149px;
        height: 50px;
        margin: 365px 0 0 0;

        .instruction__number {
          width: 41px;
        }
      }

      &__number {
        font-size: 48px;
      }

      &__numberText {
        margin: 18px 0 0 12px;
      }

      &__howToDo {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 24px 0 0 0;
      }

      &__step {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 240px;
        height: 305px;
        margin: 110px 0 0 0;

        &:first-of-type {
          margin: 0;
        }

        &:last-of-type {
          position: relative;
          margin: 144px 0 0 0;

          .background {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 240px;
            height: 70px;
            background: linear-gradient(
              180deg,
              #ffffff 0%,
              rgba(255, 255, 255, 0) 100%
            );
          }

          .arrow {
            position: absolute;
            top: 170px;
            left: 183px;
          }
        }
      }

      &__image {
        width: 100%;
        height: 100%;
      }

      &__text {
        margin: 8px 0 0 0;
      }

      &__arrow1 {
        position: absolute;
        top: 340px;
        left: 45%;
        width: 49px;
        height: 25px;
        transform: rotate(105deg);
      }

      &__arrow2 {
        position: absolute;
        top: 755px;
        left: 45%;
        width: 49px;
        height: 25px;
        transform: rotate(105deg);
      }

      &__description {
        width: 343px;
        margin: 24px 0 0 0;
        text-align: center;
      }
    }
  }
}
