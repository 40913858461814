.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  color: #141414;
  font-family: Rubik;
  background: #f9faff;

  &__content {
    display: flex;
    width: 1160px;
    min-height: 786px;
  }
}

@media (max-width: 479px) {
  .profile {
    &__content {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      min-height: 100px;
    }
  }
}
