@mixin flex($dir: row, $justify: normal, $align: normal) {
  display: flex;
  flex-direction: $dir;
  align-items: $align;
  justify-content: $justify;
}

@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
