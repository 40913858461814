.reviews {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 988px;
  overflow: hidden;

  &__background {
    position: absolute;
    bottom: 0;
    left: -741px;
    width: 2434px;
    height: 2434px;
    background: linear-gradient(206.16deg, #ffffff 61.71%, #f2f4ff 83.53%);
    border-radius: 50%;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    filter: blur(100px);
  }

  &__content {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 659px;
  }

  &__title {
    width: 500px;
    height: 48px;
    margin-top: 100px;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
  }

  &__subtitle {
    min-width: 476px;
    margin-top: 16px;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
  }

  &__item {
    display: flex;
    width: 881px;
    height: 310px;
  }

  .firstReview {
    margin-top: 48px;

    .reviews__name {
      margin-top: 35px;
    }
  }

  .secondReview {
    margin-top: 51px;

    .reviews__name {
      margin-top: 27px;
    }
  }

  &__imageHolder {
    min-width: 310px;
    min-height: 310px;
    background: linear-gradient(134.33deg, #f95b3d 69.24%, #ca133b 96.98%);
    border-radius: 50%;
  }

  .imageLeft {
    margin-right: 51px;
  }

  .imageRight {
    margin-left: 51px;
  }

  &__image {
    max-width: 300px;
    max-height: 300px;
    margin: 5px 0 0 5px;
    border-radius: 50%;
  }

  &__name {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  &__text {
    width: 520px;
    margin-top: 8px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    &:nth-child(2) {
      margin-top: 16px;
    }
  }
}

@media (max-width: 479px) {
  .reviews {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 1194px;
    overflow: hidden;

    &__background {
      position: absolute;
      bottom: 0;
      left: -741px;
      width: 2434px;
      height: 2434px;
      background: linear-gradient(206.16deg, #ffffff 61.71%, #f2f4ff 83.53%);
      border-radius: 50%;
      transform: matrix(-1, 0, 0, 1, 0, 0);
      filter: blur(100px);
    }

    &__content {
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__title {
      width: 375px;
      height: 32px;
      margin-top: 56px;
      font-size: 24px;
      line-height: 32px;
    }

    &__subtitle {
      display: none;
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      min-height: 481px;
    }

    .firstReview {
      margin-top: 32px;

      .reviews__name {
        margin-top: 24px;
      }
    }

    .secondReview {
      position: relative;
      height: 473px;
      margin-top: 56px;

      .reviews__name {
        margin-top: 180px;
      }

      .reviews__imageHolder {
        position: absolute;
      }
    }

    &__imageHolder {
      min-width: 156px;
      max-width: 156px;
      min-height: 156px;
      max-height: 156px;
      background: linear-gradient(134.33deg, #f95b3d 69.24%, #ca133b 96.98%);
      border-radius: 50%;
    }

    .imageLeft {
      margin-right: 0;
    }

    .imageRight {
      margin-left: 0;
    }

    &__image {
      max-width: 150px;
      max-height: 150px;
      margin: 3px 0 0 3px;
      border-radius: 50%;
    }

    &__name {
      width: 100%;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
    }

    &__text {
      width: 343px;
      margin-top: 8px;
      text-align: center;

      &:nth-child(2) {
        margin-top: 16px;
      }
    }
  }
}
