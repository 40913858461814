.consent {
  display: flex;
  margin: 16px 0 0 0;

  &:first-of-type {
    margin: 0 0 0 0;
  }

  &__text {
    margin: 0 0 0 8px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #222;
    user-select: none;
  }

  &__link {
    color: #2b89e2;
    font-weight: 500;
    font-size: 12px;
    font-family: Rubik;
    font-style: normal;
    line-height: 18px;
    text-align: center;
  }
}
