.popup {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 5;
  display: flex;
  align-items: center;
  width: 360px;
  min-height: 60px;
  padding: 0 16px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);

  &__onCartPage {
    bottom: 150px;
  }

  &__closeIconHolder {
    position: relative;
  }

  &__closeIcon {
    position: absolute;
    width: 23px;
    height: 23px;
    margin: -20px 0 0 320px;
    cursor: pointer;

    &:before,
    &:after {
      position: absolute;
      top: 0;
      width: 3px;
      height: 15px;
      background-color: #868686;
      content: "";
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  &__image {
    max-width: 24px;
    max-height: 24px;
  }

  &__text {
    max-width: 260px;
    margin: 0 0 0 16px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
}

@media (max-width: 479px) {
  .popup {
    position: fixed;
    right: inherit;
    bottom: 30px;
    z-index: 5;
    display: flex;
    align-items: center;
    align-self: center;
    width: 343px;
    min-height: 52px;
    padding: 0 12px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);

    &__closeIcon {
      position: absolute;
      width: 22px;
      height: 22px;
      margin: -18px 0 0 300px;
      cursor: pointer;

      &:before,
      &:after {
        position: absolute;
        top: 0;
        right: 9px;
        width: 4px;
        height: 22px;
        background-color: #868686;
        content: "";
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}
