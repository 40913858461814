.promo {
  display: flex;
  width: 1125px;
  height: 533px;

  &__textContent {
    position: relative;
    margin-top: 60px;
  }

  &__title {
    width: 505px;
    height: 144px;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;

    &_orange {
      color: #f95b3d;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 490px;
    height: 130px;
    margin-top: 24px;
  }

  &__text {
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
  }

  &__infoHolder {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
  }

  &__info {
    position: absolute;
    margin: 5px 0 0 5px;
    cursor: pointer;
  }

  &__image {
    width: 533px;
    height: 533px;
    margin-left: 136px;
  }

  &__downloadButtons {
    display: flex;
    justify-content: space-between;
    width: 271px;
    height: 40px;
    margin-top: 32px;
  }

  &__link {
    text-decoration: none;
  }

  .hint {
    position: absolute;
    top: 232px;
    width: 364px;
    height: 160px;

    &__lt {
      left: 74px;
    }

    &__en {
      left: -32px;
    }

    &::after {
      position: absolute;
      top: -20px;
      left: 86px;
      border-color: transparent transparent #fff transparent;
      border-style: solid;
      border-width: 10px;
      content: "";
    }

    &__content {
      width: 340px;
      height: 100%;
      margin-top: -4px;
      padding: 24px;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.2);
    }
  }
}

@media (max-width: 479px) {
  .promo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 734px;

    &__textContent {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 0;
    }

    &__title {
      width: 343px;
      height: 96px;
      font-size: 24px;
      line-height: 32px;

      &_orange {
        color: #f95b3d;
      }
    }

    // for IOS devices
    @supports (-webkit-touch-callout: none) {
      &__title {
        width: 371px;
        height: 96px;
        font-size: 24px;
        line-height: 32px;

        &_orange {
          display: inline;
          color: #f95b3d;
        }
      }
    }

    &__description {
      width: 343px;
      height: 120px;
      margin-top: 16px;
    }

    &__text {
      font-size: 16px;
      line-height: 26px;
    }

    &__infoHolder {
      position: relative;
      display: inline-block;
      width: 21px;
      height: 21px;
    }

    &__info {
      position: absolute;
      width: 21px;
      height: 21px;
      margin: 5px 0 0 5px;
      cursor: pointer;
    }

    &__image {
      width: 375px;
      height: 358px;
      margin-top: 16px;
      margin-left: 0;
    }

    &__downloadButtons {
      position: absolute;
      top: 631px;
      margin-top: 0;
    }

    &__link {
      text-decoration: none;
    }

    .hint {
      position: absolute;
      width: 304px;
      height: 206px;

      &__lt {
        top: 182px;
        left: 87px;
      }

      &__en {
        top: 182px;
        left: 0px;
      }

      &::after {
        position: absolute;
        top: -23px;
        left: 174px;
        border-color: transparent transparent #fff transparent;
        border-style: solid;
        border-width: 10px;
        content: "";
      }

      &__content {
        width: 256px;
        height: 100%;
        margin-top: -4px;
        padding: 24px;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.2);
      }
    }
  }
}
