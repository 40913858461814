.wrapper {
  display: flex;
  flex-direction: column;
}

.modifiedWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;

  @media (max-width: 479px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 15px;
  }
}

.answerContainer {
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 40px;
  }
}

.modifiedAnswerContainer {
  flex-grow: 1;
  height: 60px;
  background: #f4f6fb;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.input {
  width: 16px;
  height: 16px;
  border: 1px solid #e8e8e8;
  border-radius: 3.5px;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;

  &::after {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 13px;
    content: url(../../../../assets/image/icons/check-solid.svg);
  }

  &:checked {
    background-color: #45cb85;
    border-color: #45cb85;
  }

  &:disabled {
    border-color: #f3f1f1;
  }
}

.modifiedInput {
  width: 16px;
  height: 16px;
  border: 1px solid #e8e8e8;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffffff;
  position: relative;

  &:checked {
    background-color: #45cb85;
    border-color: #45cb85;
    position: relative;
  }

  &:after {
    position: absolute;
    width: 7px;
    height: 7px;
    content: "";
    background-color: #ffffff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
}

.text {
  margin: 0 0 0 8px;
  font-family: Rubik;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #222;
  user-select: none;
  font-weight: 500;
}

.active {
  background-color: #ecfaf3;
}
