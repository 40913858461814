.singleParthner {
  width: 100%;
  max-width: 1440px;
  background: #f9faff;
}

.diagnolita {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 344px;

  &__title {
    width: 289px;
    height: 48px;
    margin-top: 80px;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
  }

  &__content {
    display: flex;
    margin-top: 32px;
  }

  &__image {
    width: 268px;
    height: 70px;
  }

  &__textContent {
    max-width: 712px;
    margin-left: 56px;
  }

  &__text {
    margin-top: 26px;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;

    &:first-of-type {
      margin-top: 0;
    }
  }
}

@media (max-width: 479px) {
  .singleParthner {
    max-width: 479px;
  }

  .diagnolita {
    height: 408px;

    &__title {
      width: 150px;
      height: 28px;
      margin-top: 40px;
      font-size: 20px;
      line-height: 28px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 24px;
    }

    &__textContent {
      max-width: 343px;
      margin: 24px 0 0 0;
    }

    &__text {
      text-align: center;
    }
  }
}
