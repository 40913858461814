.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 144rem;
  background: #f9faff;
}

.image {
  width: 64px;
  height: 64px;
  margin-top: 132px;
  margin-bottom: 32px;
}

.title {
  color: #141414;
  font-weight: 500;
  font-size: 40px;
  font-family: Rubik;
  font-style: normal;
  line-height: 47px;
  letter-spacing: 0.01em;
  text-align: center;
}

.description {
  margin: 24px 0 0 0;
}

.text {
  color: #141414;
  font-weight: normal;
  font-size: 16px;
  font-family: Rubik;
  font-style: normal;
  line-height: 24px;
  text-align: center;
}

.contactsLink {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #2b89e2;
}

.link {
  margin: 40px 0 0 0;
  width: 296px;
  height: 56px;
  margin-bottom: 308px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  font-family: Rubik;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-decoration: 0;
  background: #f95b3d;
  border-radius: 8px;
}

@media (max-width: 479px) {
  .wrapper {
    align-items: flex-start;
    width: 100%;
    max-width: 100vw;
    padding-left: 16px;
    background: #f9faff;
  }

  .wrapper {
    align-items: flex-start;
    width: 100%;
    max-width: 37.5rem;
    padding-left: 16px;
    background: #f9faff;
  }

  .image {
    width: 32px;
    height: 32px;
    margin-top: 101px;
    margin-bottom: 24px;
  }

  .title {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }

  .description {
    margin: 16px 0 0 0;
  }

  .text {
    text-align: center;
  }

  .link {
    width: 343px;
    margin: 32px 0 0 212px;
  }

  .link {
    width: 343px;
    margin-bottom: 212px;
  }
}
