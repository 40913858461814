.block {
  display: flex;
  flex-direction: column;
  padding: 16px 29px 0 32px;

  &__cardError {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: -moz-max-content;
    width: 343px;
    max-width: 343px;
    padding: 16px;
    color: #f20035;
    font-weight: normal;
    font-size: 14px;
    font-family: Rubik;
    font-style: normal;
    line-height: 20px;
    text-align: center;
    background-color: #fff2f5;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(88, 0, 19, 0.1);
    align-self: center;

    &:after {
      position: absolute;
      top: -20px;
      left: 47%;
      margin-left: -5px;
      border-color: transparent #fff2f5 transparent transparent;
      border-style: solid;
      border-width: 10px;
      transform: rotate(0.25turn);
      content: "";
    }
  }

  &__cardErrorText {
    display: flex;
    align-items: center;
    width: fit-content;
    width: -moz-fit-content;
    height: 4rem;
  }
}

@media (max-width: 479px) {
  .block {
    max-width: 100vw;
    padding: 24px 16px 0 16px;

    &__cardError {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: -moz-max-content;
      width: 343px;
      max-width: 343px;
      padding: 16px;
      color: #f20035;
      font-weight: normal;
      font-size: 14px;
      font-family: Rubik;
      font-style: normal;
      line-height: 20px;
      text-align: center;
      background-color: #fff2f5;
      border-radius: 10px;
      box-shadow: 0px 4px 10px rgba(88, 0, 19, 0.1);

      &:after {
        position: absolute;
        top: -20px;
        left: 47%;
        margin-left: -5px;
        border-color: transparent #fff2f5 transparent transparent;
        border-style: solid;
        border-width: 10px;
        transform: rotate(0.25turn);
        content: "";
      }
    }

    &__cardErrorText {
      display: flex;
      align-items: center;
      width: fit-content;
      width: -moz-fit-content;
      height: 4rem;
    }
  }
}
