.researchRepeat {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 739px;
  padding-bottom: 164px;
  overflow: hidden;

  &__background {
    position: absolute;
    bottom: -400px;
    width: 1800px;
    height: 900px;
    background: #fff0ed;
    border-top-left-radius: 50% 900px;
    border-top-right-radius: 50% 900px;
    filter: blur(50px);
  }

  &__content {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 659px;
  }

  &__title {
    width: 443px;
    height: 96px;
    margin-top: 80px;
    margin-bottom: 40px; // margin to dropdown-list (needed for reuse)
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
  }
}

@media (max-width: 479px) {
  .researchRepeat {
    min-height: 497px;
    padding-bottom: 56px;

    &__background {
      position: absolute;
      bottom: -150px;
      width: 900px;
      height: 450px;
      background: #fff0ed;
      border-top-left-radius: 50% 900px;
      border-top-right-radius: 50% 900px;
      filter: blur(30px);
    }

    &__content {
      z-index: 1;
      min-height: 497px;
    }

    &__title {
      width: 330px;
      height: 64px;
      margin-top: 56px;
      margin-bottom: 32px; // margin to dropdown-list (needed for reuse)
      font-size: 24px;
      line-height: 32px;
    }
  }
}
