.symptoms {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  padding: 80px 0 120px 0;

  &__title {
    display: flex;
    align-items: center;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    letter-spacing: 0.01em;
    color: #2b89e2;
  }

  &__titleImage {
    margin: 0 12px 0 0;
  }

  &__content {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1000px;
  }

  &__blockTitle {
    margin: 0 0 24px 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #6268a7;
  }

  &__columns {
    display: flex;
    justify-content: center;
    margin: 20px 0 0 0;
  }

  &__column {
    width: calc((100% - 40px) / 2);
    max-width: 406px;

    &:last-of-type {
      margin: 0 0 0 16px;
    }
  }

  &__textImage {
    float: left;
    max-width: 64px;
    max-height: 64px;
    margin: 0 4px 4px 0;
  }

  &__text {
    margin: 0 0 24px 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #141414;

    &_blue {
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #6268a7;
    }
  }

  &__image {
    width: 400px;
  }

  &__listTitle {
    display: flex;
    align-items: center;
    margin: 0 0 0 20px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #2b89e2;
  }

  &__item {
    max-width: 300px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #141414;
  }
}

@media (max-width: 479px) {
  .symptoms {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1440px;
    padding: 80px 0 120px 0;

    &__title {
      display: flex;
      align-items: center;
      max-width: 343px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 0.01em;
      color: #2b89e2;
    }

    &__titleImage {
      margin: 0 12px 0 0;
    }

    &__content {
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 1000px;
    }

    &__blockTitle {
      margin: 0 0 24px 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.01em;
      color: #6268a7;
    }

    &__columns {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin: 20px 0 0 0;
    }

    &__column {
      width: 343px;
      max-width: 406px;

      &:last-of-type {
        margin: 0 0 0 16px;
      }
    }

    &__textImage {
      float: left;
      max-width: 64px;
      max-height: 64px;
      margin: 0 4px 4px 0;
    }

    &__text {
      margin: 0 0 24px 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #141414;

      &_blue {
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #6268a7;
      }
    }

    &__image {
      width: 400px;
    }

    &__listTitle {
      display: flex;
      align-items: center;
      margin: 0;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #2b89e2;
    }

    &__item {
      max-width: 300px;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 22px;
      color: #141414;
    }
  }
}
