.wrapper {
    display: flex;
    flex-direction: column;
    width: 76rem;
    height: 32.5rem;
    font-weight: 500;
    font-family: Rubik;
    font-style: normal;
    background: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
}

.header {
    margin-top: 4rem;
    margin-left: 3.2rem;
    color: #141414;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.01em;
}

.pannel {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 69.6rem;
    height: 8.8rem;
    margin-top: 3.2rem;
    margin-left: 3.2rem;
    background: #ECFAF3;
    border-radius: 10px;

    &__testNegative {
        background: #ECFAF3;
    }

    &__testPositive {
        background: #FDECF0;
    }

    &__testFailed {
        display: flex;
        justify-content: center;
        background: #FDECF0;
        color: #f20035;
    }

    &__image {
        width: 2.4rem;
        height: 2.4rem;
        margin-left: 2.4rem;
    }

    &__text {
        margin-left: 1.6rem;
        color: #141414;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.01em;
    }

    &__value {
        position: absolute;
        right: 0;
        margin-right: 2.4rem;
        color: #141414;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.01em;
    }

}

@media (max-width: 479px) {

    .wrapper {
        width: 37.5rem; 
        height: 15.6rem;
        margin-bottom: 0.8rem;
        border-radius: 0;
        box-shadow: none;
    }

    .header {
        margin-top: 2.4rem;
        margin-left: 1.6rem;
        font-size: 16px;
        line-height: 24px;
    }

    .pannel {
        width: 34.3rem;
        height: 6.8rem;
        margin-top: 1.6rem;
        margin-left: 1.6rem;

        &__testFailed {
            padding-left: 16px;
        }

        &__image {
            width: 2rem;
            height: 2rem;
            margin-left: 1.6rem;
        }

        &__text {
            margin-left: 0.8rem;            
            font-size: 16px;
            line-height: 19px;            
        }
        &__value {            
            margin-right: 1.6rem;            
            font-size: 16px;
            line-height: 19px;            
        }
    }

}