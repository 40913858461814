.slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  overflow: hidden;

  &__slideList {
    display: flex;
    align-items: center;
  }

  &__emptySlide {
    width: 400px;
    height: 400px;

    &:first-of-type {
      margin: 0 64px 0 0;
    }

    &:last-of-type {
      margin: 0 0 0 64px;
    }
  }

  &__slide {
    width: 400px;
    height: 400px;
    margin: 120px 32px 0 32px;
    filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.1));
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;

    &_active {
      width: 600px;
      height: 600px;
    }

    &_hidden {
      display: none;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
  }

  &__dotsList {
    display: flex;
    align-items: center;
    margin: 40px 0 0 0;
  }

  &__dot {
    width: 8px;
    height: 8px;
    margin: 0 0 0 16px;
    border-radius: 50%;
    background: #b5bdea;
    cursor: pointer;

    &:first-of-type {
      margin: 0;
    }

    &_active {
      width: 16px;
      height: 16px;
      background: #4654a3;
    }
  }
}

@media (max-width: 479px) {
  .slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100vw;
    overflow: hidden;

    &__slideList {
      display: flex;
      align-items: center;
    }

    &__emptySlide {
      width: 180px;
      height: 180px;

      &:first-of-type {
        margin: 0 32px 0 0;
      }

      &:last-of-type {
        margin: 0 0 0 32px;
      }
    }

    &__slide {
      width: 210px;
      height: 210px;
      margin: 96px 16px 0 16px;
      filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.1));
      border-radius: 20px;
      overflow: hidden;
      cursor: pointer;

      &_active {
        width: 290px;
        height: 290px;
      }

      &_hidden {
        display: none;
      }
    }

    &__image {
      width: 100%;
      height: 100%;
    }

    &__dotsList {
      display: flex;
      align-items: center;
      margin: 32px 0 0 0;
    }

    &__dot {
      width: 10px;
      height: 10px;
      margin: 0 0 0 16px;
      border-radius: 50%;
      background: #b5bdea;
      cursor: pointer;

      &:first-of-type {
        margin: 0;
      }

      &_active {
        width: 18px;
        height: 18px;
        background: #4654a3;
      }
    }
  }
}
