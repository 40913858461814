.details {
  position: sticky;
  top: 100px;
  right: 0;
  width: 360px;
  height: 100%;
  min-height: 256px;
  margin: 0 0 0 40px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);

  &__test {
    display: flex;
    min-height: max-content;
    padding: 0 0 16px 0;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);
  }

  &__testImageHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin: 32px 0 0 32px;
    background: #fafafa;
  }

  &__testImage {
    width: 46px;
    height: 46px;
  }

  &__testContent {
    margin: 32px 0 0 8px;
  }

  &__testName {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  &__testQuantity {
    display: flex;
    margin: 8px 0 0 0;

    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }

    &__value {
      font-weight: 500;
    }
  }

  &__testPrices {
    display: flex;
    margin: 8px 0 0 0;

    &__price {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }

    &__priceBeforeDiscount {
      margin: 0 0 0 8px;
      color: #eb3f26;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      text-decoration-line: line-through;
    }
  }

  &__testDiscount {
    width: 230px;
    margin: 8px 0 24px 0;
    color: #45cb85;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    &_bold {
      font-weight: 500;
    }
  }

  &__delivery {
    display: flex;
    align-items: center;
    height: 68px;
    padding: 0 32px;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);

    &__title {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      opacity: 0.5;
    }

    &__value {
      margin: 0 0 0 auto;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
  }

  &__totalPrices {
    display: flex;
    align-items: center;
    height: 72px;
    padding: 0 32px;

    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      opacity: 0.5;
    }

    &__prices {
      display: flex;
      margin: 0 0 0 auto;
    }

    &__beforeDiscount {
      margin: 3px 0 0 0;
      color: #eb3f26;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-decoration-line: line-through;
    }

    &__price {
      margin: 0 0 0 8px;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.euro {
  font-weight: 400;
}

@media (max-width: 479px) {
  .details {
    position: static;
    width: 100%;
    height: 100%;
    min-height: 204px;
    margin: 16px 0 0 0;
    border-radius: 0;

    &__test {
      display: flex;
      min-height: max-content;
      padding: 0 0 16px 0;
      border-bottom: 1px solid rgba(20, 20, 20, 0.1);
    }

    &__testImageHolder {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      margin: 24px 0 0 16px;
      background: #fafafa;
    }

    &__testImage {
      width: 38px;
      height: 38px;
    }

    &__testContent {
      margin: 24px 0 0 16px;
    }

    &__testName {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
    }

    &__testQuantity {
      display: flex;
      margin: 6px 0 0 0;

      &__text {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
      }

      &__value {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
      }
    }

    &__testPrices {
      display: flex;
      margin: 6px 0 0 0;

      &__price {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
      }
    }

    &__testDiscount {
      width: 230px;
      margin: 6px 0 18px 0;
      color: #45cb85;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;

      &_bold {
        font-weight: 500;
      }
    }

    &__delivery {
      display: flex;
      align-items: center;
      height: 52px;
      padding: 0 16px;
      border-bottom: 1px solid rgba(20, 20, 20, 0.1);
    }

    &__totalPrices {
      display: flex;
      align-items: center;
      height: 57px;
      padding: 0 16px;
      border-bottom: 1px solid rgba(20, 20, 20, 0.1);
    }
  }
}
