.mainWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 144rem;
  min-height: 12.5rem;
}

.hidden {
  display: none;
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: 116rem;
  height: 12.5rem;
  color: #141414;
  font-weight: 500;
  font-size: 1.6rem;
  font-family: Rubik;
  font-style: normal;
  line-height: 1.9rem;
  letter-spacing: 0.01em;
  text-decoration: none;
  background-color: #fff;
}

.logoWrapper {
  position: absolute;
  top: 3.4rem;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 10.6rem;
  height: 6.2rem;
  cursor: pointer;
}

.headerLogo {
  width: 4.2rem;
  height: 4rem;
}

.headerLogoText {
  width: 10.6rem;
  height: 1.4rem;
}

.languageMenuContainer {
  position: absolute;
  left: 14.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 4rem;
  height: 1.7rem;
  cursor: pointer;
}

.flagImage {
  width: 24px;
  height: 17px;
}

.arrowImage {
  width: 8px;
  height: 6px;
}

.languageMenuPopup {
  position: absolute;
  top: 4.2rem;
  left: -70%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 17.8rem;
  height: 6.7rem;
  padding: 8px 0;
  color: #fff;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.2);
}

.languageMenuItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 1.9rem;
  margin-left: 24px;
  color: #141414;
  background: #fff;
  cursor: pointer;
}

.languageMenuItemText {
  margin-left: 16px;
}

.testItem {
  position: relative;
  display: inline-block;
  margin-right: 6.4rem;
  color: #141414;
  background: #fff;
  border: none;
  cursor: pointer;
}

.testPackagesPopup {
  position: absolute;
  top: 4.2rem;
  left: -110%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 36.4rem;
  height: 29.5rem;
  padding: 8px 0;
  color: #fff;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.2);
}

.testPackagesPopup::after {
  position: absolute;
  top: -20px;
  left: 25%;
  margin-left: -5px;
  border-color: transparent transparent #fff transparent;
  border-style: solid;
  border-width: 10px;
  content: "";
}

.testPackageItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 30rem;
  height: 9rem;
  color: #141414;
  text-decoration: none;
  background: #fff;
  border-bottom: 1px solid rgba(20, 20, 20, 0.1);
  cursor: pointer;

  &__image {
    width: 1.9rem;
    height: 1.9rem;
    margin-right: 2rem;
  }
}

.testPackageItem:last-child {
  border: none;
}

.howDoesItWorksItem {
  position: relative;
  color: #141414;
  text-decoration: none;
  background: #fff;
  border: none;
  cursor: pointer;
}

.testResultsItem {
  position: relative;
  margin-left: 6.4rem;
  color: #141414;
  text-decoration: none;
  background: #fff;
  border: none;
  cursor: pointer;
}

.registerItem {
  width: 14.4rem;
  height: 4rem;
  margin-left: 4.8rem;
  color: #45cb85;
  line-height: 4rem;
  text-align: center;
  text-decoration: none;
  background: rgba(69, 203, 133, 0.15);
  border: none;
  border-radius: 0.8rem;
  cursor: pointer;
}

.joinItem {
  width: 14.4rem;
  height: 4rem;
  margin-left: 3.2rem;
  color: #fff;
  line-height: 4rem;
  text-align: center;
  text-decoration: none;
  background: #45cb85;
  border: none;
  border-radius: 0.8rem;
  cursor: pointer;
}

.userItem {
  position: relative;

  &__image {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.8rem;
    margin-left: 6.4rem;
    cursor: pointer;
  }

  &__pointerBlock {
    display: flex;
    max-width: 300px;
    max-height: 19px;
    cursor: pointer;
  }

  &__text {
    max-width: 240px;
    overflow: hidden;
    white-space: nowrap;
  }

  &__select {
    margin-left: 0.8rem;
  }
}

.userMenuPopup {
  position: absolute;
  top: 4.7rem;
  left: 3rem;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 27.6rem;
  padding: 0.8rem;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.2);
}

.userMenuPopupItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 21.2rem;
  height: 6.7rem;
  color: #141414;
  text-decoration: none;
  background: #fff;
  border-bottom: 1px solid rgba(20, 20, 20, 0.1);
  cursor: pointer;

  &:last-child {
    border: none;
  }

  &__image {
    width: 2rem;
    height: 2rem;
    margin-right: 1.6rem;
  }
}

.cartItemWrapper {
  position: relative;
  width: 28px;
  height: 24px;
  margin-left: 3.4rem;
  padding: 0;
  background: #fff;
  border: none;
  cursor: pointer;
}

.cartItemImage {
  width: 100%;
  height: 100%;
}

.cartItemQuantity {
  position: absolute;
  top: -10px;
  left: 14px;
  width: 20px;
  height: 20px;
  color: #fff;
  font-weight: 500;
  font-size: 1.2rem;
  font-family: Rubik;
  font-style: normal;
  line-height: 2rem;
  text-align: center;
  background-color: #f95b3d;
  border-radius: 10px;
}

.burgerItem {
  display: block;
  padding: 0;
  background: #fff;
  border: none;
  cursor: pointer;
}

@media (max-width: 479px) {
  .mainWrapper {
    min-height: 7.5rem;
  }

  .wrapper {
    height: 7.5rem;
    padding-right: 1.6rem;
    padding-left: 1.6rem;
  }

  .logoWrapper {
    top: 1.6rem;
    left: 1.6rem;
    width: 7.2rem;
    height: 4.2rem;
  }

  .headerLogo {
    width: 2.6rem;
    height: 2.6rem;
  }

  .headerLogoText {
    width: 7.2rem;
    height: 0.9rem;
  }

  .userItem__image {
    margin-right: 2.6rem;
  }

  .userMenuPopup {
    top: 4.7rem;
    left: -133%;
    width: 34.3rem;
    padding: 0.8rem;
  }

  .userMenuPopup::after {
    position: absolute;
    top: -20px;
    left: 65%;
    margin-left: -5px;
    border-color: transparent transparent #fff transparent;
    border-style: solid;
    border-width: 10px;
    content: "";
  }

  .userMenuPopupItem {
    width: 31.1rem;
  }

  .cartItemWrapper {
    margin-right: 2.6rem;
    margin-left: 0rem;
  }
}
