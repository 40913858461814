.orderInfo {
  margin: 64px 0 80px 0;

  &__title {
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
  }

  &__content {
    width: 760px;
    margin: 24px 0 0 0;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 24px 0;

  &__backBlock {
    display: flex;
    align-items: center;
    width: 760px;
    height: 70px;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);
  }

  &__backLink {
    margin: 0 0 0 32px;
    color: #2b89e2;
    font-weight: 500;
    font-size: 18px;
    font-family: Rubik;
    font-style: normal;
    line-height: 21px;
    text-decoration: none;
  }

  &__backImage {
    margin: 0 8px 0 0;
  }

  &__resultsBlock {
    width: 760px;
    height: 60px;
    margin: 0 0 16px 0;
    padding: 32px 32px 0 32px;

    &_big {
      display: flex;
      align-items: center;
      width: 760px;
      height: 105px;
      margin: 0 0 32px 0;
      padding: 0 32px;
      border-bottom: 1px solid rgba(20, 20, 20, 0.1);
    }
  }

  &__resultsTitle {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }

  &__resultsLink {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
    height: 56px;
    margin: 0 0 0 auto;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    font-family: Rubik;
    font-style: normal;
    line-height: 24px;
    text-decoration: none;
    background: #f95b3d;
    border-radius: 8px;
  }

  &__title {
    width: 696px;
    margin: 0 0 8px 0;
    color: #434343;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  &__infoBlock {
    display: flex;
    align-items: center;
    width: 696px;
    height: 57px;
    border-bottom: 1px solid rgba(20, 20, 20, 0.1);

    &:last-of-type {
      border-bottom: none;
    }
  }

  &__infoTitle {
    color: #8a8a8a;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &__infoText {
    max-width: 400px;
    margin: 0 0 0 auto;
    overflow: hidden;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;

    &_green {
      color: #45cb85;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    &_yellow {
      color: #ffa500;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__infoCircle {
    &_green {
      width: 8px;
      height: 8px;
      margin: 0 8px 0 auto;
      background: #45cb85;
      border-radius: 50%;
    }

    &_yellow {
      width: 8px;
      height: 8px;
      margin: 0 8px 0 auto;
      background: #ffa500;
      border-radius: 50%;
    }
  }

  &__bankBlock {
    display: flex;
    align-items: center;
  }

  &__bankImage {
    margin: 0 0 0 8px;
    max-height: 32px;
  }

  &__bankName {
    margin: 0 0 0 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  &__cardBlock {
    display: flex;
    align-items: center;
  }

  &__insuranceImage {
    max-height: 50px;
  }

  &__circles {
    display: flex;
  }

  &__circlesBlock {
    margin: 0 8px 0 0;
    font-size: 12px;
  }

  &__cardNumber {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  &__brandImage {
    max-width: 40px;
    max-height: 30px;
    margin: 0 0 0 16px;

    &_nonexistent {
      max-width: 100%;
    }
  }

  &__invalidText {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 47px;
    margin: 280px;
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
  }

  &__link {
    display: block;
    color: #2b89e2;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
  }
}

.euro {
  font-weight: 400;
}

@media (max-width: 479px) {
  .orderInfo {
    margin: 32px 0 40px 0;

    &__title {
      margin: 0 0 0 16px;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
    }

    &__content {
      width: 100%;
      min-height: 633px;
      margin: 16px 0 0 0;
      background: #fff;
      border-radius: 0;
      box-shadow: 0px 4px 40px rgba(46, 46, 46, 0.07);
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 8px 0;

    &__backBlock {
      display: flex;
      align-items: center;
      width: 100%;
      height: 54px;
      border-bottom: 1px solid rgba(20, 20, 20, 0.1);
    }

    &__backLink {
      margin: 0 0 0 16px;
    }

    &__resultsBlock {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 48px;
      margin: 0 0 8px 0;
      padding: 24px 0 0 16px;

      &_big {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 145px;
        margin: 0 0 24px 0;
        padding: 0;
        border-bottom: 1px solid rgba(20, 20, 20, 0.1);
      }
    }

    &__resultsTitle {
      &_notCompleted {
        align-self: flex-start;
        margin: 0;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
      }

      &_completed {
        align-self: flex-start;
        margin: 24px 0 0 16px;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
      }
    }

    &__resultsLink {
      display: flex;
      align-items: center;
      align-self: center;
      justify-content: center;
      width: 343px;
      height: 56px;
      margin: 16px 0 0 0;
    }

    &__title {
      width: calc(100% - 16px);
      margin: 0 0 8px 16px;
      color: #434343;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }

    &__infoBlock {
      display: flex;
      flex-direction: column;
      align-items: initial;
      width: calc(100% - 32px);
      height: 89px;
      margin: 0 16px;
      padding: 0 16px;
      border-bottom: 1px solid rgba(20, 20, 20, 0.1);

      &:last-of-type {
        border-bottom: none;
      }
    }

    &__infoTitle {
      margin: 16px 0 0 0;
      color: #8a8a8a;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    &__infoText {
      margin: 8px 0 0 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
    }

    &__infoCircle {
      width: 8px;
      height: 8px;
      border-radius: 50%;

      &_green {
        background: #45cb85;
      }

      &_yellow {
        background: #ffa500;
      }
    }

    &__invalidText {
      width: 300px;
      height: 40px;
      font-weight: 500;
      font-size: 32px;
      line-height: 40px;
    }

    &__insuranceImage {
      max-height: 36px;
    }
  }
}
