.selectContainer {
    display: flex;
    flex-direction: column;
    margin-right: 4rem;
    width: 32.4rem;
    position: relative;
  }

.fullWidth {
  width: 100%;
  margin-right: 0;
  margin-top: 32px;
}

.fullWidthWithoutMargin {
  width: 100%;
  margin: 0;
}
  
.selectHeader {
  height: 2rem;
  margin-bottom: 0.8rem;
  color: #141414;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.select {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  min-height: 4rem;
  height:-moz-max-content;
  height: max-content;
  line-height: 20px;
  padding-left: 1.6rem;
  padding-right: 41px;
  background-image: url(../../assets/image/icons/select-arrow.svg); 
  background-repeat: no-repeat;
  background-position-x: calc(100% - 17px);
  background-position-y: 50%;
  border: 1px solid rgba(20, 20, 20, 0.1);
  border-radius: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  overflow: hidden;
  white-space: normal;
  cursor: pointer;
}

.selectText {
  display: flex;
  align-items: center;
  height: fit-content;
  height: -moz-fit-content;
}

.select:focus{
    outline: none;
}

.selectOptionContainer {
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  padding: 0;
  background: #fff;
  border: 1px solid rgba(20, 20, 20, 0.4);
  box-shadow: 0px 2px 10px rgba(46, 46, 46, 0.25);
  position: absolute;
  top: 50px;
  z-index: 2;

  &__withoutHeader {
    top: 23px;
  }
}

.selectOption {
  width: 100%;
  padding: 2px 16px;
  word-wrap:break-word;
  white-space: normal;
  cursor: pointer;  
  list-style-type: none;
}

.selectOption:hover {
  color:#fff;
  background-color: dodgerblue;
  
}

.selectError {
  border-color:#f20035 !important;
}

.selectDisabled {
  color:rgba(20, 20, 20, 0.1);
  cursor: default;
}

@media (max-width: 479px) {

  .selectContainer {
    width: 34.3rem;
    margin-top: 2.4rem;
    margin-right: 0;
  }

  .fullWidthWithoutMargin {
    margin: 0;
  }
  
  .select {
    width: 34.3rem;
    width: 100%;
    height:-moz-max-content;
    height: max-content;
    padding-left: 1.6rem;
    background-position-x: 32.1rem;
    border: 1px solid rgba(20, 20, 20, 0.1);
    border-radius: 10px;
  }

  .selectOptionContainer {
    max-height: 150px;
    overflow-y: scroll;
  }
}
  